/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const NavItemKids = ({
  active = null,
  inactive = null,
  isActive = false,
  to = "#",
  text = "",
}) => {
  return (
    <Link
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      as={RouterLink}
      to={to}
      ml={text === "learn" && "1.5"}
    >
      {isActive ? active : inactive}{" "}
    </Link>
  );
};

export default NavItemKids;
