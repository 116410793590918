/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@chakra-ui/react";
import {
  MainHeader,
  Footer,
  MainSection,
  SecondarySection,
  SectionsWrapper,
  SideBar,
} from "..";
import useMainStore from "../../hooks/useMainStore";
import { useMediaQuery } from "react-responsive";

const MainLayout = ({
  children,
  topic = false,
  snacks = false,
  practice = false,
  title,
  subtitle,
  image_url,
  id,
  level,
}) => {
  const { isLoggedIn } = useMainStore();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

  return (
    <Box minH={"100vh"} marginX="auto" backgroundColor={"white"}>
      <MainHeader
        platform={true}
        topic={topic}
        snacks={snacks}
        practice={practice}
        title={title}
        subtitle={subtitle}
        image_url={image_url}
        id={id}
        level={level}
      />
      <SectionsWrapper>
        {isLoggedIn && !isTabletOrMobile && (
          <MainSection>
            <SideBar
              topic={topic}
              snacks={snacks}
              practice={practice}
              title={title}
              subtitle={subtitle}
              image_url={image_url}
              id={id}
              level={level}
            />
          </MainSection>
        )}
        <SecondarySection>{children}</SecondarySection>
      </SectionsWrapper>
      {isLoggedIn && <Footer />}
    </Box>
  );
};

export default MainLayout;
