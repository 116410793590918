import React from "react";
import { Box } from "@chakra-ui/react";
import { NavImageButton } from "..";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../constants/routes";

const EnjoyMenu = () => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} flexDirection="column">
      <NavImageButton
        image={images.enjoySongsMenu}
        onClick={() => navigate(routes.enjoySongs.path)}
      />
      <NavImageButton
        image={images.enjoyAudioBooksMenu}
        onClick={() => navigate(routes.enjoyAudioBooks.path)}
      />
      <NavImageButton
        image={images.enjoyCartoonsMenu}
        onClick={() => navigate(routes.enjoyCartoons.path)}
      />
      <NavImageButton
        image={images.enjoyAnimationsMenu}
        onClick={() => navigate(routes.enjoyAnimations.path)}
      />
      <NavImageButton
        image={images.playFlashCardsMenu}
        onClick={() => navigate(routes.playFlashCards.path)}
      />
    </Box>
  );
};

export default EnjoyMenu;
