import React from 'react';
import { MainContext } from '../store/contexts/MainContext';

const useMainStore = () => {
  const context = React.useContext(MainContext);
  if (context === undefined) {
    throw new Error('useMainStore must be used within a Provider');
  }
  return context;
};

export default useMainStore;