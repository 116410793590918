/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import { Modals, H5pModal, Spinner } from "..";

const NewsList = ({ data = [], isRecentMoreLoading }) => {
  const h5pModal = useDisclosure();
  const [uuid, setUuid] = useState();

  const openModal = (value) => {
    setUuid(value);
    h5pModal.onOpen();
  };

  return (
    <Box>
      <Box
        flex={1}
        display="grid"
        gridGap={2}
        gridTemplateColumns={{
          base: "1fr 1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        // minH={"55vh"}
        mt={4}
        mb={16}
      >
        {data.map((item, index) => {
          return (
            <Image
              key={index}
              onClick={() => openModal(item?.value)}
              cursor="pointer"
              src={item?.image_url}
              alt="recent news"
              borderRadius={5}
              w="31vh"
              h={"17vh"}
              objectFit="cover"
              opacity={item?.is_completed ? 0.2 : 1}
            />
          );
        })}
      </Box>
      {isRecentMoreLoading && <Spinner />}
      <Modals onClose={h5pModal.onClose} isOpen={h5pModal.isOpen}>
        <H5pModal uuid={uuid} />
      </Modals>
    </Box>
  );
};

export default NewsList;
