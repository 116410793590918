export const MAIN_LAYOUT_MAX_WIDTH = {
  base: "100%",
  sm: "95%",
  md: "95%",
  lg: "85%",
};

export const ebaLoginUrl =
  "https://giris.eba.gov.tr/EBA_GIRIS/Giris?uygulamaKodu=diyalekt&login=student";
export const termsUrl = "https://diyalekt.eba.gov.tr/terms-and-conditions";
export const privacyUrl = "https://diyalekt.eba.gov.tr/privacy-policy";
export const instagramLink = "https://www.instagram.com/tcmeb/";
export const twitterLink = "https://twitter.com/tcmeb";
export const youtubeLink = "https://www.youtube.com/@tcmeb_";
export const facebookLink = "https://www.facebook.com/milliegitimbakanligi";
export const appStoreLink =
  "https://apps.apple.com/us/app/di-yalekt/id6446923691";
export const googlePlayLink =
  "https://play.google.com/store/apps/details?id=tr.gov.meb.diyalekt";
export const forgotPassLink =
  "https://giris.eba.gov.tr/EBA_GIRIS/studentForgetPassword.jsp";

export const googleAnalyticsTrackerId = "G-JNMXGDV6H9";
