/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, Title } from "../../components";
import { useLocation } from "react-router-dom";
import images from "../../constants/images";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const DiscoverDetails = () => {
  const id = useLocation();
  const [playerShow, setPlayerShow] = useState(true);

  const { data, isLoading } = useQuery(["discoverDetails", id?.state?.id], () =>
    PostService.getDiscoverDetails(id?.state?.id)
  );

  const discoverDetails = useMemo(() => {
    return data && data.length > 0 ? data[0] : [] || [];
  }, [data]);

  var myVideo = document.getElementById("video1");

  function playPause() {
    if (myVideo.paused) {
      myVideo.play();
      setPlayerShow(!playerShow);
    } else {
      myVideo.pause();
      setPlayerShow(true);
    }
  }

  return (
    <MainLayout snacks={true}>
      <Title text={"Discover"} color="title" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        mb="10"
      >
        {/* <Image
          src={discoverDetails?.image_url}
          atl="videoNewsDetails"
          borderRadius={5}
        /> */}
        <video
          width="100%"
          id="video1"
          controls
          type="video/mp4"
          src={discoverDetails.value}
          onPlay={() => setPlayerShow(false)}
          onPause={() => setPlayerShow(!playerShow)}
        />
        {/* <video id="video1" controls width="100%" height="100%">
          <source src={discoverDetails.value} type="video/mp4" />
        </video> */}
        {playerShow && (
          <Image
            src={images.videoIcon}
            alt="videoIcon"
            w={["8vh", "8vh", "10vh"]}
            position="absolute"
            onClick={() => playPause()}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default DiscoverDetails;
