import React from "react";
import { Image } from "@chakra-ui/react";

const NavImageButton = ({ onClick = () => {}, image }) => {
  return (
    <Image
      src={image}
      h="10%"
      maxW={"75%"}
      onClick={onClick}
      cursor="pointer"
    />
  );
};

export default NavImageButton;
