/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const ShowAlert = ({
  onClick = () => {},
  data,
  isShow,
  isYes,
  onYesClick = () => {},
  color,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isShow}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent {...props}>
          <AlertDialogBody>{data}</AlertDialogBody>
          <AlertDialogFooter>
            {isYes ? (
              <>
                <Button ref={cancelRef} onClick={onClick}>
                  No
                </Button>
                <Button
                  colorScheme={color}
                  backgroundColor={color}
                  ml={3}
                  borderRadius={20}
                  onClick={onYesClick}
                >
                  Yes
                </Button>
              </>
            ) : (
              <Button
                ref={cancelRef}
                colorScheme={color}
                backgroundColor={color}
                onClick={onClick}
                textColor="white"
                borderRadius={20}
              >
                Ok
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ShowAlert;
