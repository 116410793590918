const endpoints = {
  login: "/api/v1/account/login",
  register: "/api/v1/account/register/",
  logout: "/api/v1/account/logout",
  deleteAccount: "/api/v1/account/delete/",
  getBooks: "/api/v1/book/list",
  getBookFromPage: (page) => `api/v1/book/list?page=${page}`,
  getBookDetails: (postId) => `/api/v1/book/show?id=${postId}`,
  getDiscoverList: "/api/v1/discover/list",
  getDiscoverPageList: (page) => `/api/v1/discover/list?page=${page}`,
  getDiscoverDetails: (postId) => `/api/v1/discover/show?id=${postId}`,
  getNews: "/api/v1/news/list",
  getNewsPage: (page) => `/api/v1/news/list?page=${page}`,
  getNewsDetails: (postId) => `/api/v1/news/show?id=${postId}`,
  getTopicList: "/api/v1/contentSubject/list",
  changePassword: "/api/v1/account/change-password/",
  init: "/api/v1/init",
  getMeetingDates: "/api/v1/meeting/get-all-available-periods",
  getMe: "/api/v1/account/me",
  contentList: "/api/v1/content/list",
  getContentPage: (page) => `api/v1/content/list?page=${page}`,
  updateUser: "/api/v1/account/update",
  updateAvatar: "/api/v1/account/update-avatar",
  setMeeting: "/api/v1/meeting",
  getMeeting: "/api/v1/meeting/get-all",
  sendWriting: "/api/v1/writing",
  getMyWriting: "/api/v1/writing/get-all",
  showWriting: (postId) => `/api/v1/writing/show?id=${postId}`,
  deleteMeeting: (postId) => `/api/v1/meeting/delete/${postId}`,
  ssoCallBack: "/api/v1/account/sso-callback",
  getDayTab: "/api/v1/report/day/",
  getLessonTab: "/api/v1/report/lesson/",
  getLevelTab: "/api/v1/report/level/",
  startUp: "/api/v1/init/startup",
  getScorm: (uuid) => `/api/v1/scorm/play/${uuid}`,
  finishScorm: "/api/v1/content/save-finished-content",
  getWeeklyGoalList: "/api/v1/level-goals/list",
  helpCenter: "/api/v1/help-center",
  finishBook: "/api/v1/book/save-finished-book",
  edevletRedirect: "/api/v1/account/edevlet-get-redirect-url/",
  edevletCallBack: (token, code) =>
    `/api/v1/account/edevlet-callback?token=${token}&code=${code}`,
  performanceReport: "api/v1/report/performance",
  sendPerformanceMail: "/api/v1/report/performance-mail",
};

export default endpoints;
