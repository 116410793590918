import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { MainLayout, SemiTitle, PracticeList, Spinner } from "../../components";
import { PostService } from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { routes } from "../../constants/routes";
import { InView } from "react-intersection-observer";

const Speaking = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const perPage = 20;
  const subject_id = params?.state?.subject_id;
  const type = [4, 5];
  const category = 6;
  const subtitle = params?.state?.subtitle;
  const image = params?.state?.image;
  const level = params?.state?.level;
  const title = params?.state?.title;

  const [page, setPage] = useState(1);
  const [contentData, setContentData] = useState([]);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    params.state === null && navigate(routes.topic.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!inView) return;
    LoadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const LoadMoreData = async () => {
    const [result, error] = await contentPage({
      page: page,
      perPage: perPage,
      subject_id: subject_id,
      type: type,
      category: category,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setContentData([...contentData, ...resultData]);
      setPage(page + 1);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: contentPage, isLoading: isMoreLoading } = useMutation(
    PostService.getContentPage
  );

  const { mutateAsync: getContent, isLoading: isLoading } = useMutation(
    PostService.getContentList
  );

  const getData = async () => {
    const [result, error] = await getContent({
      page: page,
      perPage: perPage,
      subject_id: subject_id,
      type: type,
      category: category,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setContentData(resultData);
    }
    if (error) console.log(error);
  };

  return (
    <MainLayout
      topic={true}
      title={title}
      subtitle={subtitle}
      image_url={image}
      id={subject_id}
      level={level}
    >
      <Box mt="2" />
      <SemiTitle text={subtitle} mt="16" />
      {isLoading ? (
        <Spinner />
      ) : (
        <PracticeList Data={contentData} isMoreLoading={isMoreLoading} />
      )}
      <InView onChange={setInView}></InView>
    </MainLayout>
  );
};

export default Speaking;
