import React, { useEffect } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  SemiTitle,
  GrammarTextModal,
  Modals,
  //ScormModal,
} from "../../components";
import images from "../../constants/images";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";

const Grammar = () => {
  const navigate = useNavigate();
  const grammarTextModal = useDisclosure();
  //const scormModal = useDisclosure();
  const params = useLocation();
  const subject_id = params?.state?.subject_id;
  const image = params?.state?.image;
  const subtitle = params?.state?.subtitle;
  const level = params?.state?.level;
  const title = params?.state?.title;

  useEffect(() => {
    params.state === null && navigate(routes.topic.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      topic={true}
      title={title}
      subtitle={subtitle}
      image_url={image}
      id={subject_id}
      level={level}
    >
      <Title text={title + " - " + level} color={"title"} mt={8} />
      <SemiTitle text={subtitle} mt={-1} />
      <Image
        src={image}
        alt="grammarImage"
        h={"100%"}
        w={"50vh"}
        alignSelf={"center"}
        my="6"
        objectFit={"contain"}
      />
      <Box alignSelf={"center"} display="flex" gap={"3"} my={"2"} mb="45">
        <Image
          src={images.video}
          alt="video"
          h={"10vh"}
          w={"10vh"}
          onClick={() =>
            navigate(routes.grammarVideo.path, {
              state: { title, subject_id, image, subtitle, level },
            })
          }
          cursor="pointer"
        />
        <Image
          src={images.text}
          alt="text"
          h={"10vh"}
          w={"10vh"}
          mt="2"
          onClick={grammarTextModal.onOpen}
          cursor="pointer"
        />
        <Image
          src={images.practice}
          alt="practice"
          h={"10vh"}
          w={"10vh"}
          onClick={() =>
            navigate(routes.topicPractice.path, {
              state: { title, subject_id, image, subtitle, level },
            })
          }
          cursor="pointer"
        />
      </Box>
      <Modals
        onClose={grammarTextModal.onClose}
        isOpen={grammarTextModal.isOpen}
      >
        <GrammarTextModal subject_id={subject_id} />
      </Modals>

      {/* <Modals onClose={scormModal.onClose} isOpen={scormModal.isOpen}>
        <ScormModal />
      </Modals> */}
    </MainLayout>
  );
};

export default Grammar;
