import React, { useMemo, useState } from "react";
import { Text, Grid, GridItem, Box, Divider } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { PostService } from "../../services";
import { Spinner } from "..";
import { Flashcards } from "../../constants/data";
import { Player } from "@lottiefiles/react-lottie-player";
import "@fontsource/montserrat";

const DayMenu = () => {
  const [showCard, setShowCard] = useState(false);

  const { data, isLoading } = useQuery("days", PostService.getDayTab);

  const days = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  const flashCardImage = useMemo(() => {
    var flashCards = Flashcards.find((element) => element?.name === days?.name);
    return flashCards?.image;
  }, [days?.name]);

  const lottie = useMemo(() => {
    var flashCards = Flashcards.find((element) => element?.name === days?.name);
    return flashCards?.lottie;
  }, [days?.name]);

  return (
    <Box display={"flex"} flexDirection="column" gap={"4"}>
      <Grid
        templateColumns="repeat(2, 1fr)"
        display={"flex"}
        h={"20"}
        alignItems={"center"}
        borderRadius={10}
        borderColor="title"
        borderWidth={1}
        shadow={"md"}
        mt="-2.5"
      >
        <GridItemMenu
          day={days.day}
          text={"Current Streak"}
          isLoading={isLoading}
        />
        <Divider orientation="vertical" color={"title"} h={"100%"} />
        <GridItemMenu
          onClick={() => setShowCard(true)}
          day={days.name}
          text={">10 Days"}
          isLoading={isLoading}
        />
      </Grid>
      {showCard && (
        <Box
          w="100%"
          h="60vh"
          backgroundImage={flashCardImage}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
        >
          <Player
            src={lottie}
            autoplay
            loop
            style={{ height: "28vh", width: "100%" }}
          ></Player>
        </Box>
      )}
    </Box>
  );
};

export default DayMenu;

const GridItemMenu = ({
  onClick = () => {},
  day,
  text,
  textColor = "title",
  isLoading,
}) => {
  return (
    <GridItem
      w="100%"
      h="100%"
      textAlign={"center"}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      cursor="pointer"
      onClick={onClick}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Text
          color={textColor}
          fontFamily="montserrat"
          fontWeight="black"
          fontSize={"24"}
        >
          {day}
        </Text>
      )}
      <Text
        color={textColor}
        fontWeight="500"
        fontFamily={"montserrat"}
        fontSize={"10"}
        mt="-1.5"
      >
        {text}
      </Text>
    </GridItem>
  );
};
