/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Box } from "@chakra-ui/react";
import images from "../../constants/images";
import { MainHeader, Footer } from "..";
import "@fontsource/atma";

const ForgotPassLayout = ({ children }) => {
  return (
    <Box as="main" marginX="auto">
      <MainHeader />
      <Box
        w="100%"
        h="80vh"
        backgroundImage={images.forgotBackground}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        display={"flex"}
        flexDirection="column"
        alignItems={"center"}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default ForgotPassLayout;
