/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Image, Text, Link } from "@chakra-ui/react";
import images from "../constants/images";
import { ForgotPassLayout, DefaultButton } from "../components";
import { forgotPassLink } from "../constants";
import "@fontsource/atma";

const HowGetEbaPass = () => {
  return (
    <ForgotPassLayout>
      <Text
        fontFamily={"atma"}
        fontWeight={"bold"}
        color="title"
        fontSize={"32"}
        mt="10"
      >
        Şifremi Unutursam Ne Yapmalıyım?
      </Text>
      <Image src={images.forgotNumOne} w="7" mt={"9"} />
      <Text fontFamily={"atma"} fontSize={"20"} mt="1">
        "Şifremi Unuttum" bağlantısına tıkla.
      </Text>
      <Image src={images.forgotOk} w="7" mr={"4"} />
      <Image src={images.forgotNumTwo} w="7" mt="3" />
      <Text fontFamily={"atma"} fontSize={"20"}>
        e-posta adresine veya telefon numarana gelen kodu gir
      </Text>
      <Text fontFamily={"atma"} fontSize={"20"}>
        ve yeni şifreni oluştur.
      </Text>
      <Link
        _hover={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        href={forgotPassLink}
        isExternal
      >
        <DefaultButton
          borderRadius={"8"}
          backgroundColor={"title"}
          fontWeight={"dark"}
          fontSize={8}
          label={"Şifremi Unuttum"}
          color={"white"}
          height={"25"}
          borderColor={"black"}
          w={{ base: "100%", md: "100%" }}
          mt="5"
        />
      </Link>
    </ForgotPassLayout>
  );
};

export default HowGetEbaPass;
