/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import mainReducer from "../reducers/mainReducer";
import { INIT_DATA, SET_USER, SET_AUTH } from "../actionTypes";
import { AuthService } from "../../services";

export const MainContext = createContext();

const initialState = {
  authenticated: false,
  user: {},
};

const MainContextProvider = ({ children }) => {
  const [data, dispatch] = useReducer(mainReducer, initialState);

  const resetAuth = () => {
    AuthService.setToken("");
    AuthService.setLevel("");
    AuthService.setAuthorizationToken("", "");
    dispatch({ type: SET_USER, payload: {} });
    dispatch({ type: SET_AUTH, payload: false });
  };

  const initData = (data) => {
    dispatch({ type: INIT_DATA, payload: data });
  };

  const setUser = (data) => {
    dispatch({ type: SET_USER, payload: data });
  };

  const updateUser = (obj) => {
    dispatch({ type: SET_USER, payload: { ...data.user, ...obj } });
  };

  const setAuth = (data) => {
    dispatch({ type: SET_AUTH, payload: data });
  };

  const isLoggedIn = useMemo(() => {
    return data.authenticated;
  }, [data.user, data.authenticated]);

  return (
    <MainContext.Provider
      value={{
        data,
        dispatch,
        resetAuth,
        initData,
        setUser,
        setAuth,
        updateUser,
        isLoggedIn,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MainContextProvider;
