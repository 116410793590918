import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ContextlessPlayer as Player } from "@escolalms/h5p-react";

const LANG = "en";
const API_URL = "https://diyalektapp.eba.gov.tr";

const contentSettings = (uuid, token, lang = "en") => {
  let url = `${API_URL}/api/v1/h5p/content/${uuid}`;
  url = lang ? `${url}?lang=${lang}` : url;
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });
};

const H5PPlayer = () => {
  var { uuid, token } = useParams();

  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uuid && token) {
      setLoading(true);
      contentSettings(uuid, token, LANG)
        .then((res) => res.json())
        .then((data) => {
          setSettings(data.data);
          setLoading(false);
        });
    }
  }, [uuid, token]);

  return (
    <Box>
      <Player
        onXAPI={(e) => console.log("xAPI event", e)}
        state={settings}
        loading={loading}
      />
    </Box>
  );
};

export default H5PPlayer;
