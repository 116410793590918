import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MainLayout, Title, SemiTitle } from "../../components";

const AppointmentApprove = () => {
  return (
    <MainLayout practice={true}>
      <Title text="Practice" color="title" />
      <SemiTitle text={"Film & Music - B1"} />
      <Box textAlign={"center"} mt="5" mb={"20"}>
        <Text color={"dustyOrange"} fontSize="22" fontWeight={"extrabold"}>
          Congratulations
        </Text>
        <Text
          color={"dustyOrange"}
          mt={"7"}
          fontSize="13"
          fontWeight={"normal"}
        >
          Your Speaking appointment for the topic
        </Text>
        <Text
          mt={"1"}
          color={"dustyOrange"}
          fontWeight={"extrabold"}
          fontSize="15"
        >
          "Film & Music"
        </Text>
        <Text
          mt={"1"}
          color={"dustyOrange"}
          fontSize="13"
          fontWeight={"normal"}
        >
          has been created.
        </Text>
        <Text mt={"10"} color={"dustyOrange"} fontSize="13">
          You can find the link on your mail
        </Text>
        <Text mt={"-1"} color={"dustyOrange"} fontSize="13">
          and your profile section.
        </Text>
      </Box>
    </MainLayout>
  );
};

export default AppointmentApprove;
