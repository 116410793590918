import axios from 'axios'

export const BASE_URL = "https://diyalektapp.eba.gov.tr";

const API = axios.create({
  baseURL: BASE_URL,
});

export const setAuthorizationHeader = (token, level_id) => {
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  API.defaults.headers.common["X-USER-LEVEL"] = level_id;
  API.defaults.headers.common["X-PLATFORM-TYPE"] = 1;
  API.defaults.headers.common["type"] = 2;
};

export default API;
