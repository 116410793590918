import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Player } from "@lottiefiles/react-lottie-player";

const TopicCard = ({ onClick = () => {}, label, gif }) => {
  return (
    <Box onClick={onClick} cursor={"pointer"}>
      <Box
        w="100%"
        h="90"
        bgGradient="linear(to-br, #7C70FE, #8391FF, #7C70FE)"
        borderRadius={5}
        display="flex"
        alignItems="center"
        pb={"7"}
      >
        <Text
          flex={1}
          fontWeight={"bold"}
          fontSize={12}
          color={"white"}
          textAlign={"center"}
          mx="2"
        >
          {label}
        </Text>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box
          boxShadow="md"
          borderRadius={"100%"}
          backgroundColor={"white"}
          h={"16"}
          w={"16"}
          mt={-8}
          alignContent={"center"}
          alignItems={"center"}
          p={2}
        >
          <Player
            src={gif}
            autoplay
            loop
            style={{ height: "100%", width: "100%" }}
          ></Player>
        </Box>
      </Box>
    </Box>
  );
};

export default TopicCard;
