import React from "react";
import { Text, Button } from "@chakra-ui/react";

const DefaultButton = ({
  onClick = () => {},
  fontWeight = "900",
  label = "",
  color,
  backgroundColor,
  fontSize = "12",
  height = "30",
  disable,
  ...props
}) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      borderRadius={"12"}
      height={height}
      my="3"
      w={"100%"}
      disabled={disable}
      onClick={onClick}
      {...props}
    >
      <Text color={color} fontWeight={fontWeight} fontSize={fontSize}>
        {label}
      </Text>
    </Button>
  );
};

export default DefaultButton;
