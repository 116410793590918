import React, { useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, Title } from "../../components";
import images from "../../constants/images";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useLocation } from "react-router-dom";

const Categories = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const title = params?.state?.title;
  const subtitle = params?.state?.subtitle;
  const image_url = params?.state?.image_url;
  const id = params?.state?.id;
  const level = params?.state?.level;

  useEffect(() => {
    params.state === null && navigate(routes.topic.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContents = (route, title, subtitle, image, subject_id, level) => {
    navigate(route, {
      state: { title, subtitle, image, subject_id, level },
    });
  };

  return (
    <MainLayout
      title={title}
      subtitle={subtitle}
      image_url={image_url}
      id={id}
      level={level}
    >
      <Title text="Categories" color={"title"} />
      <Box
        flex={1}
        display="grid"
        gridGap={2}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        alignItems={"center"}
        mb={10}
        minH={"50vh"}
      >
        <Image
          src={images.vocabulary}
          alt="vocabulary"
          onClick={() =>
            onContents(
              routes.vocabulary.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
        <Image
          src={images.grammar}
          alt="grammar"
          onClick={() =>
            onContents(
              routes.grammar.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
        <Image
          src={images.reading}
          alt="reading"
          onClick={() =>
            onContents(
              routes.reading.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
        <Image
          src={images.listening}
          alt="listening"
          onClick={() =>
            onContents(
              routes.listening.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
        <Image
          src={images.writing}
          alt="writing"
          onClick={() =>
            onContents(
              routes.writing.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
        <Image
          src={images.speaking}
          alt="speaking"
          onClick={() =>
            onContents(
              routes.speaking.path,
              title,
              subtitle,
              image_url,
              id,
              level
            )
          }
          cursor={"pointer"}
        />
      </Box>
    </MainLayout>
  );
};

export default Categories;
