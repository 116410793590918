import React from "react";
import { Box } from "@chakra-ui/react";
import { TopicCard } from "..";
import { useNavigate } from "react-router-dom";
//import { routes } from "../../constants/routes";

const PracticeTopicList = ({ data = [], lottie = [], routes, ...props }) => {
  const navigate = useNavigate();

  const onDetail = (id, title, instruction) =>
    navigate(routes, {
      state: { id: id, title: title, instruction: instruction },
    });

  return (
    <Box
      flex={1}
      display="grid"
      gridGap={7}
      gridTemplateColumns={{
        base: "1fr 1fr",
        sm: "1fr 1fr",
        md: "1fr 1fr 1fr 1fr",
        lg: "1fr 1fr 1fr 1fr 1fr",
      }}
      alignItems={"center"}
      mb={10}
      {...props}
    >
      {data.map((item, index) => {
        return (
          <TopicCard
            key={index}
            index={index}
            onClick={() => onDetail(item?.id, item?.title, item?.instruction)}
            label={item?.title}
            gif={lottie[index]?.gif}
          />
        );
      })}
    </Box>
  );
};

export default PracticeTopicList;
