const images = {
  header: require("../../assets/kids/header.png"),
  play: require("../../assets/kids/play.png"),
  playActive: require("../../assets/kids/playActive.png"),
  enjoy: require("../../assets/kids/enjoy.png"),
  enjoyActive: require("../../assets/kids/enjoyActive.png"),
  learn: require("../../assets/kids/learn.png"),
  learnActive: require("../../assets/kids/learnActive.png"),
  read: require("../../assets/kids/read.png"),
  readActive: require("../../assets/kids/readActive.png"),
  home: require("../../assets/kids/home.png"),
  home2: require("../../assets/kids/home2.png"),
  home3: require("../../assets/kids/home3.png"),
  home4: require("../../assets/kids/home4.png"),
  home5: require("../../assets/kids/home5.png"),
  change: require("../../assets/kids/change.png"),
  kidsLogo: require("../../assets/kids/kidslogo.png"),
  playGamesMenu: require("../../assets/kids/playGamesMenu.png"),
  playFlashCardsMenu: require("../../assets/kids/playFlashCardsMenu.png"),
  enjoyAnimationsMenu: require("../../assets/kids/enjoyAnimationsMenu.png"),
  enjoyAudioBooksMenu: require("../../assets/kids/enjoyAudioBooksMenu.png"),
  enjoyCartoonsMenu: require("../../assets/kids/enjoyCartoonsMenu.png"),
  enjoySongsMenu: require("../../assets/kids/enjoySongMenu.png"),
  kidsEnjoyChose: require("../../assets/kids/kidsEnjoyChose.png"),
  choseSongs: require("../../assets/kids/choseSongs.png"),
  choseAudioBooks: require("../../assets/kids/choseAudioBooks.png"),
  choseCartoons: require("../../assets/kids/choseCartoons.png"),
  choseAnimations: require("../../assets/kids/choseAnimations.png"),
  choseFlashCards: require("../../assets/kids/choseFlashCards.png"),
};

export default images;
