import React, { useEffect, useState } from "react";
import { MainLayout, ContentList } from "../components";
import { useMutation } from "react-query";
import { KidsPostService } from "../../services";
import { Spinner } from "../../components";
import { InView } from "react-intersection-observer";

const Learn = () => {
  const perPage = 20;
  const category = 16;

  const [page, setPage] = useState(1);
  const [contentData, setContentData] = useState([]);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!inView) return;
    LoadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const LoadMoreData = async () => {
    const [result, error] = await contentPage({
      page: page,
      perPage: perPage,
      category: category,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setContentData([...contentData, ...resultData]);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: contentPage, isLoading: isMoreLoading } = useMutation(
    KidsPostService.getKidsContentPage
  );

  const { mutateAsync: getContent, isLoading: isLoading } = useMutation(
    KidsPostService.getKidsContentList
  );

  const getData = async () => {
    const [result, error] = await getContent({ perPage, category });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setContentData(resultData);
    }
    if (error) console.log(error);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <ContentList Data={contentData} isMoreLoading={isMoreLoading} />
      )}
      <InView onChange={setInView}></InView>
    </MainLayout>
  );
};

export default Learn;
