import React, { useMemo, useState } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import { MainLayout, Title, Modals, H5pModal } from "../../components";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const OnlineCourses = () => {
  const h5pModal = useDisclosure();

  const [value, setValue] = useState();

  const { data, isLoading } = useQuery("posts", PostService.init);

  const posts = useMemo(() => {
    return data && data.length > 0
      ? data[0]?.online_practice_courses
      : [] || [];
  }, [data]);

  const openModal = (value) => {
    setValue(value);
    h5pModal.onOpen();
  };

  return (
    <MainLayout practice={true}>
      <Title text={"Practice"} color="title" />
      <Box
        flex={1}
        display="grid"
        gap={8}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr",
          md: "1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        alignItems={"center"}
        mb={"10"}
        justifyContent={"center"}
        px={"12"}
      >
        {posts.map((item, index) => {
          return (
            <Box
              key={index}
              cursor="pointer"
              onClick={() => openModal(item?.value)}
            >
              <Image
                src={item?.image_url}
                alt="online courses"
                opacity={item?.is_completed ? 0.2 : 1}
              />
            </Box>
          );
        })}
      </Box>
      <Modals onClose={h5pModal.onClose} isOpen={h5pModal.isOpen}>
        <H5pModal uuid={value} />
      </Modals>
    </MainLayout>
  );
};

export default OnlineCourses;
