import React from "react";
import { Text, Box } from "@chakra-ui/react";
import Select from "react-dropdown-select";

const DropDownInput = ({ Data = [], label, labelField, ...props }) => {
  return (
    <>
      {label && (
        <Text fontSize={10} fontWeight={"medium"} ml={2} mt="1.5">
          {label}
        </Text>
      )}
      <Select
        options={Data}
        labelField={labelField}
        valueField="code"
        searchable={true}
        searchBy="name"
        style={{
          borderWidth: 1,
          borderColor: "lightgray",
          borderRadius: 8,
          fontSize: 8,
          color: "#9b9b9b",
          height: 20,
          marginTop: 2,
          marginBottom: 8,
          backgroundColor: "#f4f7f0",
          paddingLeft: 16,
        }}
        {...props}
      />
    </>
  );
};

export default DropDownInput;
