/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Spinner, Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useMainStore from "../hooks/useMainStore";
import { routes } from "../constants/routes";
import { AuthService } from "../services";
import { ShowAlert } from "../components";

const EdevletLogin = () => {
  const navigate = useNavigate();
  const { initData, setUser, setAuth } = useMainStore();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const code = params.get("code");

  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState("");

  const { mutateAsync, isLoading } = useMutation(AuthService.edevletCallBack);

  useEffect(() => {
    token && onLogin();
  }, []);

  const onLogin = async () => {
    try {
      const [data] = await mutateAsync({ token, code });
      if (data.token) {
        setUser(data);
        setAuth(true);
        initData(data);
        AuthService.setToken(data.token);
        AuthService.setLevel(data.level);
        AuthService.setAuthorizationToken(data.token, data.level, 1);
        navigate(routes.root.path);
      } else {
        setAlertData("Kullanıcı Bulunamadı");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertData(error);
      setShowAlert(true);
    }
  };

  return (
    <Box>
      {isLoading && (
        <Box>
          <Spinner />
          <ShowAlert
            data={alertData}
            isShow={showAlert}
            color="title"
            onClick={() => setShowAlert(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default EdevletLogin;
