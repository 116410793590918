import React from "react";
import { Box, Image, Text, Link } from "@chakra-ui/react";
import images from "../../constants/images";
import kidsImages from "../constants/images";
import useMainStore from "../../hooks/useMainStore";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import {
  termsUrl,
  privacyUrl,
  instagramLink,
  twitterLink,
  facebookLink,
  youtubeLink,
  appStoreLink,
  googlePlayLink,
} from "../../constants";

const KidsFooter = () => {
  const { isLoggedIn } = useMainStore();
  const navigate = useNavigate();

  const onRoot = async () => {
    navigate(routes.root.path);
  };

  return (
    <Box
      backgroundColor={"whiteTree"}
      mx={"auto"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      p={3}
      w={"100%"}
    >
      <Box display={"flex"} gap={10} ml={4}>
        <Image src={images.meb} w={"10vh"} h={"10vh"} />
        <Box
          flex={1}
          display="grid"
          flexDirection={"row"}
          gridGap={8}
          gridTemplateColumns={{
            base: "1fr",
            sm: "1fr 1fr",
            md: "1fr 1fr 1fr",
            lg: "1fr 1fr 1fr 1fr 1fr",
          }}
          justifyContent="center"
          ml={2}
        >
          <FooterItem>
            <FooterText text={"About"} fontWeight={"bold"} color="#fd2f94" />
            <FooterText
              text={"What is Diyalekt?"}
              fontWeight={"normal"}
              href={routes.whatIs.path}
              color="#fd2f94"
            />
            <FooterText
              text={"Contributors"}
              fontWeight={"normal"}
              href={routes.contributors.path}
              color="#fd2f94"
            />
          </FooterItem>
          <FooterItem>
            <FooterText
              text={"Help Center"}
              fontWeight={"bold"}
              color="#00b8f1"
            />
            <FooterText
              text={"User Guide"}
              fontWeight={"normal"}
              href={routes.userGuide.path}
              color="#00b8f1"
            />
            <FooterText
              text={"Terms"}
              fontWeight={"normal"}
              href={termsUrl}
              color="#00b8f1"
            />
            <FooterText
              text={"Privacy Policy"}
              fontWeight={"normal"}
              href={privacyUrl}
              color="#00b8f1"
            />
          </FooterItem>
          <FooterItem>
            <FooterText
              text={"Social Media"}
              fontWeight={"bold"}
              color="#01a601"
            />
            <FooterText
              text={"Instagram"}
              fontWeight={"normal"}
              href={instagramLink}
              color="#01a601"
            />
            <FooterText
              text={"Youtube"}
              fontWeight={"normal"}
              href={youtubeLink}
              color="#01a601"
            />
            <FooterText
              text={"Twitter"}
              fontWeight={"normal"}
              href={twitterLink}
              color="#01a601"
            />
            <FooterText
              text={"Facebook"}
              fontWeight={"normal"}
              href={facebookLink}
              color="#01a601"
            />
          </FooterItem>
          <Box>
            <Text fontWeight={"bold"} fontSize="10" color={"#ff7b00"} mb="2">
              Download
            </Text>
            <Link
              _hover={{ textDecoration: "none" }}
              _focus={{ boxShadow: "none" }}
              href={appStoreLink}
              isExternal
            >
              <Image
                src={images.appStore}
                resize="contain"
                w={100}
                h={35}
                mb="2"
              />
            </Link>
            <Link
              _hover={{ textDecoration: "none" }}
              _focus={{ boxShadow: "none" }}
              href={googlePlayLink}
              isExternal
            >
              <Image src={images.googlePlay} resize="contain" w={100} />
            </Link>
          </Box>
          <Box>
            {isLoggedIn && (
              <Image
                src={kidsImages.change}
                onClick={() => onRoot()}
                w="14vh"
                cursor={"pointer"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Text fontSize="9" fontWeight="normal" mt={4}>
        MEB © - All Rights Reserved. Services are provided within the framework
        of the rules specified in the Privacy, Use and Copyright notice.
      </Text>
    </Box>
  );
};

export default KidsFooter;

const FooterText = ({ href, text, fontWeight, color }) => {
  return (
    <Link
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      href={href}
      fontWeight={fontWeight}
      fontSize="10"
      fontFamily={"atma"}
      color={color}
    >
      {text}
    </Link>
  );
};

const FooterItem = ({ children }) => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      {children}
    </Box>
  );
};
