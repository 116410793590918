import React from "react";
import { Box, Image, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import "@fontsource/atma";

const HomeCard = ({ image, title, text, flexDirection, to = "#" }) => {
  return (
    <Link
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      display={"flex"}
      flexDirection={{ base: "column", md: flexDirection }}
      gap={"10"}
      alignItems="center"
      justifyContent={"center"}
      mb="10"
      to={to}
      as={RouterLink}
    >
      <Image src={image} w={{ base: "65%", md: "40%" }} />
      <Box w={{ base: "80%", md: "25%" }}>
        <Text fontFamily={"atma"} fontWeight={"bold"} fontSize="32" mb={"1"}>
          {title}
        </Text>
        <Text fontFamily={"atma"} fontSize="24">
          {text}
        </Text>
      </Box>
    </Link>
  );
};

export default HomeCard;
