import React from "react";
import { Box, Text, Link, Image } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { routes } from "../constants/routes";
import images from "../constants/images";
import useMainStore from "../hooks/useMainStore";

const SideBar = ({
  topic = false,
  snacks = false,
  practice = false,
  title,
  subtitle,
  image_url,
  id,
  level,
}) => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const location = useLocation();

  const onContents = (route, title, subtitle, image, subject_id, level) => {
    navigate(route, {
      state: { title, subtitle, image, subject_id, level },
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} mt="5">
      <SideBarMenu
        text={"TOPIC"}
        image={images.topicSidebar}
        onClick={() => navigate(routes.topic.path)}
      />
      {topic && (
        <Box ml={10}>
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.vocabulary.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={location.pathname === routes.vocabulary.path}
            text={"Vocabulary"}
            color={"hotPurple"}
          />
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.grammar.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={
              location.pathname === routes.grammar.path ||
              location.pathname === routes.topicPractice.path
            }
            text={"Grammar"}
            color={"hotPurple"}
          />
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.reading.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={location.pathname === routes.reading.path}
            text={"Reading"}
            color={"hotPurple"}
          />
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.listening.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={location.pathname === routes.listening.path}
            text={"Listening"}
            color={"hotPurple"}
          />
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.writing.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={location.pathname === routes.writing.path}
            text={"Writing"}
            color={"hotPurple"}
          />
          <TopicBarItem
            onClick={() =>
              onContents(
                routes.speaking.path,
                title,
                subtitle,
                image_url,
                id,
                level
              )
            }
            isActive={location.pathname === routes.speaking.path}
            text={"Speaking"}
            color={"hotPurple"}
          />
        </Box>
      )}
      <SideBarMenu
        text={"SNACKS"}
        image={images.snacksSidebar}
        onClick={() => navigate(routes.snacks.path)}
      />
      {snacks && (
        <Box ml={10}>
          <SideBarItem
            to={routes.books.path}
            isActive={
              location.pathname === routes.books.path ||
              location.pathname === routes.bookDetails.path
            }
            text={"Books"}
            color={"macaroniAndCheese"}
          />
          <SideBarItem
            to={routes.news.path}
            isActive={location.pathname === routes.news.path}
            text={"News"}
            color={"macaroniAndCheese"}
          />
          <SideBarItem
            to={routes.discover.path}
            isActive={
              location.pathname === routes.discover.path ||
              location.pathname === routes.discoverDetails.path
            }
            text={"Discover"}
            color={"macaroniAndCheese"}
          />
        </Box>
      )}
      {/* <SideBarMenu
        text={"TALK TO DIO"}
        image={images.talkToDioSidebar}
        //onClick={() => {}}
      /> */}
      <SideBarMenu
        text={"PRACTICE"}
        image={images.practiceSidebar}
        onClick={() => navigate(routes.practice.path)}
      />
      {practice && (
        <Box ml={10}>
          <SideBarItem
            to={routes.onlineCourses.path}
            isActive={location.pathname === routes.onlineCourses.path}
            text={"Online Courses"}
            color={"kiwiGreen"}
          />
          <SideBarItem
            to={user.level > 2 && routes.onlineSpeaking.path}
            isActive={
              location.pathname === routes.onlineSpeaking.path ||
              location.pathname === routes.appointment.path ||
              location.pathname === routes.appointmentApprove.path
            }
            text={"Online Speaking"}
            color={"kiwiGreen"}
          />
          <SideBarItem
            to={user.level > 2 && routes.practiceWriting.path}
            isActive={
              location.pathname === routes.practiceWriting.path ||
              location.pathname === routes.writingDetails.path
            }
            text={"Writing"}
            color={"kiwiGreen"}
          />
        </Box>
      )}
    </Box>
  );
};

export default SideBar;

const SideBarItem = ({ isActive = false, to = "#", text, color }) => {
  return (
    <Link
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      as={RouterLink}
      to={to}
      fontWeight={"bold"}
      fontSize={14}
      color={isActive ? color : "warmGray"}
      display={"flex"}
      flexDirection={"column"}
      mb="2"
    >
      {text}
    </Link>
  );
};

const SideBarMenu = ({ onClick = () => {}, text, image }) => {
  return (
    <Box
      display={"flex"}
      gap={"2"}
      onClick={onClick}
      cursor={"pointer"}
      alignItems={"center"}
      my="2"
    >
      <Image src={image} alt="topicSidebar" maxW={"4.8vh"} objectFit="cover" />
      <Text my={1} fontSize={14} fontWeight={"900"}>
        {text}
      </Text>
    </Box>
  );
};

const TopicBarItem = ({
  onClick = () => {},
  isActive = false,
  to = "#",
  text,
  color,
}) => {
  return (
    <Text
      fontWeight={"bold"}
      fontSize={14}
      color={isActive ? color : "warmGray"}
      display={"flex"}
      flexDirection={"column"}
      mb="2"
      cursor={"pointer"}
      onClick={onClick}
    >
      {text}
    </Text>
  );
};
