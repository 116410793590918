import React, { useEffect, useState } from "react";
import { Box, Textarea, Image, Text } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  SemiTitle,
  DefaultButton,
  ShowAlert,
  Spinner,
} from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { PostService } from "../../services";
import { routes } from "../../constants/routes";
import images from "../../constants/images";

const WritingDetails = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const title = params?.state?.title;
  const id = params?.state?.id;
  const instruction = params?.state?.instruction;

  const [text, setText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState(instruction);

  useEffect(() => {
    !id && navigate(routes.practiceWriting.path);
  }, [id]);

  const { mutateAsync: sendWriting, isLoading: isLoading } = useMutation(
    PostService.sendWriting
  );

  const onSendText = async () => {
    const { data } = await sendWriting({
      subject_id: id,
      text: text,
    });
    if (data.id) {
      navigate(routes.practiceWriting.path);
      setText("");
    }
    if (data.success === false) {
      setAlertData(data.message);
      setShowAlert(true);
    }
  };

  return (
    <MainLayout practice={true}>
      <Title text="Practice" color="title" />
      <SemiTitle text={title} />
      <Box>
        {instruction ? (
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            gap="2"
            my={3}
          >
            <Image
              src={images.instruction}
              alt="instruction"
              boxSize={5}
              cursor="pointer"
              onClick={() => setShowAlert(true)}
            />
            <Text color="title" fontWeight="600" fontSize="11">
              Instructions
            </Text>
          </Box>
        ) : (
          <Box my={3} />
        )}
        <Box
          mb="5"
          px={"1"}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
        >
          <Textarea
            fontSize={"14"}
            fontWeight="normal"
            borderWidth={1}
            borderColor={"lightgray"}
            backgroundColor={"input"}
            size={"lg"}
            h="50vh"
            value={text}
            name="text"
            onChange={(e) => setText(e.target.value)}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <DefaultButton
              backgroundColor={"title"}
              fontWeight={"bold"}
              fontSize={10}
              label={"Send"}
              color={"white"}
              height={"30"}
              w={"20%"}
              onClick={() => onSendText()}
              disabled={!text.length > 0}
            />
          )}
        </Box>
      </Box>

      <ShowAlert
        data={alertData}
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default WritingDetails;
