import React from "react";
import { Box } from "@chakra-ui/react";
import Spinner from "../Spinner";

const GrammarVideoList = ({ data = [], isMoreLoading, ...props }) => {
  return (
    <Box
      flex={1}
      display="grid"
      gridGap={2}
      gridTemplateColumns={{
        base: "1fr 1fr",
        sm: "1fr 1fr 1fr",
        md: "1fr 1fr 1fr",
        lg: "1fr 1fr",
      }}
      // minH={"55vh"}
      mt={4}
      mb={16}
      {...props}
    >
      {data.map((item, index) => {
        return (
          <video key={index} controls width="100%" controlsList="nodownload">
            <source src={item?.value} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        );
      })}
      {isMoreLoading && <Spinner />}
    </Box>
  );
};

export default GrammarVideoList;
