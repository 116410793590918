import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MainLayout, Title } from "../../components";

const Contributors = () => {
  return (
    <MainLayout>
      <Title text={"Contributors"} color="title" />
      <Box display={"flex"} alignSelf={"center"} mt="5" w="100%" gap={"3"}>
        <Box flex={1} textAlign="end">
          <Text fontWeight={"bold"} mb="1">
            Project Manager
          </Text>
          <Text fontWeight={"bold"} mb="1">
            Project Consultant
          </Text>
          <Text fontWeight={"bold"} mb="1">
            Art Director
          </Text>
          <Text fontWeight={"bold"} mb="1.5">
            Project Coordinators
          </Text>
          <br />
          <Text fontWeight={"bold"} mb="1">
            System Manager
          </Text>
          <Text fontWeight={"bold"} mb="5">
            Project Assistants
          </Text>
          <br />
          <br />
          <br />
          <br />
          <Text fontWeight={"bold"} mb="1" mt="0.5">
            Graphic Designer
          </Text>
          <Text fontWeight={"bold"} mb="1">
            Marketing Coordinator
          </Text>
          <Text fontWeight={"bold"} mb="1">
            Video Content Creator
          </Text>
          <Text fontWeight={"bold"} mb="1">
            Administrative Officer
          </Text>
        </Box>
        <Box flex={1}>
          <Text mb="1">Ali Kılıçlıoğlu</Text>
          <Text mb="1">Doç. Dr. Tuncer Can</Text>
          <Text mb="1">Ekrem Acar</Text>
          <Text mb="1">Dr. Ayhan Öztürk</Text>
          <Text mb="1">Emine Demirel Kaya</Text>
          <Text mb="1">Ahmet Şamil Demircan</Text>
          <Text mb="1">Cemrenur Mangır</Text>
          <Text mb="1">Ece Sağlık</Text>
          <Text mb="1">Merve Erçin</Text>
          <Text mb="1">Neslihan Kaynar Zehir</Text>
          <Text mb="1">Tuğçe Kaptan Tabatabaei</Text>
          <Text mb="1">Ceyhun Doğan</Text>
          <Text mb="1">Savaş Kürşat Yirmibeş</Text>
          <Text mb="1">Sema Söyler</Text>
          <Text mb="1">Muharrem Tanyıldızı</Text>
        </Box>
      </Box>
      <Text textAlign={"center"} fontSize="20" mt="8" fontWeight={"black"}>
        Diyalekt Content Providers
      </Text>
      <Text textAlign={"center"} fontSize="16" mt="4" fontWeight={"black"}>
        Talim Ve Terbiye Kurulu Başkanlığı
      </Text>
      <Box display={"flex"} alignSelf={"center"} mt="3" w="100%" gap={"3"}>
        <Box flex={1} textAlign="center">
          <Text mb="1">Abdullah Sağdıç</Text>
          <Text mb="1">Adem Yurdunkulu</Text>
          <Text mb="1">Adnan Gülözer</Text>
          <Text mb="1">Ajda Mutlu</Text>
          <Text mb="1">Alper Aslantatar</Text>
          <Text mb="1">Amine Gülşah Ölçer </Text>
          <Text mb="1">Asiye Kanak</Text>
          <Text mb="1">Aysel Sağlam</Text>
          <Text mb="1">Aysel Seyidoğlu</Text>
          <Text mb="1">Aysun Kanıcıoğlu</Text>
          <Text mb="1">Aysun Mete</Text>
          <Text mb="1">Ayşe Zambak</Text>
          <Text mb="1">Ayten Kayacan</Text>
          <Text mb="1">Bahar Çağrı Şan</Text>
          <Text mb="1">Bahar Özcan</Text>
          <Text mb="1">Başak Akad</Text>
          <Text mb="1">Bilge Akıncı</Text>
          <Text mb="1">Burcu Yoğurtcu</Text>
          <Text mb="1">Canan Koçak</Text>
          <Text mb="1">Caner Çatak</Text>
          <Text mb="1">Çağla Orkan</Text>
          <Text mb="1">Defne Kılıç</Text>
          <Text mb="1">Demet Kuzu</Text>
          <Text mb="1">Dilek Gördü</Text>
          <Text mb="1">Dilek Şahin</Text>
          <Text mb="1">Elif Arslan Uğurlu</Text>
          <Text mb="1">Elif Fergane</Text>
          <Text mb="1">Elif Tertemiz Arat</Text>
          <Text mb="1">Emel Topçu</Text>
          <Text mb="1">Emine Esin Ünlü</Text>
          <Text mb="1">Emre Ünsal</Text>
          <Text mb="1">Enise Ulu Çoğalan</Text>
          <Text mb="1">Erhan Duran</Text>
          <Text mb="1">Ersan Uz</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Esra Kandilci</Text>
          <Text mb="1">Esra Öztürk Çalık</Text>
          <Text mb="1">Fadime Üstüner</Text>
          <Text mb="1">Fatma Bilgin</Text>
          <Text mb="1">Fatma Gamze Derinkök</Text>
          <Text mb="1">Fatma Gür Arslan</Text>
          <Text mb="1">Gülay Özbebit</Text>
          <Text mb="1">Gülbahar Özcan</Text>
          <Text mb="1">Güldane Büyükçam</Text>
          <Text mb="1">Gülten Akgül</Text>
          <Text mb="1">Güney Toker</Text>
          <Text mb="1">Habip Arvas</Text>
          <Text mb="1">Hatice İğsen</Text>
          <Text mb="1">Hatice Tuba Kaan</Text>
          <Text mb="1">Hilal Arslan</Text>
          <Text mb="1">İlknur Sağlam</Text>
          <Text mb="1">İmsal Işıl Gılıç</Text>
          <Text mb="1">İnnap Kaya</Text>
          <Text mb="1">İsa Gümüşsoy</Text>
          <Text mb="1">Kamil Günay</Text>
          <Text mb="1">Kamil Ünal Baştürk</Text>
          <Text mb="1">Kâmuran Sungur</Text>
          <Text mb="1">Latife Çeri</Text>
          <Text mb="1">Leyla Çağlar Yurttaş</Text>
          <Text mb="1">Mahmut İnan</Text>
          <Text mb="1">Medine Güney</Text>
          <Text mb="1">Melek Özkayacan</Text>
          <Text mb="1">Muharrem Tanyıldızı</Text>
          <Text mb="1">Murat Sinci</Text>
          <Text mb="1">Mustafa Oğuz Demir</Text>
          <Text mb="1">Naciye Selin Taştekin</Text>
          <Text mb="1">Nilüfer Dinç Demirok</Text>
          <Text mb="1">Nurcan Ada Çetinkaya</Text>
          <Text mb="1">Nurşen Kaya</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Özdem Ünal</Text>
          <Text mb="1">Özge Yılmaz</Text>
          <Text mb="1">Özgü Öztürk</Text>
          <Text mb="1">Öznur Eroğlu</Text>
          <Text mb="1">Pelin Bölükbaşı</Text>
          <Text mb="1">Pınar Sağlam İnce</Text>
          <Text mb="1">Rabia Yılmaz</Text>
          <Text mb="1">Rezzan Gizir</Text>
          <Text mb="1">Rümeysa Özdemir</Text>
          <Text mb="1">Seçil Sezen</Text>
          <Text mb="1">Seda Çelik</Text>
          <Text mb="1">Sefa Sezer</Text>
          <Text mb="1">Seher Yumrutepe</Text>
          <Text mb="1">Selma Uğur</Text>
          <Text mb="1">Sema Türkmen Büyükkeçeci</Text>
          <Text mb="1">Sevda Bulak</Text>
          <Text mb="1">Sevgi Bülbül</Text>
          <Text mb="1">Sevgi Yılmaz</Text>
          <Text mb="1">Sevil Erkoç Yüksek</Text>
          <Text mb="1">Şaduman Birinci</Text>
          <Text mb="1">Tuba Kaan</Text>
          <Text mb="1">Tuğçe Tütüncü</Text>
          <Text mb="1">Ümit Oral</Text>
          <Text mb="1">Vasfiye Şanlı</Text>
          <Text mb="1">Vesile Erbaş</Text>
          <Text mb="1">Volkan İrken</Text>
          <Text mb="1">Yasemin Erenkuş</Text>
          <Text mb="1">Yavuz Karadağ</Text>
          <Text mb="1">Yusuf Çakır</Text>
          <Text mb="1">Zehra Tamer</Text>
          <Text mb="1">Zeynep Kalıncı</Text>
          <Text mb="1">Zeynep Sav</Text>
        </Box>
      </Box>
      <Text textAlign={"center"} fontSize="16" mt="8" fontWeight={"black"}>
        Yeğitek Genel Müdürlüğü
      </Text>
      <Box display={"flex"} alignSelf={"center"} mt="3" w="100%" gap={"3"}>
        <Box flex={1} textAlign="center">
          <Text mb="1">Ahmet Aksu</Text>
          <Text mb="1">Ali Rıza Çiftçi</Text>
          <Text mb="1">Arda Yorulmaz</Text>
          <Text mb="1">Arif Yener Develi</Text>
          <Text mb="1">Barışcan Uzay</Text>
          <Text mb="1">Beyza Demirci</Text>
          <Text mb="1">Birtan Yıldız</Text>
          <Text mb="1">Cihangir Savaş Meşe</Text>
          <Text mb="1">Elmas Erbaş</Text>
          <Text mb="1">Esin Özgenç</Text>
          <Text mb="1">Fahriye Eroğlu</Text>
          <Text mb="1">Funda İnce</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Gamze Halat</Text>
          <Text mb="1">Hacer Koç Yıldız</Text>
          <Text mb="1">Hakan Önal</Text>
          <Text mb="1">Hakan Öztürk</Text>
          <Text mb="1">Hüseyin Kaplan</Text>
          <Text mb="1">Kemal Efe</Text>
          <Text mb="1">Mehmet Hasdemir</Text>
          <Text mb="1">Merve İlik</Text>
          <Text mb="1">Meryem Kıvılcım</Text>
          <Text mb="1">Naci Arslan</Text>
          <Text mb="1">Osman Karagöz</Text>
          <Text mb="1">Özlem Sevimli</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Saadet Kurulay</Text>
          <Text mb="1">Sema Nur Aydoğan</Text>
          <Text mb="1">Serkan Sönmez</Text>
          <Text mb="1">Seylan Akdeniz</Text>
          <Text mb="1">Sezai Mercan</Text>
          <Text mb="1">Sinan Sevimli</Text>
          <Text mb="1">Sultan Uçar Altun</Text>
          <Text mb="1">Yasin Yıldız</Text>
          <Text mb="1">Yasin Kartal</Text>
          <Text mb="1">Zeynep Şahin</Text>
        </Box>
      </Box>
      <Text textAlign={"center"} fontSize="16" mt="8" fontWeight={"black"}>
        Ortaöğretim Genel Müdürlüğü
      </Text>
      <Box display={"flex"} alignSelf={"center"} mt="3" w="100%" gap={"3"}>
        <Box flex={1} textAlign="center">
          <Text mb="1">Arzu Şentürk Yeniçeri</Text>
          <Text mb="1">Başak Akad</Text>
          <Text mb="1">Çiğdem Ocak</Text>
          <Text mb="1">Defne Kılıç</Text>
          <Text mb="1">Doğan Demirci</Text>
          <Text mb="1">Elif Arslan Uğurlu </Text>
          <Text mb="1">Esra Kandilci</Text>
          <Text mb="1">Gizem Çeçen</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Gül Seda Topal</Text>
          <Text mb="1">Hatice Tuba Kaan</Text>
          <Text mb="1">İbrahim Durgut</Text>
          <Text mb="1">İlknur Erdebil</Text>
          <Text mb="1">İsa Gümüşsoy</Text>
          <Text mb="1">Mustafa Korkutan</Text>
          <Text mb="1">Naciye Selin Taştekin</Text>
          <Text mb="1">Pelin Karagöz</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Seda Sümer</Text>
          <Text mb="1">Ümit Oral</Text>
          <Text mb="1">Yiğit Kiriş</Text>
          <Text mb="1">Zehra Tamer</Text>
          <Text mb="1">Zeki Kaya</Text>
          <Text mb="1">Zeynep Atay Kemikli</Text>
          <Text mb="1">Zülal Yıldız</Text>
        </Box>
      </Box>
      <Text textAlign={"center"} fontSize="20" mt="8" fontWeight={"black"}>
        DiyalektKids Content Providers
      </Text>
      <Text textAlign={"center"} fontSize="16" mt="4" fontWeight={"black"}>
        Temel Eğitim Genel Müdürlüğü
      </Text>
      <Box display={"flex"} alignSelf={"center"} mt="3" w="100%" gap={"3"}>
        <Box flex={1} textAlign="center">
          <Text mb="1">Ahmet Arif Özen</Text>
          <Text mb="1">Ahmet Salim Göktaş</Text>
          <Text mb="1">Ali Kaştan</Text>
          <Text mb="1">Ali Keleş</Text>
          <Text mb="1">Ali Mert Ünal</Text>
          <Text mb="1">Aslıhan Mestanoğlu</Text>
          <Text mb="1">Ayhan Usta</Text>
          <Text mb="1">Aysun Tonya Kasar</Text>
          <Text mb="1">Ayşe Gül Ünlü</Text>
          <Text mb="1">Ayşegül Tan</Text>
          <Text mb="1">Babür Kezer</Text>
          <Text mb="1">Bünyamin Telli</Text>
          <Text mb="1">Büşra Sarıkaya</Text>
          <Text mb="1">Cem Atasoy</Text>
          <Text mb="1">Cem Savaş</Text>
          <Text mb="1">Coşku Aker</Text>
          <Text mb="1">Çağdaş Mollaoğlu</Text>
          <Text mb="1">Demet Ülgünalp</Text>
          <Text mb="1">Dilek Fidancı</Text>
          <Text mb="1">Dr. Mehmet Akçaalan</Text>
          <Text mb="1">Dr. Sultan Uçar Altun</Text>
          <Text mb="1">Elçin Kuzucu</Text>
          <Text mb="1">Elçin Kuzucu</Text>
          <Text mb="1">Emel Atar</Text>
          <Text mb="1">Emine Çatlı</Text>
          <Text mb="1">Emre Yıldırım</Text>
          <Text mb="1">Engin Karadoğan</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Enver Ülker</Text>
          <Text mb="1">Ercan Yandımoğlu</Text>
          <Text mb="1">Erdem Topuzoğlu</Text>
          <Text mb="1">Erhan Ay</Text>
          <Text mb="1">Fahriye Eroğlu</Text>
          <Text mb="1">Fatma Arzu Ağaoğlu</Text>
          <Text mb="1">Fatma Kahyaoğlu</Text>
          <Text mb="1">Ferah Akın</Text>
          <Text mb="1">Gökhan Kahyaoğlu</Text>
          <Text mb="1">Gülay Başaran</Text>
          <Text mb="1">Gülşen Çepik</Text>
          <Text mb="1">Hafize Açar Kaştan</Text>
          <Text mb="1">Harun Karabulut</Text>
          <Text mb="1">Hasan Arlı</Text>
          <Text mb="1">Hatice Dursun</Text>
          <Text mb="1">Hülya Erdem Özbaş</Text>
          <Text mb="1">İlknur İzgi İpekel</Text>
          <Text mb="1">İzzet Öztürk</Text>
          <Text mb="1">Kader Kaya</Text>
          <Text mb="1">Mehmet Emre Öz</Text>
          <Text mb="1">Mehmet Yaşar Toprak</Text>
          <Text mb="1">Merve Sarı</Text>
          <Text mb="1">Nilgün Bozna</Text>
          <Text mb="1">Oktay Keskin</Text>
          <Text mb="1">Onur Gönüllü</Text>
          <Text mb="1">Oya Pangal</Text>
          <Text mb="1">Özben Akseki</Text>
        </Box>
        <Box flex={1} textAlign="center">
          <Text mb="1">Özcan Elgünlü</Text>
          <Text mb="1">Özge Bakay</Text>
          <Text mb="1">Özge Kurt</Text>
          <Text mb="1">Özgül Önalan</Text>
          <Text mb="1">Pelin Dizge Kulelioğlu</Text>
          <Text mb="1">Recep Uluçay</Text>
          <Text mb="1">Sadık Türkel</Text>
          <Text mb="1">Samet Temiz</Text>
          <Text mb="1">Saniye Kuleli</Text>
          <Text mb="1">Sema Söyler</Text>
          <Text mb="1">Senem Çevik</Text>
          <Text mb="1">Serkan Utlu</Text>
          <Text mb="1">Sultan Altun</Text>
          <Text mb="1">Şenay Tufan İlhan</Text>
          <Text mb="1">Şerife Özlem Doğan</Text>
          <Text mb="1">Şükrü Onur Çinar</Text>
          <Text mb="1">Taylan Dergin</Text>
          <Text mb="1">Tuğba Özbaykuş</Text>
          <Text mb="1">Vahide Küçükballı</Text>
          <Text mb="1">Yavuz Karadağ</Text>
          <Text mb="1">Zehra Aydın</Text>
          <Text mb="1">Zehra Yedigöz Kara</Text>
          <Text mb="1">Zeynep Akdoğan</Text>
          <Text mb="1">Zeynep Şahin</Text>
        </Box>
      </Box>
      <Text textAlign={"center"} mb={"5"} mt="10">
        The list is alphabetical.
      </Text>
    </MainLayout>
  );
};

export default Contributors;
