import React, { useMemo } from "react";
import { Text, Box } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const GrammarTextModal = (subject_id) => {
  const perPage = 15;
  const type = [3];
  const category = 1;

  const { data, isLoading } = useQuery(
    ["posts", perPage, subject_id, type, category],
    () =>
      PostService.getContentList({
        perPage: perPage,
        subject_id: subject_id,
        type: type,
        category: category,
      })
  );

  const posts = useMemo(() => {
    return data && data.length > 0 ? data[0].data : [] || [];
  }, [data]);

  return (
    <Box display={"flex"} alignItems={"center"} h={"57vh"}>
      <Box h={"50vh"} overflowY="scroll" ml={5}>
        {posts.map((item, index) => {
          return (
            <Box key={index}>
              <Text fontWeight={"bold"} fontSize={"16"} p="1">
                {item?.name}
              </Text>
              <Text
                p="1"
                fontSize={"14"}
                dangerouslySetInnerHTML={{ __html: item?.value }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default GrammarTextModal;
