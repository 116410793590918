import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill={"#f4bc03"}
      {...props}
    >
      <path
        data-name="Path 48"
        d="M22.789 11.218v19.063a.205.205 0 0 1-.208.208h-.755a.205.205 0 0 1-.208-.208V11.218a1.012 1.012 0 0 1-.417-.833 1.023 1.023 0 0 1 1.3-.99.964.964 0 0 1 .7.7 1.144 1.144 0 0 1-.412 1.123z"
        transform="translate(-21.2 -9.359)"
        fill={"#f4bc03"}
      />
      <path
        data-name="Path 49"
        d="M42.957 17.528v8.021a.619.619 0 0 1-.313.495 6.025 6.025 0 0 1-5.781.078 7.74 7.74 0 0 0-6.146-.521.307.307 0 0 1-.417-.286v-8.073a.32.32 0 0 1 .182-.286 7.127 7.127 0 0 1 6.068.313c3.776 1.719 4.557.729 6.068.052a.23.23 0 0 1 .339.208z"
        transform="translate(-27.93 -14.574)"
        fill={"#f4bc03"}
      />
    </svg>
  );
}

export default SvgComponent;
