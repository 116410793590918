import API from "../api";
import endpoints from "../api/endpoints";

const getBooks = async (perPage) => {
  const { data } = await API.get(endpoints.getBooks, { params: { perPage } });
  return [data?.data, null];
};

const getMoreBooks = async ({ page, perPage }) => {
  const { data } = await API.get(endpoints.getBookFromPage(page), {
    params: { perPage },
  });
  return [data?.data, null];
};

const getBookDetails = async (postId) => {
  const { data } = await API.get(endpoints.getBookDetails(postId));
  return [data?.data, null];
};

const getDiscoverList = async (perPage) => {
  const { data } = await API.get(endpoints.getDiscoverList, {
    params: { perPage },
  });
  return [data?.data, null];
};

const getDiscoverPage = async ({ page, perPage }) => {
  const { data } = await API.get(endpoints.getDiscoverPageList(page), {
    params: { perPage },
  });
  return [data?.data, null];
};

const getDiscoverDetails = async (postId) => {
  const { data } = await API.get(endpoints.getDiscoverDetails(postId));
  return [data?.data, null];
};

const getNews = async ({ perPage, newsType }) => {
  const { data } = await API.get(endpoints.getNews, {
    params: { perPage, newsType },
  });
  return [data?.data, null];
};

const getNewsPage = async ({ page, perPage, newsType }) => {
  const { data } = await API.get(endpoints.getNewsPage(page), {
    params: { perPage, newsType },
  });
  return [data?.data, null];
};

const getNewsDetails = async (postId) => {
  const { data } = await API.get(endpoints.getNewsDetails(postId));
  return [data?.data, null];
};

const getTopicList = async () => {
  const { data } = await API.get(endpoints.getTopicList);
  return [data?.data, null];
};

const init = async () => {
  const { data } = await API.get(endpoints.init);
  return [data?.data, null];
};

const getMeetingDates = async ({ content_subject_id, date }) => {
  const { data } = await API.post(endpoints.getMeetingDates, {
    content_subject_id,
    date,
  });
  return [data?.data, null];
};

const getContentList = async ({ perPage, subject_id, type, category }) => {
  const { data } = await API.get(endpoints.contentList, {
    params: { perPage, subject_id, type, category },
  });
  return [data?.data, null];
};

const getContentPage = async ({
  page,
  perPage,
  subject_id,
  type,
  category,
}) => {
  const { data } = await API.get(endpoints.getContentPage(page), {
    params: { perPage, subject_id, type, category },
  });
  return [data?.data, null];
};

const SetMeeting = async ({ content_subject_id, date, meeting_period_id }) => {
  const { data } = await API.post(endpoints.setMeeting, {
    content_subject_id,
    date,
    meeting_period_id,
  });
  return [data, null];
};

const getMeeting = async () => {
  const { data } = await API.get(endpoints.getMeeting);
  return [data, null];
};

const sendWriting = async ({ subject_id, text }) => {
  const formData = new FormData();
  formData.append("subject_id", subject_id);
  formData.append("text", text);
  return await API.post(endpoints.sendWriting, formData)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const getMyWriting = async () => {
  const { data } = await API.get(endpoints.getMyWriting);
  return [data, null];
};

const deleteMeeting = async (postId) => {
  const { data } = await API.delete(endpoints.deleteMeeting(postId));
  return [data, null];
};

const getDayTab = async () => {
  const { data } = await API.get(endpoints.getDayTab);
  return [data, null];
};

const getLessonTab = async () => {
  const { data } = await API.get(endpoints.getLessonTab);
  return [data, null];
};

const getLevelTab = async () => {
  const { data } = await API.get(endpoints.getLevelTab);
  return [data, null];
};

const showWriting = async (postId) => {
  const { data } = await API.get(endpoints.showWriting(postId));
  return [data, null];
};

const startUp = async () => {
  const { data } = await API.get(endpoints.startUp);
  return [data?.data, null];
};

const getWeeklyGoalList = async () => {
  const { data } = await API.get(endpoints.getWeeklyGoalList);
  return [data, null];
};

const helpCenter = async ({ subject, name, email, message }) => {
  const formData = new FormData();
  formData.append("subject", subject);
  formData.append("name", name);
  formData.append("email", email);
  formData.append("message", message);
  const { data } = await API.post(endpoints.helpCenter, formData);
  return [data, null];
};

const finishBook = async (book_id) => {
  return await API.post(endpoints.finishBook, { book_id })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const finishScorm = async (content_id) => {
  return await API.post(endpoints.finishScorm, { content_id })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const PostService = {
  getBooks,
  getMoreBooks,
  getBookDetails,
  getDiscoverList,
  getDiscoverPage,
  getDiscoverDetails,
  getNews,
  getNewsDetails,
  getTopicList,
  init,
  getMeetingDates,
  getContentList,
  getContentPage,
  SetMeeting,
  getMeeting,
  sendWriting,
  getMyWriting,
  deleteMeeting,
  getDayTab,
  getLessonTab,
  getLevelTab,
  showWriting,
  startUp,
  getWeeklyGoalList,
  getNewsPage,
  helpCenter,
  finishBook,
  finishScorm
};

export default PostService;
