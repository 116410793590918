import React, { useState, useRef, useEffect } from "react";
import { Box, Image, Text, Checkbox, Input as CInput } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  AuthInput,
  PhoneNumberInput,
  DefaultButton,
  DropDownInput,
  ShowAlert,
} from "../../components";
import images from "../../constants/images";
import useMainStore from "../../hooks/useMainStore";
import { countries, graduation, Languages } from "../../constants/data";
import useForm from "../../hooks/useForm";
import * as yup from "yup";
import "yup-phone";
import { useMutation } from "react-query";
import { UserService } from "../../services";

const ProfileInfo = () => {
  const {
    data: { user },
    updateUser,
  } = useMainStore();

  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState();

  useEffect(() => {
    if (user?.avatar_id === 2) avatar1Check();
    else if (user?.avatar_id === 3) avatar2Check();
    else if (user?.avatar_id === 4) avatar3Check();
    else if (user?.avatar_id === 5) avatar4Check();
    else if (user?.avatar_id === 6) avatar5Check();
    else if (user?.avatar_id === 7) avatar6Check();
    else if (user?.avatar_id === 8) avatar7Check();
    else if (user?.avatar_id === 9) avatar8Check();
    else if (user?.avatar_id === 10) avatar9Check();
    else if (user?.avatar_id === 11) avatar10Check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutateAsync: updateUserAsync, isLoading } = useMutation(
    UserService.updateUser
  );

  const { mutateAsync: updateAvatar, isLoading: updateAvatarLoading } =
    useMutation(UserService.updateAvatar);

  const [image, setImage] = useState({ url: null, image: null });
  const imageInput = useRef();

  const validation = yup.object({
    first_name: yup.string().required("Name field is required"),
    last_name: yup.string().required("Surname field is required"),
    nationality: yup.string().required("Nationality field is required"),
    phone: yup
      .string()
      .phone("TR", undefined, "Invalid Phone")
      .required("Phone field is required"),
    email: yup
      .string()
      .email("Invalid Email")
      .required("Email field is required"),
    birthday: yup.string().required("Birthday field is required"),
    languages: yup.array().required("Languages field is required"),
    graduation: yup.number().required("Graduation field is required"),
  });

  const {
    values,
    setValues,
    errors,
    isValid,
    onFormSubmit,
    setValuesDirectly,
  } = useForm(
    {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      nationality: user?.nationality || "",
      phone: user?.phone || "",
      email: user?.email || "",
      birthday: user?.birthday || "",
      languages: user?.languages || [],
      graduation: user?.graduation || "",
    },
    validation
  );

  const onSave = async () => {
    onFormSubmit();
    if (!isValid) {
      setAlertData("please check that the information is complete");
      setShowAlert(true);
      return;
    }
    try {
      const [data] = await updateUserAsync(values);
      onUpdateAvatar();
      if (data.success) {
        updateUser(values);
        setAlertData(data.message);
        setShowAlert(true);
      }
    } catch (error) {
      if (error.response.data.message) {
        setAlertData(error.response.data.message);
        setShowAlert(true);
      }
    }
  };

  const [avatarId, setAvatarId] = useState();
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);
  const [check7, setCheck7] = useState(false);
  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);
  const [check10, setCheck10] = useState(false);

  const avatars = [
    { key: 1, avatar: images.avatar1, value: false },
    { key: 2, avatar: images.avatar2, value: false },
    { key: 3, avatar: images.avatar3, value: false },
    { key: 4, avatar: images.avatar4, value: false },
    { key: 5, avatar: images.avatar5, value: false },
    { key: 6, avatar: images.avatar6, value: false },
    { key: 7, avatar: images.avatar7, value: false },
    { key: 8, avatar: images.avatar8, value: false },
    { key: 9, avatar: images.avatar9, value: false },
    { key: 10, avatar: images.avatar10, value: false },
  ];

  const avatar1Check = () => {
    setAvatarId(2);
    setCheck(!check);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar2Check = () => {
    setAvatarId(3);
    setCheck(false);
    setCheck2(!check2);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar3Check = () => {
    setAvatarId(4);
    setCheck(false);
    setCheck2(false);
    setCheck3(!check3);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar4Check = () => {
    setAvatarId(5);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(!check4);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar5Check = () => {
    setAvatarId(6);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(!check5);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar6Check = () => {
    setAvatarId(7);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(!check6);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar7Check = () => {
    setAvatarId(8);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(!check7);
    setCheck8(false);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar8Check = () => {
    setAvatarId(9);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(!check8);
    setCheck9(false);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar9Check = () => {
    setAvatarId(10);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(!check9);
    setCheck10(false);
    setImage({ url: null, image: null });
  };
  const avatar10Check = () => {
    setAvatarId(11);
    setCheck(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setCheck6(false);
    setCheck7(false);
    setCheck8(false);
    setCheck9(false);
    setCheck10(!check10);
    setImage({ url: null, image: null });
  };

  const onUpdateAvatar = async () => {
    try {
      if (image.image === null && avatarId === 1) return;
      if (avatarId) {
        const [data] = await updateAvatar({
          image: image.image,
          avatarId: avatarId,
        });
        if (data?.success) {
          updateUser({
            avatar_id: parseInt(data?.data.avatar_id),
            avatar: data?.data?.avatar,
          });
        }
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onSelectImage = async () => {
    imageInput.current?.click();
  };

  const onUploadImage = async (e) => {
    const image = e.target.files[0];
    setImage({ url: URL.createObjectURL(image), image });
    setAvatarId(1);
  };

  var languageValue = Languages.find(
    (element) => element?.code === values.languages[0]
  );

  return (
    <MainLayout>
      <Title text={"Profile Information"} color="title" />
      <Box
        display="flex"
        flexDirection={"column"}
        w={{ base: "80%", md: "45", lg: "40%" }}
        alignSelf="center"
        mb={10}
      >
        <AuthInput
          label={"Name"}
          name="first_name"
          h="30"
          value={values.first_name}
          onChange={(val) => setValues(val)}
        />
        <AuthInput
          label={"Surname"}
          h="30"
          name="last_name"
          value={values?.last_name}
          onChange={setValues}
        />
        <DropDownInput
          label={"Nationality"}
          Data={countries}
          labelField="name"
          values={[{ name: values?.nationality }]}
          onChange={(val) => setValuesDirectly({ nationality: val[0]?.name })}
        />
        <PhoneNumberInput
          label={"Phone Number"}
          h="30"
          name="phone"
          value={values?.phone}
          onChange={setValues}
        />
        <AuthInput
          label={"E-mail"}
          h="30"
          name="email"
          value={values?.email}
          onChange={setValues}
        />
        <AuthInput
          label={"Birthday"}
          h="30"
          type="date"
          name="birthday"
          value={values?.birthday}
          onChange={setValues}
        />
        <DropDownInput
          label={"Languages"}
          Data={Languages}
          labelField="name"
          values={[{ name: languageValue?.name }]}
          onChange={(val) => setValuesDirectly({ languages: [val[0]?.code] })}
          //onChange={(val) => console.log([val[val.length - 1]?.name])}
        />
        <DropDownInput
          label={"Graduation"}
          Data={graduation}
          labelField="name"
          values={[{ name: graduation[parseInt(values.graduation) - 1].name }]}
          onChange={(value) =>
            setValuesDirectly({ graduation: value[0]?.code })
          }
        />

        <Box>
          <Text fontSize={11} fontWeight={"medium"} ml={2} mb="-2">
            Avatar
          </Text>
          <Box
            flex={1}
            display="grid"
            gridTemplateColumns={{
              base: "1fr 1fr 1fr",
              sm: "1fr 1fr 1fr",
              md: "1fr 1fr 1fr 1fr",
              lg: "1fr 1fr 1fr 1fr 1fr",
            }}
            alignItems="center"
          >
            <AvatarItem
              avatar={avatars[0].avatar}
              value={check}
              onChange={() => avatar1Check()}
            />
            <AvatarItem
              avatar={avatars[1].avatar}
              value={check2}
              onChange={() => avatar2Check()}
            />
            <AvatarItem
              avatar={avatars[2].avatar}
              value={check3}
              onChange={() => avatar3Check()}
            />
            <AvatarItem
              avatar={avatars[3].avatar}
              value={check4}
              onChange={() => avatar4Check()}
            />
            <AvatarItem
              avatar={avatars[4].avatar}
              value={check5}
              onChange={() => avatar5Check()}
            />
            <AvatarItem
              avatar={avatars[5].avatar}
              value={check6}
              onChange={() => avatar6Check()}
            />
            <AvatarItem
              avatar={avatars[6].avatar}
              value={check7}
              onChange={() => avatar7Check()}
            />
            <AvatarItem
              avatar={avatars[7].avatar}
              value={check8}
              onChange={() => avatar8Check()}
            />
            <AvatarItem
              avatar={avatars[8].avatar}
              value={check9}
              onChange={() => avatar9Check()}
            />
            <AvatarItem
              avatar={avatars[9].avatar}
              value={check10}
              onChange={() => avatar10Check()}
            />
          </Box>
          <Text
            fontSize={"10"}
            color="title"
            fontWeight={"800"}
            textAlign={"end"}
            cursor={"pointer"}
            onClick={onSelectImage}
          >
            upload photo...
          </Text>
          <CInput
            ref={imageInput}
            type="file"
            accept="image/*"
            display="none"
            onChange={onUploadImage}
          />
        </Box>

        <DefaultButton
          color={"white"}
          fontSize={"10"}
          height="30"
          label="Save Profile"
          backgroundColor={"title"}
          fontWeight="bold"
          w={"40%"}
          alignSelf="center"
          onClick={() => onSave()}
        />
      </Box>
      <ShowAlert
        isShow={showAlert}
        data={alertData}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default ProfileInfo;

const AvatarItem = ({ onChange = () => {}, avatar, value }) => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent="space-between"
      my="2"
      h="14"
    >
      <Image src={avatar} resize={"cover"} w="4vh" h="10" />
      <Checkbox
        spacing="1rem"
        size="sm"
        iconColor="black"
        borderWidth={1}
        value={value}
        isChecked={value}
        onChange={onChange}
      />
    </Box>
  );
};
