import React, { useEffect, useState } from "react";
import {
  MainLayout,
  Title,
  SemiTitle,
  GrammarVideoList,
} from "../../components";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { PostService } from "../../services";
import { routes } from "../../constants/routes";

const GrammarVideo = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const subject_id = params?.state?.subject_id;
  const image = params?.state?.image;
  const subtitle = params?.state?.subtitle;
  const level = params?.state?.level;
  const title = params?.state?.title;
  const perPage = 20;
  const type = [1];
  const category = 1;

  const [page, setPage] = useState(1);
  const [contentData, setContentData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    params.state === null && navigate(routes.topic.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LoadMoreData();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    LoadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleScroll = () => {
    window.innerHeight + document.documentElement.scrollTop ===
      document.scrollingElement.scrollHeight + 0.5 ||
    window.innerHeight + document.documentElement.scrollTop ===
      document.scrollingElement.scrollHeight
      ? setIsFetching(true)
      : setIsFetching(false);
  };

  const LoadMoreData = async () => {
    const [result, error] = await contentPage({
      page: page,
      perPage: perPage,
      subject_id: subject_id,
      type: type,
      category: category,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setContentData([...contentData, ...resultData]);
      setPage(page + 1);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: contentPage, isLoading: isMoreLoading } = useMutation(
    PostService.getContentPage
  );

  return (
    <MainLayout
      topic={true}
      title={title}
      subtitle={subtitle}
      image_url={image}
      id={subject_id}
      level={level}
    >
      <Title text={title + " - " + level} color={"title"} />
      <SemiTitle text={subtitle} mt={1} />
      <GrammarVideoList data={contentData} isMoreLoading={isMoreLoading} />
    </MainLayout>
  );
};

export default GrammarVideo;
