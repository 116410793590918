import React from "react";
import { Box, Text } from "@chakra-ui/react";

const SemiTitle = ({ text, fontSize = 16, ...props }) => {
  return (
    <Box alignSelf={"center"} w={"55%"} {...props}>
      <Box backgroundColor={"pinkishGrey"} h={"0.2vh"} />
      <Text
        textAlign={"center"}
        my={"2"}
        color={"orange"}
        fontWeight={"bold"}
        fontSize={fontSize}
      >
        {text}
      </Text>
      <Box backgroundColor={"pinkishGrey"} h={"0.2vh"} />
    </Box>
  );
};

export default SemiTitle;
