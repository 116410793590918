import React from "react";
import { Box, Text } from "@chakra-ui/react";

const GoalCard = ({ text, color, image, progress, rate, ...props }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      gap={1}
      my={3}
      flex={1}
      {...props}
    >
      <Box
        display={"flex"}
        borderRadius={"100%"}
        h={16}
        w={16}
        alignItems={"center"}
        justifyContent={"center"}
        backgroundImage={image}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
      >
        <Text color={"white"} fontWeight={"bold"} fontSize={20}>
          {rate}
        </Text>
      </Box>
      <Box flex={1}>
        <Text color={color} fontSize={11} fontWeight={"600"}>
          {text}
        </Text>
        <Box backgroundColor={color} h={1} />
        <Box backgroundColor={"title"} h={1} w={progress} />
      </Box>
    </Box>
  );
};

export default GoalCard;
