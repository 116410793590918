import React, { useState } from "react";
import { Box, Textarea, Text } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  AuthInput,
  DefaultButton,
  ShowAlert,
  Spinner,
} from "../../components";
import * as yup from "yup";
import { useMutation } from "react-query";
import useForm from "../../hooks/useForm";
import { PostService } from "../../services";

const UserGuide = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState("");

  const { mutateAsync, isLoading } = useMutation(PostService.helpCenter);

  const validation = yup.object({
    subject: yup.string().required("Subject field is required"),
    name: yup.string().required("Name field is required"),
    email: yup
      .string()
      .email("Invalid Email")
      .required("Email field is required"),
    message: yup.string().required("Message field is required"),
  });

  const { values, setValues, errors, isValid } = useForm(
    {
      subject: "",
      name: "",
      email: "",
      message: "",
    },
    validation
  );

  const onSubmit = async () => {
    if (!isValid) {
      Object.keys(errors).forEach((key) => {
        setAlertData(errors[key]);
      });
      setShowAlert(true);
      return;
    }
    const [data, error] = await mutateAsync(values);
    if (data.success) {
      setAlertData(data.message);
      setShowAlert(true);
    }
    if (error) {
      setAlertData(error.response.data.message);
      setShowAlert(true);
    }
  };

  return (
    <MainLayout>
      <Title text={"Help Center"} color={"title"} />
      <Box
        display={"flex"}
        flexDirection="column"
        w={{ base: "100%", md: "100%" }}
      >
        <Text color={"title"} fontWeight="500" fontSize={"16"} mb="2">
          User Guide
        </Text>
        <video controls width="100%" controlsList="nodownload">
          <source
            src={
              "https://diyalekt-cdn.eba.gov.tr/static-videos/diyalekt-how-to.mp4"
            }
            type="video/mp4"
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <Box display={"flex"} flexDirection="column" mt="10" mb="10">
          <Text color={"title"} fontWeight="500" fontSize={"16"}>
            Contact Us
          </Text>
          <AuthInput
            label="Subject"
            name="subject"
            value={values.subject}
            onChange={(val) => setValues(val)}
          />
          <AuthInput
            label="Name"
            name="name"
            value={values.name}
            onChange={(val) => setValues(val)}
          />
          <AuthInput
            label="Email"
            name="email"
            value={values.email}
            onChange={(val) => setValues(val)}
          />
          <Text fontSize={10} fontWeight={"medium"} ml={2} mt="1.5">
            Message
          </Text>
          <Textarea
            fontSize={9}
            fontWeight="normal"
            borderWidth={1}
            borderColor={"lightgray"}
            backgroundColor={"input"}
            size={"lg"}
            h="10vh"
            value={values.message}
            name="message"
            onChange={(val) => setValues(val)}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <DefaultButton
              backgroundColor={"title"}
              fontWeight={"bold"}
              fontSize={10}
              label={"Send"}
              color={"white"}
              height={"30"}
              w={"20%"}
              alignSelf="center"
              mt="4"
              disabled={isLoading}
              onClick={() => onSubmit()}
            />
          )}
        </Box>
      </Box>
      <ShowAlert
        data={alertData}
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default UserGuide;
