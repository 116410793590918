import API from "../api";
import endpoints from "../api/endpoints";

const getKidsContentList = async ({ perPage, category }) => {
  const { data } = await API.get(endpoints.contentList, {
    params: { perPage, category },
  });
  return [data?.data, null];
};

const getKidsContentPage = async ({ page, perPage, category }) => {
  const { data } = await API.get(endpoints.getContentPage(page), {
    params: { perPage, category },
  });
  return [data?.data, null];
};

const getScorm = async (uuid) => {
  const { data } = await API.get(endpoints.getScorm(uuid));
  return [data?.data, null];
};

const KidsPostService = { getKidsContentList, getScorm, getKidsContentPage };

export default KidsPostService;
