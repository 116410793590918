/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState } from "react";
import { Box, Text, Image } from "@chakra-ui/react";
import { MainLayout, Title, ShowAlert, Spinner } from "../../components";
import { useQuery, useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { PostService } from "../../services";
import { routes } from "../../constants/routes";
import images from "../../constants/images";
import $ from "jquery";

const BookDetails = () => {
  const params = useLocation();
  const id = params?.state?.id;
  const navigate = useNavigate();

  const [letter, setLetter] = useState("");
  const [showWords, setShowWords] = useState(false);
  const [words, setWords] = useState([]);
  const [fontSize, setFontSize] = useState(14);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState("");

  useEffect(() => {
    params.state === null && navigate(routes.books.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading } = useQuery(["bookDetails", id], () =>
    PostService.getBookDetails(id)
  );

  const bookDetails = useMemo(() => {
    return data && data.length > 0 ? data[0] : [] || [];
  }, [data]);

  useEffect(() => {
    ClickWord();
  }, []);

  const ClickWord = () => {
    $(".clickable").click(function (e) {
      var selection = window.getSelection();
      if (!selection || selection.rangeCount < 1) return true;
      var range = selection.getRangeAt(0);
      var node = selection.anchorNode;
      var word_regexp = /^\w*$/;

      // Extend the range backward until it matches word beginning
      while (range.startOffset > 0 && range.toString().match(word_regexp)) {
        range.setStart(node, range.startOffset - 1);
      }
      // Restore the valid word match after overshooting
      if (!range.toString().match(word_regexp)) {
        range.setStart(node, range.startOffset + 1);
      }

      // Extend the range forward until it matches word ending
      while (
        range.endOffset < node.length &&
        range.toString().match(word_regexp)
      ) {
        range.setEnd(node, range.endOffset + 1);
      }
      // Restore the valid word match after overshooting
      if (!range.toString().match(word_regexp)) {
        range.setEnd(node, range.endOffset - 1);
      }

      var word = range.toString();
      getDictionary(word);
    });
  };

  const getDictionary = async (str) => {
    setShowWords(true);
    const ar = str;
    setLetter(ar);
    const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${ar}`;
    const response = await fetch(url);
    const text = await response.json();

    const meaningsData = text[0].meanings;
    setWords(meaningsData);
  };

  const textPlus = () => {
    setFontSize(fontSize + 2);
  };

  const textMinus = () => {
    setFontSize(fontSize - 2);
  };

  const { mutateAsync, isLoading: isFinishing } = useMutation(
    PostService.finishBook
  );

  const FinishBook = async (book_id) => {
    const data = await mutateAsync(book_id);
    if (data.success) {
      setAlertData(data.message);
      setShowAlert(true);
    }
    if (data.success === false) {
      setAlertData(data.message);
      setShowAlert(true);
    }
  };

  return (
    <MainLayout snacks={true}>
      <Title text={"Books"} color={"title"} />
      <Box
        borderWidth={2}
        borderColor="title"
        borderRadius={8}
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        mb="15"
        maxH={"70vh"}
        maxW={"100%"}
      >
        <Text
          fontSize={10}
          ml={5}
          mt={1}
          fontWeight="bold"
          alignSelf={"baseline"}
        >
          {bookDetails.title}
        </Text>
        <Box h={"60vh"} w="95%" mt={3} mb={1} ml={5} mr={5} overflowY="scroll">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="clickable">
              <Box
                h={"50vh"}
                fontSize={fontSize}
                dangerouslySetInnerHTML={{
                  __html: `
              <!DOCTYPE html>
              <html>
              <body>
              ${bookDetails.value}
              </body>
              </html>
              `,
                }}
              />
            </div>
          )}
        </Box>
        <Box display={"flex"} alignSelf="flex-end" mr={4} p={"2"} gap="2">
          <Image
            src={images.textPlus}
            alt="textPlus"
            w={5}
            cursor="pointer"
            onClick={() => textPlus()}
          />
          <Image
            src={images.textMinus}
            alt="textMinus"
            w={5}
            cursor="pointer"
            onClick={() => textMinus()}
          />
          <Text
            backgroundColor={"orange"}
            color="white"
            p="1"
            fontWeight={"bold"}
            fontSize={9}
            borderRadius={14}
            cursor="pointer"
            _disabled={isFinishing}
            onClick={() => FinishBook(bookDetails.id)}
          >
            Completed
          </Text>
        </Box>
        {showWords ? (
          <Box
            backgroundColor={"orange"}
            borderTopLeftRadius={10}
            borderTopRightRadius={10}
            borderColor="title"
            display="flex"
            flexDirection={"column"}
            borderWidth={2}
            maxH="15vh"
            w={{ base: "100%", lg: "51vw" }}
            overflowY="scroll"
          >
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              mt="3"
            >
              <Text
                display={"flex"}
                gap="2"
                color={"white"}
                ml="3"
                fontSize={12}
              >
                Definitions of<Text fontWeight={"bold"}>{letter}</Text>
              </Text>
              <Image
                src={images.cancel}
                onClick={() => setShowWords(false)}
                w={5}
                mr="5"
                cursor="pointer"
              />
            </Box>

            {words.map((item, index) => {
              return (
                <Box key={index} my="3" mx={3}>
                  <Text color={"white"} fontWeight="bold" fontSize={12}>
                    {item?.partOfSpeech}
                  </Text>
                  {item?.definitions.map((item2, index) => {
                    return (
                      <Box key={index}>
                        <Text color={"white"} fontSize={12}>
                          {index + 1}
                          {". "}
                          {item2?.definition}
                        </Text>
                      </Box>
                    );
                  })}
                  <Box display="flex" gap="1">
                    <Text color={"white"} fontSize={12}>
                      synonyms:
                    </Text>
                    {item?.synonyms.map((synItem, index) => {
                      return (
                        <Text key={index} color={"white"} fontSize={12}>
                          {synItem},
                        </Text>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
      <ShowAlert
        data={alertData}
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default BookDetails;
