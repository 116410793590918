/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Icon,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
} from "@chakra-ui/react";
import images from "../../constants/images";
import { ChartBar, Check, Flag, EngLanguage, Menu as Nav } from "../icons";
import {
  LevelMenu,
  DayMenu,
  LessonMenu,
  ProfileMenu,
  UserAvatarCard,
  SideBar,
  MainSection,
} from "..";
import useMainStore from "../../hooks/useMainStore";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { avatars } from "../../constants/data";
import { useMediaQuery } from "react-responsive";

const MainHader = ({
  platform,
  topic = false,
  snacks = false,
  practice = false,
  title,
  subtitle,
  image_url,
  id,
  level,
}) => {
  const {
    data: { user },
    isLoggedIn,
  } = useMainStore();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <Box as="nav" backgroundColor={"title"}>
      <Box
        display={"flex"}
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        justifyContent={"space-between"}
        maxWidth={{ base: "95%", sm: "85%", md: "85%", lg: "68%" }}
        marginX={"auto"}
        h={{ base: "100px", md: "90px" }}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent="center"
        >
          {isTablet && (
            <Icon
              as={Nav}
              boxSize={7}
              color="white"
              ml={5}
              onClick={onOpen}
              mr="5"
            />
          )}
          <Image
            src={images.logo}
            alt="logo"
            h={[34, 50, 55]}
            w={[82, 124, 175]}
            mt={{ base: 2, md: 0 }}
            cursor="pointer"
            onClick={() => navigate(routes.home.path)}
          />
        </Box>
        {isLoggedIn && platform && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
            alignItems={"center"}
            mb={{ base: 1, md: 0 }}
          >
            {isMobile && (
              <Icon
                as={Nav}
                boxSize={7}
                color="white"
                ml={5}
                onClick={onOpen}
                mr="5"
              />
            )}
            {isTabletOrMobile && (
              <Drawer
                placement={"left"}
                onClose={onClose}
                isOpen={isOpen}
                size={"xs"}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader backgroundColor={"title"}>
                    <Image
                      src={images.logo}
                      alt="logo"
                      h={[34, 50, 55]}
                      w={[82, 124, 175]}
                      mt={{ base: 2, md: 0 }}
                      cursor="pointer"
                      onClick={() => navigate(routes.home.path)}
                    />
                  </DrawerHeader>
                  <DrawerBody>
                    <MainSection>
                      <SideBar
                        topic={topic}
                        snacks={snacks}
                        practice={practice}
                        title={title}
                        subtitle={subtitle}
                        image_url={image_url}
                        id={id}
                        level={level}
                      />
                    </MainSection>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            )}
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems="center"
              marginX={"12px"}
            >
              <Icon as={EngLanguage} boxSize={6} />
              <Text color={"white"} fontSize={12} fontWeight={"medium"}>
                English
              </Text>
            </Box>
            <NavItem
              icon={ChartBar}
              text={"Level"}
              isBorder={true}
              placement="bottom"
              menu={<LevelMenu />}
            />
            <NavItem
              icon={Flag}
              text={"Day"}
              placement="bottom-end"
              menu={<DayMenu />}
            />
            <NavItem
              icon={Check}
              text={"Lesson"}
              isBorder={true}
              placement="bottom-end"
              menu={<LessonMenu />}
            />
            <UserAvatarCard
              name={user?.first_name}
              surname={user?.last_name}
              avatar={
                user?.avatar_id === 1
                  ? user?.avatar
                    ? { uri: user?.avatar }
                    : images.avatar
                  : user?.avatar_id
                  ? avatars[user?.avatar_id - 2]?.avatar
                  : images.avatar
              }
              menu={<ProfileMenu />}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const NavItem = ({
  icon,
  text,
  menu,
  isBorder = false,
  placement = "bottom",
}) => {
  const [isMenuText, setIsMenuText] = useState("");
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems="center"
      marginX={"12px"}
    >
      <Menu
        placement={placement}
        flip={true}
        isLazy
        onClose={() => setIsMenuText("")}
      >
        <MenuButton onClick={() => setIsMenuText(text)}>
          <Box display={"flex"} flexDirection={"column"} alignItems="center">
            <Icon as={icon} boxSize={6} />
            <Text
              color={"white"}
              fontSize={12}
              fontWeight={"medium"}
              as={text === isMenuText && "u"}
            >
              {text}
            </Text>
          </Box>
        </MenuButton>
        {isBorder ? (
          <MenuList
            w={"140%"}
            borderColor="title"
            borderRadius={10}
            shadow="lg"
          >
            {menu}
          </MenuList>
        ) : (
          <MenuList
            w={{ base: "140%", lg: "140%" }}
            border="none"
            shadow={"none"}
            borderRadius={8}
          >
            {menu}
          </MenuList>
        )}
      </Menu>
    </Box>
  );
};

export default MainHader;
