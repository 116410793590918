import React from "react";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";

const Modals = ({ onClose = () => {}, isOpen = false, children }) => {
  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
    >
      <ModalOverlay bg={"rgb(125,113,255, 0.8)"} />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  );
};

export default Modals;
