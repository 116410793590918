import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { MainLayout, Spinner, Title } from "../../components";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const WhatIs = () => {
  const { data, isLoading } = useQuery("whatIs", PostService.startUp);

  const whatIs = useMemo(() => {
    return data && data.length > 0 ? data[0]?.static_pages : [] || [];
  }, [data]);

  return (
    <MainLayout>
      <Title text={"What Is DİYALEKT"} color="title" />
      <Box flex={1} mb={6} ml={5}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box
            fontSize={14}
            dangerouslySetInnerHTML={{ __html: whatIs.what_is_diyalekt }}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default WhatIs;
