import React from "react";
import { Text } from "@chakra-ui/react";

const Title = ({ color, text, mt = 10, ...props }) => {
  return (
    <Text
      fontSize={20}
      color={color}
      textAlign={"center"}
      mt={mt}
      mb={5}
      fontWeight="black"
      {...props}
    >
      {text}
    </Text>
  );
};

export default Title;
