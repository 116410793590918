import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout } from "../components";
import images from "../constants/images";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const EnjoyChoose = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Box
        backgroundImage={images.kidsEnjoyChose}
        backgroundPosition={"center"}
        backgroundSize={"contain"}
        backgroundRepeat={"no-repeat"}
        h={{ base: "50vh", md: "70vh" }}
        display={"flex"}
        justifyContent="center"
      >
        <Box
          display={"flex"}
          flexDirection="column"
          w={{ base: "90%", md: "65%" }}
          mt="14"
        >
          <Box display={"flex"} justifyContent="end">
            <ImageBtn
              image={images.choseSongs}
              onClick={() => navigate(routes.enjoySongs.path)}
            />
            <ImageBtn
              image={images.choseAudioBooks}
              onClick={() => navigate(routes.enjoyAudioBooks.path)}
            />
            <ImageBtn
              image={images.choseCartoons}
              onClick={() => navigate(routes.enjoyCartoons.path)}
            />
          </Box>
          <Box display={"flex"} justifyContent="end" mt={"2"}>
            <ImageBtn
              image={images.choseAnimations}
              onClick={() => navigate(routes.enjoyAnimations.path)}
            />
            <ImageBtn
              image={images.choseFlashCards}
              onClick={() => navigate(routes.playFlashCards.path)}
            /> 
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default EnjoyChoose;

const ImageBtn = ({ onClick = () => {}, image }) => {
  return <Image src={image} w={"33%"} cursor="pointer" onClick={onClick} />;
};
