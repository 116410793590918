import React, { useMemo, useState } from "react";
import { Box, Text, Divider, Link } from "@chakra-ui/react";
import { MainLayout, Title, ShowAlert } from "../../components";
import { PostService } from "../../services";
import { useQuery, useMutation } from "react-query";
import moment from "moment";

const MyAppointments = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [id, setId] = useState();

  const { data, isLoading } = useQuery(
    "myAppointments",
    PostService.getMeeting
  );

  const myAppointments = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  const { mutateAsync: deleteMeeting, isLoading: isDeletingMeeting } =
    useMutation(PostService.deleteMeeting);

  const deleteAppointment = async (postId) => {
    const [result, error] = await deleteMeeting(postId);
    if (result.success) window.location.reload(false);
  };

  const showDeleteAlert = (_id) => {
    setShowAlert(true);
    setId(_id);
  };

  return (
    <MainLayout>
      <Title text={"My Appointments"} color="title" />
      <Box px={20} mr="2" ml={1} minH={"60vh"}>
        {myAppointments.map((item, index) => {
          return (
            <Box key={index}>
              <Box
                display={"flex"}
                fontSize={10}
                justifyContent="space-between"
                p={2}
              >
                <Box display={"flex"} color="denim" gap={1} alignItems="center">
                  <Link
                    display={"flex"}
                    color="denim"
                    gap={1}
                    alignItems="center"
                    href={item?.meeting_url}
                    target="#"
                  >
                    <Text fontWeight={"bold"}>
                      {" "}
                      {moment(item?.date).subtract("days").calendar()}
                    </Text>
                    <Text>{moment(item?.date).format("dddd")}</Text>
                    <Text>at</Text>
                    <Text fontWeight={"bold"}>
                      {moment(item?.date).format("LT")}
                    </Text>
                  </Link>
                  <Text
                    backgroundColor={"title"}
                    color="white"
                    borderRadius={10}
                    fontSize={7}
                    p="0.5"
                    fontWeight={"bold"}
                    cursor="pointer"
                    _disabled={isDeletingMeeting}
                    onClick={() => showDeleteAlert(item?.id)}
                  >
                    Delete
                  </Text>
                </Box>
                <Link
                  href={item?.meeting_url}
                  target="#"
                  color={"dustyOrange"}
                  fontWeight="bold"
                >
                  {item?.content_subject}
                </Link>
              </Box>
              <Divider color={"pinkishGrey"} />
            </Box>
          );
        })}
      </Box>
      <ShowAlert
        data="Are you sure you want to delete appointment?"
        isShow={showAlert}
        onClick={() => setShowAlert(false)}
        isYes={true}
        color="orange"
        onYesClick={() => deleteAppointment(id)}
      />
    </MainLayout>
  );
};

export default MyAppointments;
