import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { PostService } from "../../services";

const VideoModal = ({ uuid, id }) => {
  useEffect(() => {
    scormFinish();
  }, []);

  const { mutateAsync, isLoading: isFinishing } = useMutation(
    PostService.finishScorm
  );

  const scormFinish = async () => {
    const { data } = await mutateAsync(id);
    if (data.success === false) {
      return;
    }
    if (data.success) {
      return;
    }
  };

  return (
    <Box p="2">
      <video allow="fullscreen" controls width="100%" controlsList="nodownload">
        <source src={uuid} type="video/mp4" />
      </video>
    </Box>
  );
};

export default VideoModal;
