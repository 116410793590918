/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Image, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { EnjoyMenu, NavItemKids } from "..";
import images from "../../constants/images";
//import { UserAvatarCard, ProfileMenu } from "../../../components";
import useMainStore from "../../../hooks/useMainStore";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../../constants/routes";
//import { avatars } from "../../../constants/data";

const MainHeader = () => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      as="nav"
      backgroundImage={images.header}
      //backgroundPosition={"center"}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
    >
      <Box
        display={"flex"}
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        maxWidth={{ base: "95%", sm: "85%", md: "85%", lg: "90%" }}
        marginX={"auto"}
        minH={"135px"}
        alignItems={"center"}
        justifyContent={"center"}
        pt="3"
      >
        <Box>
          <Image
            src={images.kidsLogo}
            alt="logo"
            h={[14, 95]}
            w={[102, 195]}
            mt={{ base: 2, md: 0 }}
            cursor="pointer"
            onClick={() => navigate(routes.kidsHome.path)}
          />
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={{
            base: "1fr 1fr 1fr 1fr",
            sm: "1fr 1fr 1fr 1fr",
            md: "1fr 1fr 1fr 1fr",
            lg: "1fr 1fr 1fr 1fr",
          }}
          flexDirection={"row"}
          gap="5"
          ml={{ base: 0, md: 10 }}
          alignItems="flex-start"
          justifyContent="space-around"
        >
          <NavItemKids
            to={routes.playGames.path}
            isActive={location.pathname === routes.playGames.path}
            active={<Image src={images.playActive} alt="play"  w={{ base: 14, md: 24 }}/>}
            inactive={<Image src={images.play} alt="play"  w={{ base: 14, md: 24 }}/>}
          />
          <NavItem image={images.enjoy} menu={<EnjoyMenu />} />
          <NavItemKids
            to={routes.read.path}
            isActive={location.pathname === routes.read.path}
            active={<Image src={images.readActive} alt="read"  w={{ base: 14, md: 24 }}/>}
            inactive={<Image src={images.read} alt="read"  w={{ base: 14, md: 24 }}/>}
          />
          <NavItemKids
            to={routes.learn.path}
            isActive={location.pathname === routes.learn.path}
            active={<Image src={images.learnActive} alt="learn"  w={{ base: 14, md: 24 }}/>}
            inactive={<Image src={images.learn} alt="learn"  w={{ base: 14, md: 24 }}/>}
            text="learn"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MainHeader;

const NavItem = ({ image, menu }) => {
  const location = useLocation();
  return (
    <Menu isLazy>
      <MenuButton>
        <NavItemKids
          //to={routes.read.path}
          isActive={
            location.pathname === routes.enjoyAnimations.path ||
            location.pathname === routes.enjoyAudioBooks.path ||
            location.pathname === routes.enjoyCartoons.path ||
            location.pathname === routes.enjoySongs.path
          }
          active={<Image src={images.enjoyActive} alt="enjoy"  w={{ base: 14, md: 24 }}/>}
          inactive={<Image src={image} alt="enjoy" w={{ base: 14, md: 24 }} />}
        />
      </MenuButton>
      <MenuList
        border="none"
        h={"0"}
        maxW="20"
        shadow={"none"}
        borderRadius={8}
        mt="-4"
      >
        {menu}
      </MenuList>
    </Menu>
  );
};
