import API from "../api";
import endpoints from "../api/endpoints";

const login = async ({ email, password }) => {
  const { data } = await API.post(endpoints.login, { email, password });
  if (data.data.token) return [data?.data, null];
  return [null, data];
};

const register = async ({
  first_name,
  last_name,
  nationality,
  email,
  password,
  c_password,
  phone,
}) => {
  const { data } = await API.post(endpoints.register, {
    first_name,
    last_name,
    nationality,
    email,
    phone,
    password,
    c_password,
  });
  return [data, null];
};

const getMe = async () => {
  const { data } = await API.get(endpoints.getMe);
  return [data?.data, null];
};

const ssoCallBack = async (token) => {
  return await API.post(endpoints.ssoCallBack, { token: token })
    .then((response) => {
      const data = response.data;
      return [data?.data, null];
    })
    .catch((error) => {
      return [null, error];
    });
};

const edevletRedirect = async () => {
  return await API.post(endpoints.edevletRedirect)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const edevletCallBack = async ({ token, code }) => {
  return await API.post(endpoints.edevletCallBack(token, code))
    .then((response) => {
      const data = response.data;
      return [data?.data, null];
    })
    .catch((error) => {
      return [null, error];
    });
};

export const getToken = () => localStorage.getItem("token");

export const setToken = (token) => localStorage.setItem("token", token);

export const getLevel = () => localStorage.getItem("level");

export const setLevel = (level) => localStorage.setItem("level", level);

export const getCookie = () => localStorage.getItem("cookie");

export const setCookie = (cookie) => localStorage.setItem("cookie", cookie);

export const setAuthorizationToken = (token, level, type) => {
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  API.defaults.headers.common["X-USER-LEVEL"] = level;
  API.defaults.headers.common["X-PLATFORM-TYPE"] = type;
  API.defaults.headers.common["Content-Type"] = "application/json";
  API.defaults.headers.common["Accept"] = "*/*";
  API.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
};

const AuthService = {
  login,
  register,
  getToken,
  setToken,
  setAuthorizationToken,
  getLevel,
  setLevel,
  getMe,
  ssoCallBack,
  getCookie,
  setCookie,
  edevletCallBack,
  edevletRedirect,
};

export default AuthService;
