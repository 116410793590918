const images = {
  logo: require("../assets/images/logo@3x.png"),
  eba_logo: require("../assets/images/EBA_logo.png"),
  getEbaPass: require("../assets/images/getEbaPass.png"),
  meb: require("../assets/images/meb@3x.png"),
  avatar: require("../assets/images/avatar@3x.png"),
  practice: require("../assets/images/practice@3x.png"),
  text: require("../assets/images/text@3x.png"),
  video: require("../assets/images/video@3x.png"),
  topicSidebar: require("../assets/images/topicSidebar@3x.png"),
  snacksSidebar: require("../assets/images/snacksSidebar@3x.png"),
  talkToDioSidebar: require("../assets/images/talkToDioSidebar@3x.png"),
  practiceSidebar: require("../assets/images/practiceSidebar@3x.png"),
  videoIcon: require("../assets/images/videoIcon@3x.png"),
  recentNews: require("../assets/images/recentNews@3x.png"),
  videoNews: require("../assets/images/videoNews@3x.png"),
  practiceOnlineCourses: require("../assets/images/practiceOnlineCourses@3x.png"),
  practiceOnlineSpeaking: require("../assets/images/practiceOnlineSpeaking@3x.png"),
  practiceOnlineSpeaking2: require("../assets/images/practiceOnlineSpeaking2@3x.png"),
  practiceWriting: require("../assets/images/practiceWriting@3x.png"),
  practiceWriting2: require("../assets/images/practiceWriting2@3x.png"),
  lock: require("../assets/images/lock.png"),
  change: require("../assets/images/change.png"),
  diyalektlogo: require("../assets/images/diyalektlogo@3x.png"),
  kidslogochose: require("../assets/images/kidslogochose@3x.png"),
  textPlus: require("../assets/images/textPlus.png"),
  textMinus: require("../assets/images/textMinus.png"),
  cancel: require("../assets/images/cancel.png"),
  instruction: require("../assets/images/instruction.png"),
  googlePlay: require("../assets/images/googlePlay.png"),
  appStore: require("../assets/images/appStore.png"),
  rookie: require("../assets/images/Rookie@3x.png"),
  freshman: require("../assets/images/Freshman@3x.png"),
  junior: require("../assets/images/Junior@3x.png"),
  senior: require("../assets/images/Senior@3x.png"),
  expert: require("../assets/images/Expert@3x.png"),
  professional: require("../assets/images/Professional@3x.png"),
  king: require("../assets/images/King@3x.png"),
  forgotBackground: require("../assets/images/forgotBackground.png"),
  forgotNumOne: require("../assets/images/forgotNumOne.png"),
  forgotNumTwo: require("../assets/images/forgotNumTwo.png"),
  forgotOk: require("../assets/images/forgotOk.png"),
  eba: require("../assets/images/eba@3x.png"),
  forgotDone: require("../assets/images/forgotDone.png"),

  //Avatars
  avatar1: require("../assets/avatar/avatar1@3x.png"),
  avatar2: require("../assets/avatar/avatar2@3x.png"),
  avatar3: require("../assets/avatar/avatar3@3x.png"),
  avatar4: require("../assets/avatar/avatar4@3x.png"),
  avatar5: require("../assets/avatar/avatar5@3x.png"),
  avatar6: require("../assets/avatar/avatar6@3x.png"),
  avatar7: require("../assets/avatar/avatar7@3x.png"),
  avatar8: require("../assets/avatar/avatar8@3x.png"),
  avatar9: require("../assets/avatar/avatar9@3x.png"),
  avatar10: require("../assets/avatar/avatar10@3x.png"),
  plus: require("../assets/images/plus@3x.png"),
  minus: require("../assets/images/minus@3x.png"),
  on: require("../assets/images/on.png"),
  off: require("../assets/images/off.png"),

  //weekly target
  brightRed: require("../assets/images/brightRed@3x.png"),
  squash: require("../assets/images/squash@3x.png"),
  dangelion: require("../assets/images/dangelion@3x.png"),
  yellowishGreen: require("../assets/images/yellowishGreen@3x.png"),
  vividGreen: require("../assets/images/vividGreen@3x.png"),
  aqua: require("../assets/images/aqua@3x.png"),
  aquaBlue: require("../assets/images/aquaBlue@3x.png"),
  blue: require("../assets/images/blue@3x.png"),
  brightMagenta: require("../assets/images/brightMagenta@3x.png"),
  violet: require("../assets/images/violet@3x.png"),
  gray: require("../assets/images/gray@3x.png"),
  black: require("../assets/images/black@3x.png"),

  //categories
  vocabulary: require("../assets/images/vocabulary@3x.png"),
  grammar: require("../assets/images/grammar@3x.png"),
  reading: require("../assets/images/reading@3x.png"),
  listening: require("../assets/images/listening@3x.png"),
  writing: require("../assets/images/writing@3x.png"),
  speaking: require("../assets/images/speaking@3x.png"),

  //H5P
  agamotto: require("../assets/h5p/agamotto.png"),
  audioRecorder: require("../assets/h5p/audioRecorder.png"),
  chart: require("../assets/h5p/chart.png"),
  collage: require("../assets/h5p/collage.png"),
  column: require("../assets/h5p/column.png"),
  crossword: require("../assets/h5p/crossword.png"),
  dragAndDrop: require("../assets/h5p/dragAndDrop.png"),
  dragTheWords: require("../assets/h5p/dragTheWords.png"),
  dialogueCards: require("../assets/h5p/dialogueCards.png"),
  dictation: require("../assets/h5p/dictation.png"),
  essay: require("../assets/h5p/essay.png"),
  fillInTheBlanks: require("../assets/h5p/fillInTheBlanks.png"),
  findMultipleHotspots: require("../assets/h5p/findMultipleHotspots.png"),
  flashCards: require("../assets/h5p/flashCards.png"),
  guessTheAnswer: require("../assets/h5p/guessTheAnswer.png"),
  imageChoice: require("../assets/h5p/imageChoice.png"),
  imageSequencing: require("../assets/h5p/imageSequencing.png"),
  imagePairing: require("../assets/h5p/imagePairing.png"),
  imageSlider: require("../assets/h5p/imageSlider.png"),
  interactiveBook: require("../assets/h5p/interactiveBook.png"),
  interactiveVideo: require("../assets/h5p/interactiveVideo.png"),
  markTheWords: require("../assets/h5p/markTheWords.png"),
  memoryGame: require("../assets/h5p/memoryGame.png"),
  multipleChoice: require("../assets/h5p/multipleChoice.png"),
  personalityQuiz: require("../assets/h5p/personalityQuiz.png"),
  quiz: require("../assets/h5p/quiz.png"),
  questionnaire: require("../assets/h5p/questionnaire.png"),
  singleChoiceSet: require("../assets/h5p/singleChoiceSet.png"),
  sortTheParagraphs: require("../assets/h5p/sortTheParagraphs.png"),
  speakTheWords: require("../assets/h5p/speakTheWords.png"),
  speakTheWordsSet: require("../assets/h5p/speaktheWordsSet.png"),
  structureStrip: require("../assets/h5p/structureStrip.png"),
  summary: require("../assets/h5p/summary.png"),
  timeline: require("../assets/h5p/timeline.png"),
  trueFalseQuestion: require("../assets/h5p/trueFalseQuestion.png"),
  virtualTour: require("../assets/h5p/virtualTour.png"),
  complexFillInTheBlanks: require("../assets/h5p/complexFillInTheBlanks.png"),
  findTheWords: require("../assets/h5p/findTheWords.png"),
  nullH5P: require("../assets/h5p/null.png"),
  imageHotSpots : require("../assets/h5p/imageHotSpots.png"),

  // snacks
  snacksBooks: require("../assets/images/snacksBooks@3x.png"),
  snacksNews: require("../assets/images/snacksNews@3x.png"),
  snacksDiscover: require("../assets/images/snacksDiscover@3x.png"),

  // snacks book
  book1: require("../assets/images/book1@3x.png"),
  book2: require("../assets/images/book2@3x.png"),
  book3: require("../assets/images/book3@3x.png"),
  book4: require("../assets/images/book4@3x.png"),
  book5: require("../assets/images/book5@3x.png"),

  onlineCoursesRaportBackground: require("../assets/images/onlineCoursesRaportBackground@3x.png"),
  booksRaportBackground: require("../assets/images/booksRaportBackground@3x.png"),
  newsRaportBackground: require("../assets/images/newsRaportBackground@3x.png"),
  onlineSpeakingRaportBackground: require("../assets/images/onlineSpeakingRaportBackground@3x.png"),
  writingRaportBackground: require("../assets/images/writingRaportBackground@3x.png"),
  onlineCoursesRaport: require("../assets/images/onlineCoursesRaport@3x.png"),
  booksRaport: require("../assets/images/booksRaport@3x.png"),
  newsRaport: require("../assets/images/newsRaport@3x.png"),
  onlineSpeakingRaport: require("../assets/images/onlineSpeakingRaport@3x.png"),
  writingRaport: require("../assets/images/writingRaport@3x.png"),
};

export default images;
