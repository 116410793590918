/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Spinner } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useMainStore from "../hooks/useMainStore";
import { routes } from "../constants/routes";
import { AuthService } from "../services";

const SsoLogin = () => {
  const navigate = useNavigate();
  const { initData, setUser, setAuth } = useMainStore();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const query = params.get("token");

  const { mutateAsync, isLoading } = useMutation(AuthService.ssoCallBack);

  useEffect(() => {
    query && onLogin();
  }, [query]);

  const onLogin = async () => {
    try {
      const [data] = await mutateAsync(query);
      if (data.token) {
        setUser(data);
        setAuth(true);
        initData(data);
        AuthService.setToken(data.token);
        AuthService.setLevel(data.level);
        AuthService.setAuthorizationToken(data.token, data.level, 1);
        navigate(routes.root.path);
      } else {
        console.log("Kullanıcı Bulunamadı");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return <div>{isLoading && <Spinner />}</div>;
};

export default SsoLogin;
