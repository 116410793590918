/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, Title, Spinner } from "../../components";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { PostService } from "../../services";
import { InView } from "react-intersection-observer";

const Discover = () => {
  const navigate = useNavigate();
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [discoverData, setDiscoverData] = useState([]);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!inView) return;
    LoadMoreData();
  }, [inView]);

  const LoadMoreData = async () => {
    const [result, error] = await moreDiscover({ page, perPage });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setDiscoverData([...discoverData, ...resultData]);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: moreDiscover, isLoading: isMoreLoading } = useMutation(
    PostService.getDiscoverPage
  );

  const { mutateAsync: getContent, isLoading: isLoading } = useMutation(
    PostService.getDiscoverList
  );

  const getData = async () => {
    const [result, error] = await getContent(perPage);
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setDiscoverData(resultData);
    }
    if (error) console.log(error);
  };

  const onDetail = (id) =>
    navigate(routes.discoverDetails.path, { state: { id: id } });

  return (
    <MainLayout snacks={true}>
      <Title text={"Discover"} color={"title"} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          flex={1}
          display="grid"
          gridGap={2}
          gridTemplateColumns={{
            base: "1fr 1fr",
            sm: "1fr 1fr 1fr",
            md: "1fr 1fr 1fr",
            lg: "1fr 1fr 1fr",
          }}
          mb="10"
          //minH={"50vh"}
        >
          {discoverData.map((item, index) => {
            return (
              <Image
                key={index}
                onClick={() => onDetail(item?.id)}
                cursor="pointer"
                src={item?.image_url}
                alt="discover"
                borderRadius={5}
                w="31vh"
                h={"17vh"}
                objectFit="cover"
              />
            );
          })}
          {isMoreLoading && <Spinner />}
        </Box>
      )}
      <InView onChange={setInView}></InView>
    </MainLayout>
  );
};

export default Discover;
