import React, { useState, useEffect, useMemo } from "react";
import { Box, Text, Icon, Button } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  SemiTitle,
  AuthInput,
  DefaultButton,
  ShowAlert,
} from "../../components";
import moment from "moment";
import { Back, Next } from "../../components/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";
import useForm from "../../hooks/useForm";
import * as yup from "yup";
import { useMutation } from "react-query";
import { PostService } from "../../services";

const Appointment = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const title = params?.state?.title;
  const id = params?.state?.id;

  const [data, setData] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [showList, setShowList] = useState(false);
  const [refreshing, setRefreshing] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [focusData, setFocusData] = useState("");

  useEffect(() => {
    !id && navigate(routes.practice.path);
    isValid && onGetMeetingDates();
  }, [refreshing]);

  const { mutateAsync: getMeetingDates, isLoading: ismeeting } = useMutation(
    PostService.getMeetingDates
  );

  const { mutateAsync: setMeetingDates, isLoading: issetmeeting } = useMutation(
    PostService.SetMeeting
  );

  const onGetMeetingDates = async () => {
    if (!isValid) {
      setShowAlert(true);
      return;
    }
    const [result, error] = await getMeetingDates({
      content_subject_id: id,
      date: values.date,
    });
    const map = new Map(Object.entries(result));
    setShowList(true);
    setData(map);
  };

  const SetMeeting = async () => {
    const [data, error] = await setMeetingDates({
      content_subject_id: id,
      date: values.date,
      meeting_period_id: selectedDate,
    });
    if (data.success) navigate(routes.appointmentApprove.path);
  };

  const validation = yup.object({
    date: yup.string().required("Bu alan zorunludur"),
  });

  const { values, setValues, setValuesDirectly, errors, isValid } = useForm(
    {
      date: "",
    },
    validation
  );

  const getPreviousDate = () => {
    setRefreshing(refreshing - 1);
    const selectedDate = values.date;

    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);
    setValuesDirectly({ date: moment(previousDate).format("yyyy-MM-DD") });
  };

  const getNextDate = () => {
    setRefreshing(refreshing + 1);
    const selectedDate = values.date;

    const currentDayInMilli = new Date(selectedDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    setValuesDirectly({ date: moment(nextDate).format("yyyy-MM-DD") });
  };

  const setDataValue = (dataId, _focusData) => {
    setSelectedDate(dataId);
    setFocusData(_focusData);
  };

  return (
    <MainLayout practice={true}>
      <Title text="Practice" color="title" />
      <SemiTitle text={title} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minH="50vh"
      >
        <AuthInput
          placeholder={"Select Date"}
          id="date"
          name="date"
          onChange={setValues}
          value={moment(values.date).format("yyyy-MM-DD")}
          type="date"
          size="xs"
          w={{base: "50%", md:"33%"}}
          mt={"5"}
        />
        {!showList && (
          <DefaultButton
            backgroundColor={"title"}
            fontWeight={"semibold"}
            fontSize={10}
            label={"Search"}
            color={"white"}
            height={"25"}
            w={{base: "20%", md:"15%"}}
            onClick={() => onGetMeetingDates()}
          />
        )}
        {showList && (
          <Box display={"flex"} flexDirection="column" alignItems={"center"}>
            <Box display={"flex"} alignItems="center" gap="1">
              <Icon as={Back} onClick={() => getPreviousDate()} mr="1" />
              <Text fontSize={"10"} fontWeight="bold" color={"denim"}>
                {values.date}
              </Text>
              {/* <Text fontSize={"10"} fontWeight="bold" color={"denim"}>
                01.03.2023
              </Text> */}
              <Text fontSize={"10"} color={"denim"}>
                {moment(values.date).format("dddd")}
              </Text>
              <Icon as={Next} onClick={() => getNextDate()} ml="1" />
            </Box>
            <Box
              mt={3}
              flex={1}
              display="grid"
              gridTemplateColumns={{
                base: "1fr 1fr 1fr",
                sm: "1fr 1fr 1fr 1fr",
                md: "1fr 1fr 1fr 1fr 1fr",
                lg: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
              }}
              gap={{base: "2", md:"1"}}
              alignItems="center"
              justifyContent={"center"}
            >
              <DataItem
                date="08:00"
                value={data.get("08:00")}
                focusData={focusData}
                onClick={() => setDataValue(1, "08:00")}
              />
              <DataItem
                date="08:15"
                value={data.get("08:15")}
                focusData={focusData}
                onClick={() => setDataValue(2, "08:15")}
              />
              <DataItem
                date="08:30"
                value={data.get("08:30")}
                focusData={focusData}
                onClick={() => setDataValue(3, "08:30")}
              />
              <DataItem
                date="08:45"
                value={data.get("08:45")}
                focusData={focusData}
                onClick={() => setDataValue(4, "08:45")}
              />
              <DataItem
                date="09:00"
                value={data.get("09:00")}
                focusData={focusData}
                onClick={() => setDataValue(5, "09:00")}
              />
              <DataItem
                date="09:15"
                value={data.get("09:15")}
                focusData={focusData}
                onClick={() => setDataValue(6, "09:15")}
              />
              <DataItem
                date="09:30"
                value={data.get("09:30")}
                focusData={focusData}
                onClick={() => setDataValue(7, "09:30")}
              />
              <DataItem
                date="09:45"
                value={data.get("09:45")}
                focusData={focusData}
                onClick={() => setDataValue(8, "09:45")}
              />
              <DataItem
                date="10:00"
                value={data.get("10:00")}
                focusData={focusData}
                onClick={() => setDataValue(9, "10:00")}
              />
              <DataItem
                date="10:15"
                value={data.get("10:15")}
                focusData={focusData}
                onClick={() => setDataValue(10, "10:15")}
              />
              <DataItem
                date="10:30"
                value={data.get("10:30")}
                focusData={focusData}
                onClick={() => setDataValue(11, "10:30")}
              />
              <DataItem
                date="10:45"
                value={data.get("10:45")}
                focusData={focusData}
                onClick={() => setDataValue(12, "10:45")}
              />
              <DataItem
                date="11:00"
                value={data.get("11:00")}
                focusData={focusData}
                onClick={() => setDataValue(13, "11:00")}
              />
              <DataItem
                date="11:15"
                value={data.get("11:15")}
                focusData={focusData}
                onClick={() => setDataValue(14, "11:15")}
              />
              <DataItem
                date="11:30"
                value={data.get("11:30")}
                focusData={focusData}
                onClick={() => setDataValue(15, "11:30")}
              />
              <DataItem
                date="11:45"
                value={data.get("11:45")}
                focusData={focusData}
                onClick={() => setDataValue(16, "11:45")}
              />
              <DataItem
                date="13:30"
                value={data.get("13:30")}
                focusData={focusData}
                onClick={() => setDataValue(17, "13:30")}
              />
              <DataItem
                date="13:45"
                value={data.get("13:45")}
                focusData={focusData}
                onClick={() => setDataValue(18, "13:45")}
              />
              <DataItem
                date="14:00"
                value={data.get("14:00")}
                focusData={focusData}
                onClick={() => setDataValue(19, "14:00")}
              />
              <DataItem
                date="14:15"
                value={data.get("14:15")}
                focusData={focusData}
                onClick={() => setDataValue(20, "14:15")}
              />
              <DataItem
                date="14:30"
                value={data.get("14:30")}
                focusData={focusData}
                onClick={() => setDataValue(21, "14:30")}
              />
              <DataItem
                date="14:45"
                value={data.get("14:45")}
                focusData={focusData}
                onClick={() => setDataValue(22, "14:45")}
              />
              <DataItem
                date="15:00"
                value={data.get("15:00")}
                focusData={focusData}
                onClick={() => setDataValue(23, "15:00")}
              />
              <DataItem
                date="15:15"
                value={data.get("15:15")}
                focusData={focusData}
                onClick={() => setDataValue(24, "15:15")}
              />
              <DataItem
                date="15:30"
                value={data.get("15:30")}
                focusData={focusData}
                onClick={() => setDataValue(25, "15:30")}
              />
              <DataItem
                date="15:45"
                value={data.get("15:45")}
                focusData={focusData}
                onClick={() => setDataValue(26, "15:45")}
              />
              <DataItem
                date="16:00"
                value={data.get("16:00")}
                focusData={focusData}
                onClick={() => setDataValue(27, "16:00")}
              />
              <DataItem
                date="16:15"
                value={data.get("16:15")}
                focusData={focusData}
                onClick={() => setDataValue(28, "16:15")}
              />
              <DataItem
                date="16:30"
                value={data.get("16:30")}
                focusData={focusData}
                onClick={() => setDataValue(29, "16:30")}
              />
              <DataItem
                date="16:45"
                value={data.get("16:45")}
                focusData={focusData}
                onClick={() => setDataValue(30, "16:45")}
              />
            </Box>
            <DefaultButton
              backgroundColor={"title"}
              fontWeight={"bold"}
              fontSize={10}
              label={"Save The Date"}
              color={"white"}
              height={"30"}
              w={{ base: "60%", lg: "25%" }}
              onClick={() => SetMeeting()}
            />
          </Box>
        )}
      </Box>
      <ShowAlert
        data="Please Select The Date"
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default Appointment;

const DataItem = ({ onClick = () => {}, date, value, focusData }) => {
  return (
    <Button
      display={"flex"}
      backgroundColor={value && focusData === date ? "orange" : "#EAEAEA"}
      borderWidth={1}
      borderColor={"lightgray"}
      borderRadius={5}
      w={{base: "14vh", md:"7vh"}}
      h={{base: "5vh", md:"4vh"}}
      p={1}
      alignItems="center"
      justifyContent={"center"}
      disabled={!value}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
      }}
      onClick={onClick}
    >
      <Text fontSize={"10"} color={value ?"denim" : "pinkishGrey"} fontWeight={"medium"}>
        {date}
      </Text>
    </Button>
  );
};
