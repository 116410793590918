/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Avatar,
} from "@chakra-ui/react";

const UserAvatarCard = ({ name, surname, avatar, menu }) => {
  return (
    <Box
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      ml="2"
    >
      <Menu placement="bottom-end" isLazy>
        <MenuButton>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems="center"
            mb="0.5"
          >
            {/* <Box textAlign={"right"} mr={"6px"}>
              <Text fontWeight={"extrabold"} fontSize={11}>
                {name}
              </Text>
              <Text fontWeight={"normal"} fontSize={10}>
                {surname}
              </Text>
            </Box> */}
            <Avatar w={"10"} h="10" name={name} src={avatar} />
          </Box>
        </MenuButton>
        <MenuList
          borderColor="title"
          borderRadius={10}
          shadow="md"
          w={{ base: "90%", lg: "100%" }}
        >
          {menu}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserAvatarCard;
