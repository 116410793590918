import React, { useState, useEffect } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { ContextlessPlayer as Player } from "@escolalms/h5p-react";
import useMainStore from "../../hooks/useMainStore";

const LANG = "en";
const API_URL = "https://diyalektapp.eba.gov.tr";

const contentSettings = (uuid, token, lang = "en") => {
  let url = `${API_URL}/api/v1/h5p/content/${uuid}`;
  url = lang ? `${url}?lang=${lang}` : url;
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  });
};

const H5pModal = ({ uuid }) => {
  const {
    data: { user },
  } = useMainStore();

  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uuid && user.token) {
      setLoading(true);
      contentSettings(uuid, user.token, LANG)
        .then((res) => res.json())
        .then((data) => {
          setSettings(data.data);
          setLoading(false);
        });
    }
  }, [uuid, user.token]);

  return (
    <Box p={2} maxH={{ base: "80vh", md: "90vh" }} overflowY="scroll">
      {loading ? (
        <Spinner />
      ) : (
        <Player
          onXAPI={(e) => console.log("xAPI event", e)}
          state={settings}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default H5pModal;
