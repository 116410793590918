import React from "react";
import { Box } from "@chakra-ui/react";
import { Spinner as Loading } from "@chakra-ui/react";

const Spinner = ({ ...props }) => {
  return (
    <Box display={"flex"} justifyContent="center" my={"2"}>
      <Loading color="title" alignSelf={"center"} {...props} />
    </Box>
  );
};

export default Spinner;
