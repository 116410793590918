import React, { useMemo, useEffect, useState } from "react";
import {
  MainLayout,
  Title,
  PracticeTopicList,
  Spinner,
} from "../../components";
import { useQuery } from "react-query";
import { PostService, AuthService } from "../../services";
import { routes } from "../../constants/routes";
import useMainStore from "../../hooks/useMainStore";
import { useNavigate } from "react-router-dom";
import { topicA1, topicA2, topicB1, topicB2 } from "../../constants/data";

const OnlineSpeaking = () => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const [lottie, setLottie] = useState([]);
  const userLevel = AuthService.getLevel();

  useEffect(() => {
    user.level < 3 && navigate(routes.practice.path);
  }, []);

  const { data, isLoading } = useQuery("posts", PostService.init);

  const posts = useMemo(() => {
    return data && data.length > 0 ? data[0]?.content_subjects : [] || [];
  }, [data]);

  useEffect(() => {
    setUserLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserLevel = () => {
    switch (parseInt(userLevel)) {
      case 1:
        //setLevel("A1");
        setLottie(topicA1);
        break;
      case 2:
        //setLevel("A2");
        setLottie(topicA2);
        break;
      case 3:
        // setLevel("B1");
        setLottie(topicB1);
        break;
      case 4:
        //setLevel("B2");
        setLottie(topicB2);
        break;
      default:
        return;
    }
  };

  return (
    <MainLayout practice={true}>
      <Title text={"Practice"} color="title" />
      {isLoading ? (
        <Spinner />
      ) : (
        <PracticeTopicList
          data={posts}
          routes={routes.appointment.path}
          lottie={lottie}
        />
      )}
    </MainLayout>
  );
};

export default OnlineSpeaking;
