import React, { useMemo } from "react";
import { Text, Grid, Box, Image, Divider } from "@chakra-ui/react";
import { Spinner } from "..";
import images from "../../constants/images";
import useMainStore from "../../hooks/useMainStore";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useQuery } from "react-query";
import { AuthService, PostService } from "../../services";

const LevelMenu = ({ dividerH = "125%", dividerW = "0.5" }) => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery("levels", PostService.getLevelTab);

  const levels = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  const changeLevel = async (levelId) => {
    //updateUser({ level: levelId });
    AuthService.setLevel(levelId);
    AuthService.setAuthorizationToken(user.token, levelId, 1);
    navigate(routes.root.path);
  };

  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      display={"flex"}
      h={"16"}
      alignItems={"center"}
    >
      <GridItemMenu
        levelName={"A"}
        level={"1"}
        progress={
          levels?.level === 1
            ? levels?.percentage
            : levels?.level > 1
            ? "100"
            : "0"
        }
        borderLeftRadius={10}
        isLock={false}
        onClick={levels?.level === 1 ? null : () => changeLevel(1)}
        isLoading={isLoading}
      />
      <Divider orientation="vertical" color={"title"} h={dividerH} />
      <GridItemMenu
        levelName={"A"}
        level={"2"}
        progress={
          levels?.level === 2
            ? levels?.percentage
            : levels?.level > 2
            ? "100"
            : "0"
        }
        isLock={levels?.level > 1 ? false : true}
        onClick={levels?.level < 2 ? null : () => changeLevel(2)}
        isLoading={isLoading}
      />
      <Divider orientation="vertical" color={"title"} h={dividerH} />
      <GridItemMenu
        levelName={"B"}
        level={"1"}
        progress={
          levels?.level === 3
            ? levels?.percentage
            : levels?.level > 3
            ? "100"
            : "0"
        }
        isLock={levels?.level > 2 ? false : true}
        onClick={levels?.level < 3 ? null : () => changeLevel(3)}
        isLoading={isLoading}
      />
      <Divider orientation="vertical" color={"title"} h={dividerH} />
      <GridItemMenu
        levelName={"B"}
        level={"2"}
        progress={
          levels?.level === 4
            ? levels?.percentage
            : levels?.level > 4
            ? "100"
            : "0"
        }
        borderRightRadius={10}
        isLock={levels?.level > 3 ? false : true}
        onClick={levels?.level < 4 ? null : () => changeLevel(4)}
        isLoading={isLoading}
      />
    </Grid>
  );
};

export default LevelMenu;

const GridItemMenu = ({
  levelName,
  level,
  progress,
  textColor = "title",
  borderLeftRadius,
  borderRightRadius,
  isLock,
  onClick = () => {},
  isLoading,
}) => {
  return (
    <Box flex={1} onClick={isLoading ? null : onClick} cursor="pointer">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box display={"flex"} justifyContent={"center"}>
            <Box
              borderRadius={100}
              h={"14"}
              w={"14"}
              backgroundColor={progress === "100" && "white"}
              shadow={progress === "100" && "md"}
              display="flex"
              flexDirection={"column"}
              justifyContent="center"
              alignItems={"center"}
              pos="absolute"
              mt="3"
            >
              <Box
                display={"flex"}
                flexDirection="row"
                justifyContent={"center"}
                alignContent="center"
              >
                <Text color={textColor} fontSize={"24"} fontWeight="black">
                  {levelName}
                </Text>
                <Text
                  color={textColor}
                  fontSize={"10"}
                  fontWeight="extrabold"
                  alignSelf="flex-end"
                  mb={1}
                >
                  {level}
                </Text>
              </Box>
              <Text color={textColor} fontSize={"12"} mt="-2">
                %{progress}
              </Text>
              {isLock && (
                <Image
                  src={images.lock}
                  alt="lockicon"
                  w={"3"}
                  position="absolute"
                  ml={14}
                  mt={14}
                />
              )}
            </Box>
          </Box>
          <Box
            w={`${progress}%`}
            h={20}
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems={"center"}
            backgroundColor={"paleTeal"}
            borderLeftRadius={borderLeftRadius}
            borderRightRadius={progress === "100" && borderRightRadius}
          />
        </>
      )}
    </Box>
  );
};
