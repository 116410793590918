import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { navigation, routes } from "./constants/routes";
import { Fallback, withSplashScreen } from "./components";
import useMainStore from "./hooks/useMainStore";
import { googleAnalyticsTrackerId } from "./constants";
import ReactGA from "react-ga";

import "@fontsource/montserrat";

ReactGA.initialize(googleAnalyticsTrackerId);

function App() {
  const {
    data: { authenticated },
  } = useMainStore();

  return (
    <React.Suspense fallback={<Fallback />}>
      <Routes>
        {navigation.map((route, index) => {
          let redirect = false;

          if (route.redirect) {
            redirect = route.redirectPath;
          }

          if (route.isSecured && !authenticated) {
            redirect = routes.login.path;
          }

          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                redirect ? <Navigate to={redirect} /> : <route.component />
              }
              exact={route.isExact}
            />
          );
        })}
      </Routes>
    </React.Suspense>
  );
}

export default withSplashScreen(App);
