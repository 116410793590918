import React, { useMemo, useState } from "react";
import { Box, Text, Divider, useDisclosure } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  DefaultButton,
  Modals,
  ShowWriting,
} from "../../components";
import { useQuery } from "react-query";
import { PostService } from "../../services";
import "@fontsource/montserrat/700.css"

const MyPosts = () => {
  const showWriting = useDisclosure();
  const [id, setId] = useState();
  const { data, isLoading } = useQuery("writings", PostService.getMyWriting);

  const writings = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  const openModal = (id) => {
    setId(id);
    showWriting.onOpen();
  };

  return (
    <MainLayout>
      <Title text={"My Posts"} color="title" />
      <Box px={20} mr="2" ml={1} minH={"60vh"} mb="4">
        {writings.map((item, index) => {
          return (
            <Box key={index}>
              <Box
                display={"flex"}
                fontSize={"14"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Text color={"dustyOrange"} fontWeight="900"  >
                  {item?.subject_name}
                </Text>
                <DefaultButton
                  onClick={item?.is_revised ? () => openModal(item?.id) : null}
                  backgroundColor={item?.is_revised ? "title" : "pinkishGrey"}
                  fontWeight={"semibold"}
                  fontSize={8}
                  label={item?.is_revised ? "Feedback" : "Waiting"}
                  color={"white"}
                  height={"6"}
                  w={"15%"}
                />
              </Box>
              <Divider color={"pinkishGrey"} />
            </Box>
          );
        })}
      </Box>
      <Modals onClose={showWriting.onClose} isOpen={showWriting.isOpen}>
        <ShowWriting id={id} />
      </Modals>
    </MainLayout>
  );
};

export default MyPosts;
