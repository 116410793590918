/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Button, Image, Text, Link } from "@chakra-ui/react";
import images from "../constants/images";
import { AuthLayout, DefaultButton, ShowAlert } from "../components";
import { routes } from "../constants/routes";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ebaLoginUrl, termsUrl, privacyUrl } from "../constants";
import { AuthService } from "../services";

const Login = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState("");

  const { mutateAsync, isLoading } = useMutation(AuthService.edevletRedirect);

  const edevletRedirectUrl = async () => {
    const data = await mutateAsync();
    if (data.success) {
      window.open(data?.data.redirect_url, "_self");
    }
    if (data.success === false) {
      setAlertData(data.message);
      setShowAlert(true);
    }
  };

  return (
    <AuthLayout>
      <Text
        textAlign={"center"}
        mt={"20"}
        color={"title"}
        fontWeight={"bold"}
        fontSize={20}
      >
        Login With
      </Text>
      <Link
        _hover={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        display={"flex"}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        marginTop="5"
        href={ebaLoginUrl}
        cursor="pointer"
      >
        <Image src={images.eba_logo} alt="logo" w={"90%"} />
      </Link>
      <Box
        display={"flex"}
        justifyContent={"center"}
        fontSize={10}
        mt="1"
        gap={2}
      >
        <Link
          _hover={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          onClick={() => navigate(routes.getEbaPass.path)}
        >
          <Text color={"title"} fontWeight="bold">
            I forgot my password{" "}
          </Text>
        </Link>
        <Link
          _hover={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          onClick={() => navigate(routes.createEbaAccount.path)}
        >
          <Text color={"red"} fontWeight="bold">
            Create Eba Account
          </Text>
        </Link>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        alignSelf="center"
        justifyContent={"center"}
        alignContent="center"
      >
        <DefaultButton
          borderRadius={"8"}
          backgroundColor={"white"}
          fontWeight={"semibold"}
          fontSize={11}
          label={"Login with e-Devlet"}
          color={"edevlet"}
          height={{ base: "50px", md: "40px" }}
          borderColor={"black"}
          borderWidth={{ base: 0.5, xl: 0.8 }}
          w={"90%"}
          disable={isLoading}
          onClick={() => edevletRedirectUrl()}
        />
        {/* <Button
          backgroundColor={"#d2d2d2"}
          borderRadius={"8"}
          height={"40px"}
          mt="2"
          w={"90%"}
        >
          <Link
            _hover={{ textDecoration: "none" }}
            color="white"
            fontWeight={"bold"}
            fontSize={12}
            onClick={() => navigate(routes.register.path)}
          >
            Türkçe Öğrenmek İstiyorum
          </Link>
        </Button> */}
      </Box>

      <Text
        fontSize={9.5}
        color={"pinkishGrey"}
        textAlign={"center"}
        mt="20"
        mb={10}
      >
        By logging into Diyalekt, you agree to our
        <Link fontWeight={"bold"} href={termsUrl}>
          {" "}
          Terms
        </Link>{" "}
        and
        <Link fontWeight={"bold"} href={privacyUrl}>
          {" "}
          Privacy Policy.{" "}
        </Link>
      </Text>
      <ShowAlert
        data={alertData}
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </AuthLayout>
  );
};

export default Login;
