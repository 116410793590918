import * as React from "react";

function SvgComponent({ size = 15, ...props }) {
  return (
    <svg
      data-name="Component 403 – 4"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fontSize={size}
      {...props}
    >
      <circle
        data-name="Ellipse 1062"
        cx="10"
        cy="10"
        r="10"
        transform="rotate(180 10 10)"
        fill="#f47920"
      />
      <path
        data-name="Path 32913"
        d="m12.952 1115-6.116-5.771-.807.763 5.308 5.008-5.307 5 .807.762 6.116-5.77z"
        transform="rotate(180 9.745 562.497)"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
