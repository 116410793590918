import React from "react";
import { Box } from "@chakra-ui/react";
import { MainHeader } from "..";
import { KidsFooter } from "..";

const MainLayout = ({ children }) => {
  return (
    <Box minH={"100vh"} marginX="auto" backgroundColor={"white"}>
      <MainHeader />
      {children}
      <KidsFooter />
    </Box>
  );
};

export default MainLayout;
