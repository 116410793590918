import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, Title } from "../components";
import images from "../constants/images";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";

const Snacks = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <Title text="Snacks" color={"title"} />
      <Box
        flex={1}
        display="grid"
        gridGap={2}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        minH={{sm: "30vh", md:"55vh"}}
        mb="5"
      >
        <Image
          src={images.snacksBooks}
          alt="snacksBooks"
          onClick={() => navigate(routes.books.path)}
          cursor={"pointer"}
        />
        <Image
          src={images.snacksNews}
          alt="snacksNews"
          onClick={() => navigate(routes.news.path)}
          cursor={"pointer"}
        />
        <Image
          src={images.snacksDiscover}
          alt="snacksDiscover"
          onClick={() => navigate(routes.discover.path)}
          cursor={"pointer"}
        />
      </Box>
    </MainLayout>
  );
};

export default Snacks;
