import React, { useState } from "react";
import { Text, Image, Box } from "@chakra-ui/react";
import { ForgotPassLayout, DefaultButton } from "../components";
import images from "../constants/images";

const tabs = {
  myself: "myself",
  teacher: "teacher",
};

const CreateEbaAccount = () => {
  const [tab, setTab] = useState();
  return (
    <ForgotPassLayout>
      <Image src={images.eba} w="24" mt="16" />
      <Text
        fontFamily={"atma"}
        color="title"
        fontWeight={"bold"}
        fontSize={"32"}
        mt="3"
      >
        Şifremi Nasıl Alırım?
      </Text>
      <Box display={"flex"} gap="2" w={{ base: "90%", md: "60%", lg: "40%" }}>
        <DefaultButton
          fontFamily={"atma"}
          borderRadius={"8"}
          borderBottomLeftRadius={"20"}
          backgroundColor={tab === tabs.myself && "title"}
          fontWeight={"dark"}
          fontSize={{ base: 16, md: 20 }}
          label={"Kendin Al"}
          color={tab === tabs.myself ? "white" : "black"}
          height="38"
          borderColor={tab === tabs.myself ? "title" : "black"}
          borderWidth={{ base: 0.5, xl: 0.8 }}
          w="100%"
          mt="5"
          onClick={() => setTab(tabs.myself)}
        />
        <DefaultButton
          fontFamily={"atma"}
          borderRadius={"8"}
          borderBottomRightRadius={"20"}
          backgroundColor={tab === tabs.teacher && "title"}
          fontWeight={"dark"}
          fontSize={{ base: 16, md: 20 }}
          label={"Veli/Öğretmen Yardımıyla Al"}
          color={tab === tabs.teacher ? "white" : "black"}
          height={"38"}
          borderColor={tab === tabs.teacher ? "title" : "black"}
          borderWidth={{ base: 0.5, xl: 0.8 }}
          w="100%"
          mt="5"
          onClick={() => setTab(tabs.teacher)}
        />
      </Box>
      {tab === tabs.myself && (
        <CreateEba
          title="Okul bilgilerin ile EBA'ya gir."
          text="Şifreni belirle ve giriş yap."
        />
      )}
      {tab === tabs.teacher && (
        <CreateEba
          title="Velin/Öğretmenin e-Devlet veya MEBBİS bilgileri ile EBA'ya girer  ve senin için bir şifre belirler."
          text="Onlardan aldığın şifre ile EBA'ya giriş yap."
        />
      )}
    </ForgotPassLayout>
  );
};

export default CreateEbaAccount;

const CreateEba = ({ title, text }) => {
  return (
    <Box display={"flex"} flexDirection="column" alignItems={"center"}>
      <Image src={images.forgotNumOne} w="7" mt={"9"} />
      <Text color="title" fontFamily={"atma"} fontSize={"20"} mt="1">
        {title}
      </Text>
      <Image src={images.forgotOk} w="7" mr={"4"} />
      <Image src={images.forgotNumTwo} w="7" mt="3" />
      <Text color="title" fontFamily={"atma"} fontSize={"20"}>
        {text}
      </Text>
      <Image src={images.forgotDone} w="32" mt={"4"} mb="4" />
    </Box>
  );
};
