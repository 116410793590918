/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { MainLayout, Title, GoalCard, Spinner } from "../components";
import { HomeData } from "../constants/data";
import { useQuery } from "react-query";
import { PostService } from "../services";

const Home = () => {
  const { data, isLoading } = useQuery("weekly", PostService.getWeeklyGoalList);

  const weekly = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  return (
    <MainLayout>
      <Title text={"Level Goals"} color={"title"} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          flex={1}
          display="grid"
          gridGap={5}
          gridTemplateColumns={{
            base: "1fr",
            sm: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr 1fr",
          }}
          alignItems={"center"}
          mb={10}
          minH="55vh"
        >
          {weekly.map((item, index) => {
            return (
              <GoalCard
                key={index}
                text={item?.goal_name}
                color={HomeData[index]?.color}
                image={HomeData[index]?.image}
                progress={item?.goal_percentage}
                rate={item?.goal_rate}
              />
            );
          })}
        </Box>
      )}
    </MainLayout>
  );
};

export default Home;
