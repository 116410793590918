/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, Title, ShowAlert } from "../components";
import images from "../constants/images";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";
import { AuthService } from "../services";

const Practice = () => {
  const navigate = useNavigate();
  const level = AuthService.getLevel();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState();

  const showB1 = () => {
    setAlertData("Accesible at B1 level");
    setShowAlert(true);
  };

  return (
    <MainLayout>
      <Title text={"Practice"} color="title" />
      <Box
        flex={1}
        display="grid"
        gridGap={2}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr",
          lg: "1fr 1fr 1fr",
        }}
        minH={{ sm: "30vh", md: "55vh" }}
        mb="5"
        alignItems={"flex-start"}
      >
        <Image
          src={images.practiceOnlineCourses}
          alt="onlineCourses"
          onClick={() => navigate(routes.onlineCourses.path)}
          cursor={"pointer"}
        />
        {parseInt(level) > 2 ? (
          <Image
            src={images.practiceOnlineSpeaking2}
            alt="onlineSpeaking"
            onClick={() => navigate(routes.onlineSpeaking.path)}
            cursor={"pointer"}
          />
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent="center"
            cursor={"pointer"}
            onClick={() => showB1()}
          >
            <Image src={images.practiceOnlineSpeaking} alt="onlineSpeaking" />
            <Image
              src={images.lock}
              alt="lockicon"
              w={["8vh", "8vh", "5vh"]}
              position="absolute"
            />
          </Box>
        )}
        {parseInt(level) > 2 ? (
          <Image
            src={images.practiceWriting2}
            alt="writing"
            onClick={() => navigate(routes.practiceWriting.path)}
            cursor={"pointer"}
          />
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent="center"
            cursor={"pointer"}
            onClick={() => showB1()}
          >
            <Image src={images.practiceWriting} alt="writing" />
            <Image
              src={images.lock}
              alt="lockicon"
              w={["8vh", "8vh", "5vh"]}
              position="absolute"
            />
          </Box>
        )}
      </Box>
      <ShowAlert
        isShow={showAlert}
        data={alertData}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default Practice;
