import React from "react";
import { Box, Text, Image } from "@chakra-ui/react";

const FerformanceReportCard = ({ background, image, text, count }) => {
  return (
    <Box
      backgroundImage={background}
      backgroundPosition="center"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      h={"200"}
      w="100%"
      gap="5"
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      alignContent={"center"}
    >
      <Image src={image} w="54%" />
      <Box w="32%">
        <Text fontSize={"3xl"} fontWeight="900" color={"white"}>
          {count}
        </Text>
        <Text fontSize={"xl"} fontWeight="600" color={"white"}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default FerformanceReportCard;
