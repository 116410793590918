import React, { useState, useMemo, useEffect } from "react";
import { Image, Box } from "@chakra-ui/react";
import { MainLayout, Title, NewsList, Spinner } from "../../components";
import { useQuery, useMutation } from "react-query";
import { PostService } from "../../services";
import images from "../../constants/images";
import { InView } from "react-intersection-observer";

const tabs = {
  recent: "recent",
  video: "video",
};

const RecentNewsType = 1;
const VideoNewsType = 2;

const News = () => {
  const perPage = 20;
  const [tab, setTab] = useState(tabs.recent);
  const [pageVideo, setPageVideo] = useState(1);
  const [pageNews, setPageNews] = useState(1);
  const [recentNewsData, setRecentNewsData] = useState([]);
  const [videoNewsData, setVideoNewsData] = useState([]);
  const [inViewVideo, setInViewVideo] = useState(false);
  const [inViewRecent, setInViewRecent] = useState(false);

  useEffect(() => {
    getVideoData();
    getRecentData();
  }, []);

  useEffect(() => {
    if (!inViewVideo) return;
    LoadMoreVideo();
  }, [inViewVideo]);

  useEffect(() => {
    if (!inViewRecent) return;
    LoadMoreRecent();
  }, [inViewRecent]);

  const LoadMoreVideo = async () => {
    const [result, error] = await moreVideo({
      page: pageVideo,
      perPage: perPage,
      newsType: VideoNewsType,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPageVideo(pageVideo + 1);
      setVideoNewsData([...videoNewsData, ...resultData]);
    }
    if (error) console.log(error);
  };

  const LoadMoreRecent = async () => {
    const [result, error] = await moreRecent({
      page: pageNews,
      perPage: perPage,
      newsType: RecentNewsType,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPageNews(pageNews + 1);
      setRecentNewsData([...recentNewsData, ...resultData]);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: moreVideo, isLoading: isVideoMoreLoading } = useMutation(
    PostService.getNewsPage
  );

  const { mutateAsync: videoNews, isLoading: isVideoLoading } = useMutation(
    PostService.getNews
  );

  const { mutateAsync: moreRecent, isLoading: isRecentMoreLoading } =
    useMutation(PostService.getNewsPage);

  const { mutateAsync: recentNews, isLoading: isRecentLoading } = useMutation(
    PostService.getNews
  );

  const getVideoData = async () => {
    const [result, error] = await videoNews({
      perPage: perPage,
      newsType: VideoNewsType,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPageVideo(pageVideo + 1);
      setVideoNewsData(resultData);
    }
    if (error) console.log(error);
  };

  const getRecentData = async () => {
    const [result, error] = await recentNews({
      perPage: perPage,
      newsType: RecentNewsType,
    });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPageNews(pageNews + 1);
      setRecentNewsData(resultData);
    }
    if (error) console.log(error);
  };

  return (
    <MainLayout snacks={true}>
      <Title text={"News"} color={"title"} />
      <Box
        display={"flex"}
        gap={{ base: "1", md: "3", lg: "5" }}
        alignSelf={"center"}
      >
        <Image
          src={images.recentNews}
          alt="videoNews"
          w={"25vh"}
          onClick={() => setTab(tabs.recent)}
          cursor="pointer"
          opacity={tab === tabs.recent ? 1 : 0.5}
        />
        <Image
          src={images.videoNews}
          alt="recentNews"
          w={"25vh"}
          onClick={() => setTab(tabs.video)}
          cursor="pointer"
          opacity={tab === tabs.video ? 1 : 0.5}
        />
      </Box>
      {tab === tabs.recent && (
        <Box>
          {isRecentLoading ? (
            <Spinner />
          ) : (
            <NewsList
              data={recentNewsData}
              isRecentMoreLoading={isRecentMoreLoading}
            />
          )}
          <InView onChange={setInViewRecent}></InView>
        </Box>
      )}
      {tab === tabs.video && (
        <Box>
          {isVideoLoading ? (
            <Spinner />
          ) : (
            <NewsList
              data={videoNewsData}
              isVideoMoreLoading={isVideoMoreLoading}
            />
          )}
          <InView onChange={setInViewVideo}></InView>
        </Box>
      )}
    </MainLayout>
  );
};

export default News;
