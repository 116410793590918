import React from "react";
import PropTypes from "prop-types";
import MainContextProvider from "./MainContext";

const Provider = ({ children }) => {
  return <MainContextProvider>{children}</MainContextProvider>;
};

Provider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Provider;
