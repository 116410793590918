const lotties = {
  //Topic A1 gifs
  Greetings: require("../assets/lotties/a1/Greetings-high-five.json"),
  StopRacism: require("../assets/lotties/a1/Nationalities and Countries stop-racism.json"),
  PartyTime: require("../assets/lotties/a1/party-time.json"),
  Feeling: require("../assets/lotties/a1/feeling-loved-emoji.json"),
  Seasons: require("../assets/lotties/a1/Seasons and Weather rainy-thunder.json"),
  Penguin: require("../assets/lotties/a1/penguin.json"),
  RainyThunder: require("../assets/lotties/a1/Seasons and Weather rainy-thunder.json"),
  InflatableCastle: require("../assets/lotties/a1/At the Playground inflatable-castle.json"),
  HobbiesPaint: require("../assets/lotties/a1/Games and Hobbies paint.json"),
  HealthCare: require("../assets/lotties/a1/health-care.json"),
  RoadTrip: require("../assets/lotties/a1/Holiday road-trip.json"),
  Classroom: require("../assets/lotties/a1/in the classroom.json"),
  GainMuscle: require("../assets/lotties/a1/My Body gain-muscle.json"),
  WomanSelectingClothes: require("../assets/lotties/a1/My Clothes woman-selecting-clothes.json"),
  WriteNote: require("../assets/lotties/a1/Recap write-note.json"),
  ValentineDay: require("../assets/lotties/a1/My Day valentine-day.json"),
  PreparingFood: require("../assets/lotties/a1/My Food preparing-food.json"),
  FamilyGotNewHome: require("../assets/lotties/a1/My House family-got-new-home.json"),
  StoryTelling: require("../assets/lotties/a1/My Story  storytelling.json"),
  CityBuildingSkyline: require("../assets/lotties/a1/My Town My City city-building-skyline.json"),
  MyFriend: require("../assets/lotties/a1/my-friend.json"),
  Nature: require("../assets/lotties/a1/nature.json"),

  //Topic A2 gifs
  BiographiesPortfolio: require("../assets/lotties/a2/Biographies portfolio.json"),
  BoyPlayingOnTrumpet: require("../assets/lotties/a2/Entertainment boy-playing-on-trumpet.json"),
  WalkingWomanWithADog: require("../assets/lotties/a2/Habits & Routines walking-woman-with-a-dog.json"),
  PersonalityDisorder: require("../assets/lotties/a2/How do you feel  personality-disorder.json"),
  HeartbrokenFeeling: require("../assets/lotties/a2/How do you feel heartbroken-feeling.json"),
  KitchenPreparingFood: require("../assets/lotties/a2/In the Kitchen preparing-food.json"),
  TravelLocation: require("../assets/lotties/a2/Lets Travel travel-location.json"),
  Forest: require("../assets/lotties/a2/Natural Forces forest.json"),
  BusinessDresscode: require("../assets/lotties/a2/personality and appearence business-dresscode.json"),
  EcoLight: require("../assets/lotties/a2/saving the world eco-light.json"),
  WorkingProfessionalMale: require("../assets/lotties/a2/Work & Jobs working-professional-male.json"),

  //Topic B1 gifs
  MassMedia: require("../assets/lotties/b1/mass-media.json"),
  MusicClapper: require("../assets/lotties/b1/Films & Music clapper.json"),
  FutureJobs: require("../assets/lotties/b1/Future Jobs 1 trend-watcher.json"),
  Travel: require("../assets/lotties/b1/travel 1.json"),
  PickFreelancer: require("../assets/lotties/b1/Future Jobs 2 pick-freelancer.json"),
  Tech: require("../assets/lotties/b1/technology-icon.json"),
  EnvironmentPro: require("../assets/lotties/b1/environment-protection.json"),
  ValuesNorms: require("../assets/lotties/b1/Values-and-Norms-sun.json"),
  NewsReporting: require("../assets/lotties/b1/News, Lifestyles and Current Affairs news-reporting.json"),
  DreamsGoal: require("../assets/lotties/b1/Dreams goal.json"),
  FoodPlatter: require("../assets/lotties/b1/Food food-platter.json"),
  BasketBall: require("../assets/lotties/b1/Sports sports-basketball.json"),
  BackArrows: require("../assets/lotties/b1/Back to the Past back-arrows.json"),
  Learning: require("../assets/lotties/b1/Education learning.json"),
  Turkey: require("../assets/lotties/b1/My country Turkiye turkey.json"),
  GameConsole: require("../assets/lotties/b1/Leisure activities game-console.json"),

  //Topic B2 gifs
  BookLiterature: require("../assets/lotties/b2/Books & Literature girl-searching-for-information.json"),
  BusinessmanOnDeadline: require("../assets/lotties/b2/Hard Times stressful-businessman-on-deadline.json"),
  NewsStories: require("../assets/lotties/b2/News Stories looking-for-new-business.json"),
  ComingSoon: require("../assets/lotties/b2/Coming Soon coming-soon.json"),
  Technology: require("../assets/lotties/b2/Technology technology.json"),
  TravelAroundTheWorld: require("../assets/lotties/b2/Travel travel-around-the-world.json"),
  Friendship: require("../assets/lotties/b2/Friendship friendship.json"),
  WindEnergy: require("../assets/lotties/b2/Alternative Energy wind-energy.json"),
  Disapointment: require("../assets/lotties/b2/Wishes & Regrets disapointment.json"),
  OpenMind: require("../assets/lotties/b2/psychology open-mind.json"),
  WorldMap: require("../assets/lotties/b2/My country world-map.json"),
  Artist: require("../assets/lotties/b2/Art artist.json"),
  Photography: require("../assets/lotties/b2/Hobbies & Skills photography.json"),
  VictorianMan: require("../assets/lotties/b2/manners victorian-man.json"),
  Favorite: require("../assets/lotties/b2/Favors favorite.json"),
  Education: require("../assets/lotties/b2/education.json"),
  CivilRights: require("../assets/lotties/b2/human rights civil-rights.json"),

  //FlashCards
  rookie: require("../assets/lotties/flashcards/rookie-flag.json"),
  freshman: require("../assets/lotties/flashcards/freshman-badge.json"),
  junior: require("../assets/lotties/flashcards/junior-archery.json"),
  senior: require("../assets/lotties/flashcards/senior-star-badge.json"),
  expert: require("../assets/lotties/flashcards/expert-champion-trophy.json"),
  professional: require("../assets/lotties/flashcards/professional-winner.json"),
  king: require("../assets/lotties/flashcards/kings-crown.json"),
};

export default lotties;
