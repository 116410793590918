import API from "../api";
import endpoints from "../api/endpoints";

const updateUser = async (user) => {
  const { data } = await API.post(endpoints.updateUser, { ...user });
  return [data, null];
};

const changePassword = async ({
  old_password,
  new_password,
  re_new_password,
}) => {
  const { data } = await API.post(endpoints.changePassword, {
    old_password,
    new_password,
    re_new_password,
  });
  return [data, null];
};

const updateAvatar = async ({ image, avatarId }) => {
  if (avatarId && image) {
    const formData = new FormData();
    formData.append("avatarId", avatarId);
    formData.append("avatar", image);
    const { data } = await API.post(endpoints.updateAvatar, formData);
    return [data, null];
  } else if (avatarId && image === null) {
    const formData = new FormData();
    formData.append("avatarId", avatarId);
    const { data } = await API.post(endpoints.updateAvatar, formData);
    return [data, null];
  }
};

const updateAvatarLocal = async (avatarId) => {
  const formData = new FormData();
  formData.append("avatarId", avatarId);
  const { data } = await API.post(endpoints.updateAvatar, formData);
  return [data, null];
};

const deleteAccount = async (password) => {
  return await API.delete(endpoints.deleteAccount, { params: { password } })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const getPerformanceReport = async () => {
  return await API.get(endpoints.performanceReport)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const sendPerformanceMail = async () => {
  return await API.post(endpoints.sendPerformanceMail)
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

const UserService = {
  updateUser,
  changePassword,
  updateAvatar,
  updateAvatarLocal,
  deleteAccount,
  getPerformanceReport,
  sendPerformanceMail
};

export default UserService;
