import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fallback from "./Fallback";
import { routes } from "../constants/routes";
import { isSecuredRoute } from "../helpers";
import { AuthService } from "../services";
import { SET_AUTH, SET_USER } from "../store/actionTypes";
import useMainStore from "../hooks/useMainStore";

const withSplashScreen = (Component) => (props) => {
  const { dispatch, resetAuth, initData } = useMainStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = AuthService.getToken();
  const level = AuthService.getLevel();
  const { search: searchParams, pathname: currentRoute } = window.location;

  useEffect(() => {
    if (token) {
      initialize();
    } else {
      setLoading(false);
      const isSecured = isSecuredRoute(currentRoute);
      go(isSecured ? routes.login.path : currentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const go = (path, search = "") => {
    navigate(path + (search || searchParams));
  };

  const next = () => go(currentRoute, searchParams);

  const initialize = async () => {
    try {
      currentRoute.includes("kids")
        ? AuthService.setAuthorizationToken(token, level, 2)
        : AuthService.setAuthorizationToken(token, level, 1);

      const [data] = await AuthService.getMe();
      if (data) {
        dispatch({
          type: SET_AUTH,
          payload: true,
        });
        dispatch({
          type: SET_USER,
          payload: data,
        });
        initData(data);
        AuthService.setToken(token);
        AuthService.setLevel(level);
        currentRoute.includes("kids")
          ? AuthService.setAuthorizationToken(token, level, 2)
          : AuthService.setAuthorizationToken(token, level, 1);
        setLoading(false);
        next();
      } else {
        setLoading(false);
        resetAuth();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      resetAuth();
    }
  };

  if (loading) return <Fallback />;
  else return <Component {...props} />;
};

export default withSplashScreen;
