import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { MainLayout, Spinner, Title } from "../../components";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const Privacy = () => {
  const { data, isLoading } = useQuery("privacy", PostService.startUp);

  const privacy = useMemo(() => {
    return data && data.length > 0 ? data[0]?.static_pages : [] || [];
  }, [data]);

  return (
    <MainLayout>
      <Title text={"Privacy"} color="title" />
      <Box flex={1} mt={6} mb={6} ml={5}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box
            fontSize={14}
            dangerouslySetInnerHTML={{ __html: privacy.privacy_policy }}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default Privacy;
