import React from "react";
import { Input, Text, Box } from "@chakra-ui/react";

const PhoneNumberInput = ({ label, ...props }) => {
  return (
    <>
      {label && (
        <Text fontSize={10} fontWeight={"medium"} mb="-2" ml={2} mt="1.5">
          {label}
        </Text>
      )}
      <Input
        borderWidth={1}
        borderColor={"lightgray"}
        backgroundColor={"input"}
        my={"2"}
        color={"warmGray"}
        fontSize={8}
        h="30"
        placeholder={"0(5__)___ __"}
        {...props}
      />
    </>
  );
};

export default PhoneNumberInput;
