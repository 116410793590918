import React, { useState } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import {
  Modals,
  H5pModal,
  UrlPlayModal,
  ScormModal,
  VideoModal,
  Spinner,
} from "../../components";

const ContentList = ({ Data = [], isMoreLoading, ...props }) => {
  const h5pModal = useDisclosure();
  const urlModal = useDisclosure();
  const scormModal = useDisclosure();
  const videoModal = useDisclosure();

  const [value, setValue] = useState();
  const [id, setId] = useState();

  const openModal = (type, value, id) => {
    setValue(value);
    setId(id);
    if (type === 1) videoModal.onOpen();
    else if (type === 4) h5pModal.onOpen();
    else if (type === 5) scormModal.onOpen();
    else if (type === 6) urlModal.onOpen();
  };

  return (
    <Box>
      <Box
        flex={1}
        display="grid"
        gridGap={4}
        gridTemplateColumns={{
          base: "1fr 1fr",
          sm: "1fr 1fr 1fr 1fr",
          md: "1fr 1fr 1fr 1fr",
          lg: "1fr 1fr 1fr 1fr 1fr",
        }}
        minH="50vh"
        alignItems="center"
        justifyContent={"center"}
        alignContent="center"
        mb={10}
        mt={5}
        px={{ base: "8", md: "12", lg: "32" }}
        {...props}
      >
        {Data.map((item, index) => {
          return (
            <Image
              src={item?.image_url}
              key={index}
              cursor="pointer"
              onClick={() => openModal(item?.type, item?.value, item?.id)}
              maxW={"100%"}
              maxH={"100%"}
              mb="7"
              opacity={item?.is_completed ? 0.2 : 1}
            />
          );
        })}
        {isMoreLoading && <Spinner />}
      </Box>
      <Modals onClose={videoModal.onClose} isOpen={videoModal.isOpen}>
        <VideoModal uuid={value} id={id} />
      </Modals>
      <Modals onClose={h5pModal.onClose} isOpen={h5pModal.isOpen}>
        <H5pModal uuid={value} />
      </Modals>
      <Modals onClose={urlModal.onClose} isOpen={urlModal.isOpen}>
        <UrlPlayModal uuid={value} id={id} />
      </Modals>
      <Modals onClose={scormModal.onClose} isOpen={scormModal.isOpen}>
        <ScormModal uuid={value} id={id} />
      </Modals>
    </Box>
  );
};

export default ContentList;
