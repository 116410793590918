import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { Spinner } from ".";
import { useQuery } from "react-query";
import { PostService } from "../services";

const Cookie = () => {
  const { data, isLoading } = useQuery("cookie", PostService.startUp);

  const cookie = useMemo(() => {
    return data && data.length > 0 ? data[0]?.static_pages : [] || [];
  }, [data]);

  return (
    <Box h="60vh" overflow="scroll">
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          fontSize={14}
          dangerouslySetInnerHTML={{ __html: cookie.cookie_policy }}
        />
      )}
    </Box>
  );
};

export default Cookie;
