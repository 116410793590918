/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@chakra-ui/react";
import { MainHeader, Footer } from "..";

const AuthLayout = ({ children, ...props }) => {
  return (
    <Box
      as="main"
      marginX="auto"
      minH={{ base: "170vh", md: "130vh", lg: "100vh" }}
      {...props}
    >
      <MainHeader />
      <Box
        width={{ base: "90%", md: "30%", lg: "25%" }}
        mx="auto"
        minH={{ base: "50vh", xl: "60vh" }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default AuthLayout;
