/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { MainLayout, Title, Spinner } from "../../components";
import { BookData } from "../../constants/data";
import { useMutation } from "react-query";
import { PostService } from "../../services";
import { routes } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { InView } from "react-intersection-observer";

const Books = () => {
  const navigate = useNavigate();
  const perPage = 20;
  const [page, setPage] = useState(1);
  const [bookData, setBookData] = useState([]);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!inView) return;
    LoadMoreData();
  }, [inView]);

  const LoadMoreData = async () => {
    const [result, error] = await moreBooks({ page, perPage });
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setBookData([...bookData, ...resultData]);
    }
    if (error) console.log(error);
  };

  const { mutateAsync: moreBooks, isLoading: isMoreLoading } = useMutation(
    PostService.getMoreBooks
  );

  const { mutateAsync: getContent, isLoading: isLoading } = useMutation(
    PostService.getBooks
  );

  const getData = async () => {
    const [result, error] = await getContent(perPage);
    var resultData = result.data;
    if (resultData.length > 0) {
      setPage(page + 1);
      setBookData(resultData);
    }
    if (error) console.log(error);
  };

  const onBookDetails = (id) =>
    navigate(routes.bookDetails.path, { state: { id: id } });

  return (
    <MainLayout snacks={true}>
      <Title text={"Books"} color={"title"} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          flex={1}
          display="grid"
          gridGap={"5"}
          gridTemplateColumns={{
            base: "1fr",
            sm: "1fr 1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr",
          }}
          alignItems={"center"}
          px="1"
        >
          {bookData.map((item, index) => {
            return (
              <Box
                key={index}
                h={{ base: "95%", md: "95%", lg: "95%" }}
                w="100%"
                backgroundImage={BookData[item?.design_type - 1]?.book}
                backgroundPosition="center"
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                display="flex"
                justifyContent={"center"}
                onClick={() => onBookDetails(item?.id)}
                cursor="pointer"
                opacity={item?.is_completed ? 0.2 : 1}
              >
                <Image
                  src={item?.image_url}
                  alt="book image"
                  marginLeft={{ base: 12, md: 9 }}
                  mt={3}
                  h="85%"
                />
                <Box
                  marginLeft={{ base: 12, md: 9 }}
                  mt={{ base: 3, md: 4, lg: 5 }}
                  width={"40%"}
                >
                  <Text
                    color={BookData[item?.design_type - 1]?.color}
                    fontSize={"12"}
                    fontWeight="bold"
                  >
                    {item?.title}
                  </Text>
                  <Text
                    display="flex"
                    fontStyle={"italic"}
                    fontSize={"9"}
                    fontWeight="medium"
                  >
                    Genre
                    <Text
                      fontStyle={"normal"}
                      color={BookData[item?.design_type - 1]?.color}
                      marginLeft={0.5}
                      fontWeight="semibold"
                    >
                      {item?.genre}
                    </Text>
                  </Text>
                  <Text
                    display="flex"
                    fontStyle={"italic"}
                    fontSize={"9"}
                    fontWeight="medium"
                  >
                    Aouthor
                    <Text
                      fontStyle={"normal"}
                      color={BookData[item?.design_type - 1]?.color}
                      marginLeft={0.5}
                      fontWeight="semibold"
                    >
                      {item?.writer}
                    </Text>
                  </Text>
                  <Text
                    display="flex"
                    fontStyle={"italic"}
                    fontSize={"9"}
                    fontWeight="medium"
                  >
                    Total Words
                    <Text
                      fontStyle={"normal"}
                      color={BookData[item?.design_type - 1]?.color}
                      marginLeft={0.5}
                      fontWeight="semibold"
                    >
                      {item?.total_words}
                    </Text>
                  </Text>
                </Box>
              </Box>
            );
          })}
          {isMoreLoading && <Spinner />}
        </Box>
      )}
      <InView onChange={setInView}></InView>
    </MainLayout>
  );
};

export default Books;
