import React, { useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { PostService } from "../../services";

const ShowWriting = ({ id }) => {
  const { data, isLoading } = useQuery(["showWriting", id], () =>
    PostService.showWriting(id)
  );

  const showWriting = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  return (
    <Box display="flex" alignItems={"center"} mb="15">
      <Box h={"50vh"} mt={6} mb={6} ml={5} overflowY="scroll">
        <Text fontSize={14} fontWeight="bold" color={"orange"}>
          {showWriting.subject_name}
        </Text>
        <Box
          h={"47vh"}
          fontSize={9}
          dangerouslySetInnerHTML={{ __html: showWriting.edited_text }}
        />
      </Box>
    </Box>
  );
};

export default ShowWriting;
