import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { MainLayout, HomeCard } from "../components";
import images from "../constants/images";
import { routes } from "../../constants/routes";

const Home = () => {
  return (
    <MainLayout>
      <Image src={images.home} w="80%" mx={"auto"} />
      <Box mx={"auto"}>
        <HomeCard
          image={images.home2}
          flexDirection="row"
          title="Play"
          text="Engage with our interactive games 
            and flashcards to help cement 
            your understanding of key 
            English vocabulary, grammar, 
            and pronunciation."
          to={routes.playGames.path}
        />
        <HomeCard
          image={images.home3}
          flexDirection="row-reverse"
          title="Enjoy"
          text="Learn English while having fun 
          with our selection of songs, 
          audiobooks, cartoons 
          and animation videos. "
          to={routes.enjoyChose.path}
        />
        <HomeCard
          image={images.home4}
          flexDirection="row"
          title="Read"
          text="Explore the English language 
          with our selection of storybooks
          and shortstories. "
          to={routes.read.path}
        />
        <HomeCard
          image={images.home5}
          flexDirection="row-reverse"
          title="Learn"
          text="Watch and learn with our 
          collection of educational videos. 
          Learn about the English language in 
          an interactive and engaging way. "
          to={routes.learn.path}
        />
      </Box>
    </MainLayout>
  );
};

export default Home;
