import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
    mono: "Montserrat",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: {
    black: "#000",
    primary: "#297AF3",
    dark: "#2F2F2F",
    gray: "#515151",
    pinkishGrey: "#bcbcbc",
    white: "#fff",
    background: "#F9F9F9",
    backgroundLight: "#f0f0f0",
    light: "#F4F4F4",
    danger: "#F32929",
    darker: "#292D32",
    warning: "#F39C12",
    title: "#7d71ff",
    input: "#f4f7f0",
    orange: "#F47920",
    denim: "#3d4f7a",
    edevlet: "#d50000",
    border: "#707070",
    warmGray: "#9b9b9b",
    divider: "#e3e3e3",
    whiteTree: "#f8f8f8",
    brightRed: "#f1040c",
    squash: "#efb81b",
    dandelion: "#f9e712",
    sicklyYellow: "#d0e82d",
    yellowishGreen: "#b6e812",
    vividGreen: "#10e733",
    aqua: "#10d5c4",
    aquaBlue: "#0cd9e8",
    blue: "#074fed",
    brightMagenta: "#ef06ef",
    violet: "#8d0aea",
    blackTwo: "#292929",
    hotPurple: "#d30bf4",
    macaroniAndCheese: "#e7a928",
    kiwiGreen: "#73e937",
    dustyOrange: "#f26b37",
    paleTeal: "#9ed5c0",
    whiteTwo: "#e3e3e3",
    golden: "#f4bc03",
    electricBlue: "#0247ff",
    barney: "#cf1698",
    grass: "#66b131",
    redOrange: "#fe2712",
  },
});

export default theme;
