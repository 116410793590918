import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { PostService } from "../../services";

const UrlPlayModal = ({ uuid, id }) => {
  useEffect(() => {
    scormFinish();
  }, []);

  const { mutateAsync, isLoading: isFinishing } = useMutation(
    PostService.finishScorm
  );

  const scormFinish = async () => {
    const { data } = await mutateAsync(id);
    if (data.success === false) {
      return;
    }
    if (data.success) {
      return;
    }
  };

  return (
    <Box position={"relative"} w={"100%"} pb="75%">
      <iframe
        title="diyalekt"
        src={uuid}
        allowFullScreen={true}
        style={{
          overflow: "hidden",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      ></iframe>
    </Box>
  );
};

export default UrlPlayModal;
