import * as React from "react";

const SvgChartBar = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="21"
    fontSize={21}
    viewBox="0 0 21.858 16.116"
    {...props}
  >
    <path
      data-name="49d5d84333f8ad44600552df21f20ab4"
      d="M8.834 22.755v4.716H4V23.6a.843.843 0 0 1 .841-.841zm5.675-3.846v8.562H9.675V19.75a.843.843 0 0 1 .841-.841zm5.675-3.85v12.412h-4.835V15.9a.84.84 0 0 1 .841-.841zm5.674-2.859v15.271h-4.834V12.2a.84.84 0 0 1 .841-.841h3.153a.84.84 0 0 1 .84.841z"
      transform="translate(-4 -11.355)"
      fill="#9ed5c0"
    />
  </svg>
);

export default SvgChartBar;
