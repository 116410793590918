import React, { useEffect, useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { useQuery, useMutation } from "react-query";
import { KidsPostService, PostService } from "../../services";

const ScormModal = ({ uuid, id }) => {
  //const uuid = "6aa231b0-e245-408a-a8bc-02c9166d4b8e";

  const { data, isLoading } = useQuery(
    ["getScorm", uuid],
    () => KidsPostService.getScorm(uuid),
    {
      onSuccess: async () => {
        scormFinish();
      },
    }
  );

  const getScorm = useMemo(() => {
    return data && data.length > 0 ? data[0] : [] || [];
  }, [data]);

  const { mutateAsync, isLoading: isFinishing } = useMutation(
    PostService.finishScorm
  );

  const scormFinish = async () => {
    const { data } = await mutateAsync(id);
    if (data.success === false) {
      return;
    }
    if (data.success) {
      return;
    }
  };

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn.jsdelivr.net/npm/scorm-again@latest/dist/scorm-again.js";

    script.async = true;

    script.onload = () => ScromScrits();

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  const settings = getScorm;
  const token = settings?.token;
  const cmi = settings?.player?.cmi;

  const ScromScrits = () => {
    if (settings.version === "scorm_12") {
      scorm12();
    }

    function scorm12() {
      window.API = new window.Scorm12API(settings.player);
      window.API.loadFromJSON(cmi);

      window.API.on(
        "LMSSetValue.cmi.core.lesson_status",
        function (CMIElement, value) {
          const data = {
            cmi: {
              [CMIElement]: value,
            },
          };

          post(data);
        }
      );

      // window.API.on('LMSGetValue.cmi.*', function(CMIElement) {
      //     get(CMIElement)
      //         .then(res => res.json())
      //         .then(res => {
      //             window.API.LMSSetValue(CMIElement, res)
      //         })
      // });

      window.API.on("LMSCommit", function () {
        const data = {
          cmi: window.API.cmi,
        };

        post(data);
      });
    }

    function post(data) {
      if (!token) {
        return;
      }

      fetch(settings.lmsUrl + "/" + settings.uuid, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(data),
      });
    }
  };

  return (
    <Box position={"relative"} w={"100%"} pb="75%">
      <iframe
        title="diyalekt"
        src={getScorm.entry_url_absolute}
        allowFullScreen={true}
        style={{
          overflow: "hidden",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
        }}
      ></iframe>
    </Box>
  );
};

export default ScormModal;
