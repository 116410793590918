import React from "react";
import { Box } from "@chakra-ui/react";

const SectionsWrapper = (props) => {
  return (
    <Box
      display={"flex"}
      flex={1}
      maxWidth={{ base: "95%", sm: "85%", md: "85%", lg: "68%" }}
      flexDirection={{ base: "column", sm: "column", md: "column", lg: "row" }}
      p={{ base: "2", sm: "4", md: 0 }}
      justifyContent={"space-between"}
      mx={"auto"}
      {...props}
    />
  );
};

export default SectionsWrapper;
