import * as React from "react";

const SvgCheck = ({ ...props }) => (
  <svg
    data-name="27c3a2c8335880dbd751cab652e02050"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fontSize={21}
    viewBox="0 0 19.631 19.631"
    {...props}
  >
    <path
      data-name="Path 50"
      d="M23.631 13.816A9.816 9.816 0 1 1 13.816 4a9.816 9.816 0 0 1 9.815 9.816z"
      transform="translate(-4 -4)"
      fill={"#4caf50"}
    />
    <path
      data-name="Path 51"
      d="m23.4 14.6-6.676 6.673-2.749-2.747L12.6 19.9l4.122 4.124 8.048-8.05L23.4 14.6z"
      transform="translate(-8.38 -9.399)"
      fill={"#ccff90"}
    />
  </svg>
);

export default SvgCheck;
