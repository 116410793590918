/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Image, Box } from "@chakra-ui/react";
import { AuthLayout, ShowAlert, Cookie } from "../components";
import images from "../constants/images";
import { routes } from "../constants/routes";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services";
import useMainStore from "../hooks/useMainStore";

const Root = () => {
  const {
    data: { user },
  } = useMainStore();
  const navigate = useNavigate();
  const level = AuthService.getLevel();
  const [showAlert, setShowAlert] = useState(false);
  const cookie = AuthService.getCookie();

  useEffect(() => {
    cookie === "true" ? setShowAlert(false) : setShowAlert(true);
  }, []);

  const onCookieClick = () => {
    AuthService.setCookie(true);
    setShowAlert(false);
  };

  const onDiyalekt = () => {
    AuthService.setAuthorizationToken(user.token, level, 1);
    navigate(routes.topic.path);
  };

  const onDiyalektKids = () => {
    AuthService.setLevel(user.level);
    AuthService.setAuthorizationToken(user.token, user.level, 2);
    navigate(routes.kidsHome.path);
  };

  return (
    <AuthLayout>
      <Box
        minH={"70vh"}
        display="flex"
        flexDirection={{ base: "column", md: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Image
          src={images.diyalektlogo}
          alt="logo"
          onClick={() => onDiyalekt()}
          cursor="pointer"
          minH={"43vh"}
          minW={"43vh"}
        />
        <Image
          src={images.kidslogochose}
          alt="kids logo"
          onClick={() => onDiyalektKids()}
          cursor="pointer"
          minH={"43vh"}
          minW={"43vh"}
        />
      </Box>
      <ShowAlert
        data={<Cookie />}
        isShow={showAlert}
        isYes={true}
        color="orange"
        minW="130vh"
        onClick={() => onCookieClick()}
        onYesClick={() => onCookieClick()}
      />
    </AuthLayout>
  );
};

export default Root;
