/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import HowGetEbaPass from "../pages/HowGetEbaPass";
import Topic from "../pages/Topic";
import Categories from "../pages/topic/Categories";
import Grammar from "../pages/topic/Grammar";
import Vocabulary from "../pages/topic/Vocabulary";
import Reading from "../pages/topic/Reading";
import Listening from "../pages/topic/Listening";
import Writing from "../pages/topic/Writing";
import Speaking from "../pages/topic/Speaking";
import Snacks from "../pages/Snacks";
import Books from "../pages/snacks/Books";
import News from "../pages/snacks/News";
import Discover from "../pages/snacks/Discover";
import DiscoverDetails from "../pages/snacks/DiscoverDetails";
import TopicPractice from "../pages/TopicPractice";
import Practice from "../pages/Practice";
import OnlineCourses from "../pages/practice/OnlineCourses";
import OnlineSpeaking from "../pages/practice/OnlineSpeaking";
import Appointment from "../pages/practice/Appointment";
import AppointmentApprove from "../pages/practice/AppointmentApprove";
import PracticeWriting from "../pages/practice/PracticeWriting";
import WritingDetails from "../pages/practice/WritingDetails";
import ProfileInfo from "../pages/settings/ProfileInfo";
import PerformanceReport from "../pages/settings/PerformanceReport";
import MyAppointments from "../pages/settings/MyAppointments";
import H5PPlayer from "../pages/mobil/H5PPlayer";
import BookDetails from "../pages/snacks/BookDetails";
import MyPosts from "../pages/settings/MyPosts";
import Root from "../pages/Root";
import KidsHome from "../kids/pages/Home";
import SsoLogin from "../pages/SsoLogin";
import Terms from "../pages/policies/Terms";
import Privacy from "../pages/policies/Privacy";
import Play from "../kids/pages/Play";
import FlashCards from "../kids/pages/enjoy/FlashCards";
import Songs from "../kids/pages/enjoy/Songs";
import AudioBooks from "../kids/pages/enjoy/AudioBooks";
import Cartoons from "../kids/pages/enjoy/Cartoons";
import Animations from "../kids/pages/enjoy/Animations";
import Learn from "../kids/pages/Learn";
import Read from "../kids/pages/Read";
import WhatIs from "../pages/footer/WhatIs";
import GrammarVideo from "../pages/topic/GrammarVideo";
import EnjoyChoose from "../kids/pages/EnjoyChoose";
import Contributors from "../pages/settings/Contributors";
import UserGuide from "../pages/settings/UserGuide";
import CreateEbaAccount from "../pages/CreateEbaAccount";
import EdevletLogin from "../pages/EdevletLogin";

export const routes = {
  home: {
    path: "/level-goals",
    name: "Home",
    component: Home,
    isSecured: true,
    isExact: true,
  },
  root: {
    path: "/",
    name: "Root",
    component: Root,
    isSecured: true,
    isExact: true,
  },
  login: {
    path: "/login",
    name: "Login",
    component: Login,
    isSecured: false,
    isExact: true,
  },
  // register: {
  //   path: "/register",
  //   name: "Register",
  //   component: Register,
  //   isSecured: false,
  //   isExact: true,
  // },
  terms: {
    path: "/terms-and-conditions",
    name: "Terms",
    component: Terms,
    isSecured: false,
    isExact: true,
  },
  privacy: {
    path: "/privacy-policy",
    name: "Privacy",
    component: Privacy,
    isSecured: false,
    isExact: true,
  },
  getEbaPass: {
    path: "/forgot-password",
    name: "forgot-password",
    component: HowGetEbaPass,
    isSecured: false,
    isExact: true,
  },
  createEbaAccount: {
    path: "/create-eba-account",
    name: "create-eba-account",
    component: CreateEbaAccount,
    isSecured: false,
    isExact: true,
  },
  topic: {
    path: "/topic",
    name: "Topic",
    component: Topic,
    isSecured: true,
    isExact: true,
  },
  categories: {
    path: "/topic/categories",
    name: "Categories",
    component: Categories,
    isSecured: true,
    isExact: true,
  },
  grammar: {
    path: "/topic/categories/grammar",
    name: "Grammar",
    component: Grammar,
    isSecured: true,
    isExact: true,
  },
  vocabulary: {
    path: "/topic/categories/vocabulary",
    name: "Vocabulary",
    component: Vocabulary,
    isSecured: true,
    isExact: true,
  },
  topicPractice: {
    path: "/topic/categories/grammar/practice",
    name: "Topic Practice",
    component: TopicPractice,
    isSecured: true,
    isExact: true,
  },
  reading: {
    path: "/topic/categories/reading",
    name: "Reading",
    component: Reading,
    isSecured: true,
    isExact: true,
  },
  listening: {
    path: "/topic/categories/listening",
    name: "Listening",
    component: Listening,
    isSecured: true,
    isExact: true,
  },
  writing: {
    path: "/topic/categories/writing",
    name: "Writing",
    component: Writing,
    isSecured: true,
    isExact: true,
  },
  speaking: {
    path: "/topic/categories/speaking",
    name: "Writing",
    component: Speaking,
    isSecured: true,
    isExact: true,
  },
  grammarVideo: {
    path: "/topic/categories/grammar/video",
    name: "Grammar Video",
    component: GrammarVideo,
    isSecured: true,
    isExact: true,
  },
  snacks: {
    path: "/snacks",
    name: "Snacks",
    component: Snacks,
    isSecured: true,
    isExact: true,
  },
  books: {
    path: "/snacks/books",
    name: "Books",
    component: Books,
    isSecured: true,
    isExact: true,
  },
  bookDetails: {
    path: "/snacks/books/details",
    name: "Book Details",
    component: BookDetails,
    isSecured: true,
    isExact: true,
  },
  news: {
    path: "/snacks/news",
    name: "News",
    component: News,
    isSecured: true,
    isExact: true,
  },
  discover: {
    path: "/snacks/discover",
    name: "Discover",
    component: Discover,
    isSecured: true,
    isExact: true,
  },
  discoverDetails: {
    path: "/snacks/discover/details",
    name: "Discover Details",
    component: DiscoverDetails,
    isSecured: true,
    isExact: true,
  },
  practice: {
    path: "/practice",
    name: "Practice",
    component: Practice,
    isSecured: true,
    isExact: true,
  },
  onlineCourses: {
    path: "/practice/online-courses",
    name: "Online Courses",
    component: OnlineCourses,
    isSecured: true,
    isExact: true,
  },
  onlineSpeaking: {
    path: "/practice/online-speaking",
    name: "Online Speaking",
    component: OnlineSpeaking,
    isSecured: true,
    isExact: true,
  },
  appointment: {
    path: "/practice/online-speaking/appointment",
    name: "Appointment",
    component: Appointment,
    isSecured: true,
    isExact: true,
  },
  appointmentApprove: {
    path: "/practice/online-speaking/appointment/approve",
    name: "Appointment Aprove",
    component: AppointmentApprove,
    isSecured: true,
    isExact: true,
  },
  practiceWriting: {
    path: "/practice/writing",
    name: "Practice Writing",
    component: PracticeWriting,
    isSecured: true,
    isExact: true,
  },
  writingDetails: {
    path: "/practice/writing/details",
    name: "Practice Writing Details",
    component: WritingDetails,
    isSecured: true,
    isExact: true,
  },
  profileInfo: {
    path: "/user/profile-information",
    name: "Profile Information",
    component: ProfileInfo,
    isSecured: true,
    isExact: true,
  },
  performanceReport: {
    path: "/user/performance-report",
    name: "Performance Report",
    component: PerformanceReport,
    isSecured: true,
    isExact: true,
  },
  contributors: {
    path: "/contributors",
    name: "Contributors",
    component: Contributors,
    isSecured: false,
    isExact: true,
  },
  userGuide: {
    path: "/user-guide",
    name: "User Guide",
    component: UserGuide,
    isSecured: true,
    isExact: true,
  },
  myAppointments: {
    path: "/user/my-appointments",
    name: "My Appointments",
    component: MyAppointments,
    isSecured: true,
    isExact: true,
  },
  mobilH5pPlayer: {
    path: "/mobil/h5p/:uuid/:token",
    getPath: ({ uuid, token }) => `/mobil/h5p/${uuid}/${token}`,
    name: "Mobil H5p Player",
    component: H5PPlayer,
    isSecured: false,
    isExact: true,
  },
  myPosts: {
    path: "/user/my-posts",
    name: "My Posts",
    component: MyPosts,
    isSecured: true,
    isExact: true,
  },
  ssoLogin: {
    path: "/ebasso",
    name: "Ebasso",
    component: SsoLogin,
    getPath: (q) => `/ebasso?token=${q}`,
    isExact: true,
  },
  kidsHome: {
    path: "/kids/home",
    name: "Kids Home",
    component: KidsHome,
    isSecured: false,
    isExact: true,
  },
  playGames: {
    path: "/kids/play-games",
    name: "Play Games",
    component: Play,
    isSecured: true,
    isExact: true,
  },
  playFlashCards: {
    path: "/kids/enjoy-flashcards",
    name: "Play Flash Cards",
    component: FlashCards,
    isSecured: true,
    isExact: true,
  },
  enjoySongs: {
    path: "/kids/enjoy-songs",
    name: "Enjoy Songs",
    component: Songs,
    isSecured: true,
    isExact: true,
  },
  enjoyAudioBooks: {
    path: "/kids/enjoy-audio-books",
    name: "Audio Books",
    component: AudioBooks,
    isSecured: true,
    isExact: true,
  },
  enjoyCartoons: {
    path: "/kids/enjoy-cartoons",
    name: "Cartoons",
    component: Cartoons,
    isSecured: true,
    isExact: true,
  },
  enjoyAnimations: {
    path: "/kids/enjoy-animations",
    name: "Animations",
    component: Animations,
    isSecured: true,
    isExact: true,
  },
  read: {
    path: "/kids/read",
    name: "Read",
    component: Read,
    isSecured: true,
    isExact: true,
  },
  learn: {
    path: "/kids/learn",
    name: "Learn",
    component: Learn,
    isSecured: true,
    isExact: true,
  },
  whatIs: {
    path: "/what-is-diyalekt",
    name: "What Is Diyalekt",
    component: WhatIs,
    isSecured: false,
    isExact: true,
  },
  enjoyChose: {
    path: "/kids/enjoy-chose",
    name: "Chose",
    component: EnjoyChoose,
    isSecured: true,
    isExact: true,
  },
  edevletLogin: {
    path: "/e-devlet",
    name: "Edevelet Login",
    component: EdevletLogin,
    isSecured: false,
    isExact: true,
  },
};

export const navigation = Object.values(routes);
