import React from "react";
import {
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Eye, HideEye } from "../icons";

function PasswordInput({ inputGroupProps = {}, label, ...props }) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <>
      {label && (
        <Text fontSize={10} fontWeight={"medium"} mb="-2" ml={2} mt="1.5">
          {label}
        </Text>
      )}
      <InputGroup {...inputGroupProps} {...props} h="30" alignItems="center">
        <Input
          type={show ? "text" : "password"}
          placeholder="Password"
          borderWidth={1}
          borderColor={"lightgray"}
          backgroundColor={"input"}
          color={"warmGray"}
          fontSize={8}
          h="30"
          {...props}
        />
        <InputRightElement h="30">
          <IconButton
            onClick={handleClick}
            icon={show ? <Icon as={Eye} /> : <Icon as={HideEye} />}
          />
        </InputRightElement>
      </InputGroup>
    </>
  );
}

export default PasswordInput;
