import React, { useState } from "react";
import { Box, Text, Divider, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import images from "../../constants/images";
import useMainStore from "../../hooks/useMainStore";
import ShowAlert from "../ShowAlert";

const ProfileMenu = () => {
  const { resetAuth } = useMainStore();
  const navigate = useNavigate();
  const [on, setOn] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const UserData = [
    {
      key: 1,
      text: "Profile Information",
      onclick: () => navigate(routes.profileInfo.path),
    },
    {
      key: 2,
      text: "Performance Report",
      onclick: () => navigate(routes.performanceReport.path),
    },
    {
      key: 3,
      text: "Contributors",
      onclick: () => navigate(routes.contributors.path),
    },
    {
      key: 4,
      text: "My Appointments",
      onclick: () => navigate(routes.myAppointments.path),
    },
    {
      key: 5,
      text: "My Posts",
      onclick: () => navigate(routes.myPosts.path),
    },
    { key: 6, text: "Notifications", onclick: () => setOn(!on) },
    { key: 7, text: "Sign Out", onclick: () => onLogout() },
  ];

  const onLogout = async () => {
    setShowAlert(true);
  };

  return (
    <Box display={"flex"} flexDirection="column" textAlign={"center"} flex={1}>
      <Text color="title" fontWeight="black" fontSize="12" my={"1"} mb="1.5">
        User Settings
      </Text>
      {UserData.map((item, index) => {
        return (
          <Box
            key={index}
            display={"flex"}
            alignItems="center"
            flexDirection="column"
            justifyContent={"center"}
          >
            <Divider color={"pinkishGrey"} alignSelf={"center"} w={"80%"} />
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              my="0.5"
            >
              <Text
                fontSize={"12"}
                color={item?.key === 7 ? "red" : "denim"}
                fontWeight={item?.key === 7 ? "bold" : "600"}
                p={"0.5"}
                onClick={item?.onclick}
                cursor="pointer"
              >
                {item?.text}
              </Text>
              {item?.key === 6 && (
                <Box onClick={item?.onclick} w="20%" h="3">
                  {on ? (
                    <Image src={images.off} w="100%" h="3" />
                  ) : (
                    <Image src={images.on} w="100%" h="3" />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
      <ShowAlert
        data="Are you sure you want to log out?"
        isShow={showAlert}
        isYes={true}
        color="orange"
        onYesClick={() => resetAuth()}
      />
    </Box>
  );
};

export default ProfileMenu;
