import React from "react";

function SvgComponent({ ...props }) {
  return (
    <svg
      data-name="7fc4509da70a8e262ffcb537cbfa2b38"
      xmlns="http://www.w3.org/2000/svg"
      width="35.725"
      height="23.817"
      fontSize={25}
      viewBox="0 0 35.725 23.817"
      {...props}
    >
      <path
        data-name="Path 244"
        d="M0 85.333h35.724v23.816H0z"
        transform="translate(0 -85.332)"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 245"
        d="M20.095 85.33h-4.466v9.675H0v4.465h15.629v9.675h4.465v-9.674h15.63v-4.466H20.095z"
        transform="translate(0 -85.33)"
        fill="#d80027"
      />
      <path
        data-name="Path 246"
        d="m317.383 315.358 8.248 4.582v-4.582zm-5.731 0 13.979 7.766v-2.2l-10.026-5.57zm10.255 7.766-10.255-5.7v5.7z"
        transform="translate(-289.906 -299.309)"
        fill="#0052b4"
      />
      <path
        data-name="Path 247"
        d="m311.652 315.358 13.979 7.766v-2.2l-10.026-5.57z"
        transform="translate(-289.906 -299.309)"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 248"
        d="m311.652 315.358 13.979 7.766v-2.2l-10.026-5.57z"
        transform="translate(-289.906 -299.309)"
        fill="d80027"
      />
      <path
        data-name="Path 249"
        d="m6.3 315.356-6.3 3.5v-3.5zm7.676.988v6.779H1.779z"
        transform="translate(0 -299.307)"
        fill="#0052b4"
      />
      <path
        data-name="Path 250"
        d="M10.026 315.358 0 320.928v2.2l13.979-7.766z"
        transform="translate(0 -299.309)"
        fill="#d80027"
      />
      <path
        data-name="Path 251"
        d="M8.248 93.1 0 88.514V93.1zm5.731 0L0 85.33v2.2l10.026 5.57zM3.724 85.33l10.255 5.7v-5.7z"
        transform="translate(0 -85.33)"
        fill="#0052b4"
      />
      <path
        data-name="Path 252"
        d="M13.979 93.1 0 85.33v2.2l10.026 5.57z"
        transform="translate(0 -85.33)"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 253"
        d="M13.979 93.1 0 85.33v2.2l10.026 5.57z"
        transform="translate(0 -85.33)"
        fill="d80027"
      />
      <path
        data-name="Path 254"
        d="m319.328 93.1 6.3-3.5v3.5zm-7.676-.988v-6.781h12.2z"
        transform="translate(-289.906 -85.33)"
        fill="#0052b4"
      />
      <path
        data-name="Path 255"
        d="m315.605 93.1 10.026-5.57v-2.2l-13.979 7.77z"
        transform="translate(-289.906 -85.33)"
        fill="#d80027"
      />
    </svg>
  );
}

export default SvgComponent;
