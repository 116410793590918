import React, { useState } from "react";
import { Box, Image, useDisclosure } from "@chakra-ui/react";
import { practice } from "../../constants/data";
import { H5pModal, Modals, UrlPlayModal, ScormModal, Spinner } from "..";

const PracticeList = ({ Data = [], isMoreLoading, ...props }) => {
  const h5pModal = useDisclosure();
  const scormModal = useDisclosure();
  const urlModal = useDisclosure();

  const [value, setValue] = useState();

  const openModal = (type, value) => {
    setValue(value);
    if (type === 4) h5pModal.onOpen();
    else if (type === 5) scormModal.onOpen();
    else if (type === 6) urlModal.onOpen();
  };

  return (
    <Box>
      <Box
        flex={1}
        display="grid"
        gridGap={6}
        gridTemplateColumns={{
          base: "1fr 1fr",
          sm: "1fr 1fr 1fr",
          md: "1fr 1fr 1fr 1fr",
          lg: "1fr 1fr 1fr 1fr 1fr",
        }}
        alignItems={"center"}
        mb={10}
        mt={8}
        {...props}
      >
        {Data.map((item, index) => {
          var h5pImage = practice.find(
            (element) => element?.key === item?.library_name
          );
          if (h5pImage === undefined) {
            h5pImage = practice[0];
          }

          return (
            <Box
              key={index}
              onClick={() => openModal(item?.type, item?.value)}
              cursor="pointer"
            >
              <Image
                src={h5pImage?.image}
                alt="practice image"
                opacity={item?.is_completed ? 0.2 : 1}
              />
            </Box>
          );
        })}
        {isMoreLoading && <Spinner />}
      </Box>
      <Modals onClose={h5pModal.onClose} isOpen={h5pModal.isOpen}>
        <H5pModal uuid={value} />
      </Modals>
      <Modals onClose={urlModal.onClose} isOpen={urlModal.isOpen}>
        <UrlPlayModal uuid={value} />
      </Modals>
      <Modals onClose={scormModal.onClose} isOpen={scormModal.isOpen}>
        <ScormModal uuid={value} />
      </Modals>
    </Box>
  );
};

export default PracticeList;
