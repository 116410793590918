import images from "./images";
import lotties from "./lotties";

export const HomeData = [
  {
    key: 1,
    text: "Complete 5 grammar lessons",
    image: images.brightRed,
    color: "brightRed",
    progress: "50%",
  },
  {
    key: 2,
    text: "Learn 200 words",
    image: images.squash,
    color: "squash",
    progress: "75%",
  },
  {
    key: 3,
    text: "Complete 10 reading activities",
    image: images.dangelion,
    color: "dandelion",
    progress: "60%",
  },
  {
    key: 4,
    text: "Complete 10 listening activities",
    image: images.yellowishGreen,
    color: "sicklyYellow",
    progress: "100%",
  },
  {
    key: 5,
    text: "Read 2 books",
    image: images.vividGreen,
    color: "vividGreen",
    progress: "50%",
  },
  {
    key: 6,
    text: "Complete 10 speaking activities",
    image: images.aqua,
    color: "aqua",
    progress: "20%",
  },
  {
    key: 7,
    text: "Complete 10 writing activities",
    image: images.aquaBlue,
    color: "aquaBlue",
    progress: "37%",
  },
  {
    key: 8,
    text: "Read 2 news",
    image: images.blue,
    color: "blue",
    progress: "60%",
  },
  {
    key: 9,
    text: "Write articles on 2 topics",
    image: images.brightMagenta,
    color: "brightMagenta",
    progress: "50%",
  },
  {
    key: 10,
    text: "Complete 5 online courses",
    image: images.violet,
    color: "violet",
    progress: "60%",
  },
  {
    key: 11,
    text: "Take 5 live speaking lessons",
    image: images.gray,
    color: "lightGray",
    progress: "25%",
  },
  {
    key: 12,
    text: "Complete 3 Talk to Dio activities",
    image: images.black,
    color: "blackTwo",
    progress: "100%",
  },
];

export const topicA1 = [
  { key: 1, label: "Greetings", gif: lotties.Greetings },
  { key: 2, label: "Party Time", gif: lotties.PartyTime },
  { key: 3, label: "In the Classroom", gif: lotties.Classroom },
  { key: 4, label: "Feelings", gif: lotties.Feeling },
  { key: 5, gif: lotties.Seasons },
  { key: 6, label: "At he playground", gif: lotties.InflatableCastle },
  { key: 7, label: "HobbiesPaint", gif: lotties.HobbiesPaint },
  { key: 8, label: "My Clothes", gif: lotties.WomanSelectingClothes },
  { key: 9, label: "My Body", gif: lotties.GainMuscle },
  { key: 10, label: "My Day", gif: lotties.ValentineDay },
  { key: 11, label: "Nature", gif: lotties.Nature },
  { key: 12, label: "Animals", gif: lotties.Penguin },
  { key: 13, label: "My House", gif: lotties.FamilyGotNewHome },
  { key: 14, label: "My Town/My City", gif: lotties.CityBuildingSkyline },
  { key: 15, label: "My Food", gif: lotties.PreparingFood },
  { key: 16, label: "My Story", gif: lotties.StoryTelling },
  { key: 17, label: "Holiday", gif: lotties.RoadTrip },
  { key: 18, label: "Health", gif: lotties.HealthCare },
  { key: 19, label: "Recap", gif: lotties.WriteNote },
];

export const topicA2 = [
  { key: 1, label: "Work & Jobs", gif: lotties.WorkingProfessionalMale },
  { key: 2, label: "Habits and Routines", gif: lotties.WalkingWomanWithADog },
  { key: 3, label: "In the Kitchen", gif: lotties.KitchenPreparingFood },
  { key: 4, label: "Biographies", gif: lotties.BiographiesPortfolio },
  { key: 5, label: "How do you feel?", gif: lotties.HeartbrokenFeeling },
  {
    key: 6,
    label: "Personality and Appearence",
    gif: lotties.BusinessDresscode,
  },
  {
    key: 7,
    label: "Let's Travel travel-location",
    gif: lotties.TravelLocation,
  },
  { key: 8, label: "saving the world eco-light", gif: lotties.EcoLight },
  { key: 9, label: "Natural Forces", gif: lotties.Forest },
  { key: 10, label: "Entertainment", gif: lotties.BoyPlayingOnTrumpet },
  { key: 11, label: "Recap", gif: lotties.WriteNote },
];

export const topicB1 = [
  { key: 1, label: "Mass Media", gif: lotties.MassMedia },
  { key: 1, label: "Films & Music", gif: lotties.MusicClapper },
  { key: 2, label: "Future Jobs", gif: lotties.FutureJobs },
  { key: 4, label: "Travel", gif: lotties.Travel },
  {
    key: 6,
    label: "News, Lifestyles and Current Affairs",
    gif: lotties.NewsReporting,
  },
  { key: 7, label: "Techno", gif: lotties.Tech },
  { key: 8, label: "Dreams Goal", gif: lotties.DreamsGoal },
  { key: 9, label: "Food", gif: lotties.FoodPlatter },
  { key: 10, label: "Sports", gif: lotties.BasketBall },
  { key: 11, label: "Environ Pro", gif: lotties.EnvironmentPro },
  { key: 12, label: "Health", gif: lotties.HealthCare },
  { key: 13, label: "Back to the Past", gif: lotties.BackArrows },
  { key: 14, label: "Education", gif: lotties.Learning },
  { key: 15, label: "My country: Türkiye", gif: lotties.Turkey },
  { key: 16, label: "Leisure activities", gif: lotties.GameConsole },
  { key: 3, label: "Norms", gif: lotties.ValuesNorms },
  { key: 18, label: "Recap", gif: lotties.WriteNote },
];

export const topicB2 = [
  { key: 1, gif: lotties.BookLiterature },
  { key: 2, gif: lotties.BusinessmanOnDeadline },
  { key: 4, gif: lotties.NewsStories },
  { key: 5, gif: lotties.ComingSoon },
  { key: 6, gif: lotties.Technology },
  { key: 7, gif: lotties.TravelAroundTheWorld },
  { key: 8, gif: lotties.Friendship },
  { key: 9, gif: lotties.WindEnergy },
  { key: 10, gif: lotties.Disapointment },
  { key: 11, gif: lotties.OpenMind },
  { key: 12, gif: lotties.WorldMap },
  { key: 13, gif: lotties.Artist },
  { key: 14, gif: lotties.Photography },
  { key: 15, gif: lotties.VictorianMan },
  { key: 16, gif: lotties.Favorite },
  { key: 17, gif: lotties.Education },
  { key: 18, gif: lotties.CivilRights },
  { key: 19, gif: lotties.WriteNote },
];

export const practice = [
  { key: "null", image: images.nullH5P, id: 0 },
  { key: "H5P.Agamotto", image: images.agamotto, id: 139 },
  { key: "H5P.AudioRecorder", image: images.audioRecorder, id: 5 },
  { key: "H5P.Chart", image: images.chart, id: 132 },
  { key: "H5P.Collage", image: images.collage, id: 133 },
  { key: "H5P.Column", image: images.column, id: 96 },
  { key: "H5P.AdvancedBlanks", image: images.complexFillInTheBlanks, id: 76 },
  { key: "H5P.Crossword", image: images.crossword, id: 0 },
  { key: "H5P.DragQuestion", image: images.dragAndDrop, id: 16 },
  { key: "H5P.DragText", image: images.dragTheWords, id: 17 },
  { key: "H5P.Dialogcards", image: images.dialogueCards, id: 12 },
  { key: "H5P.Dictation", image: images.dictation, id: 136 },
  { key: "H5P.Essay", image: images.essay, id: 123 },
  { key: "H5P.Blanks", image: images.fillInTheBlanks, id: 6 },
  {
    key: "H5P.ImageMultipleHotspotQuestion",
    image: images.findMultipleHotspots,
    id: 116,
  },
  { key: "H5P.Flashcards", image: images.flashCards, id: 121 },
  { key: "H5P.GuessTheAnswer", image: images.guessTheAnswer, id: 122 },
  { key: "H5P.MultiMediaChoice", image: images.imageChoice, id: 0 },
  { key: "H5P.ImageSequencing", image: images.imageSequencing, id: 111 },
  { key: "H5P.ImagePair", image: images.imagePairing, id: 113 },
  { key: "H5P.ImageSlider", image: images.imageSlider, id: 109 },
  { key: "H5P.InteractiveBook", image: images.interactiveBook, id: 97 },
  { key: "H5P.InteractiveVideo", image: images.interactiveVideo, id: 26 },
  { key: "H5P.MarkTheWords", image: images.markTheWords, id: 29 },
  { key: "H5P.MemoryGame", image: images.memoryGame, id: 92 },
  { key: "H5P.MultiChoice", image: images.multipleChoice, id: 31 },
  { key: "H5P.PersonalityQuiz", image: images.personalityQuiz, id: 90 },
  { key: "H5P.QuestionSet", image: images.quiz, id: 88 }, // Quiz kısmı QuestionSet olarak değişmiş
  { key: "H5P.Questionnaire", image: images.questionnaire, id: 35 },
  { key: "H5P.SingleChoiceSet", image: images.singleChoiceSet, id: 38 },
  { key: "H5P.SortParagraphs", image: images.sortTheParagraphs, id: 0 },
  { key: "H5P.SpeakTheWords", image: images.speakTheWords, id: 86 },
  { key: "H5P.SpeakTheWordsSet", image: images.speakTheWordsSet, id: 87 },
  { key: "H5P.StructureStrip", image: images.structureStrip, id: 0 },
  { key: "H5P.Summary", image: images.summary, id: 40 },
  { key: "H5P.Timeline", image: images.timeline, id: 84 },
  { key: "H5P.TrueFalse", image: images.trueFalseQuestion, id: 45 },
  { key: "H5P.ThreeImage", image: images.virtualTour, id: 79 },
  { key: "H5P.FindTheWords", image: images.findTheWords, id: 0 },
  { key: "H5P.ImageHotspots", image: images.imageHotSpots, id: 0 },
];

export const BookData = [
  { key: 1, book: images.book1, color: "golden" },
  { key: 2, book: images.book2, color: "electricBlue" },
  { key: 3, book: images.book3, color: "barney" },
  { key: 4, book: images.book4, color: "grass" },
  { key: 5, book: images.book5, color: "redOrange" },
];

export const countries = [
  { code: 1, name: "Afghanistan" },
  { code: 2, name: "Albania" },
  { code: 3, name: "Algeria" },
  { code: 4, name: "Andorra" },
  { code: 5, name: "Angola" },
  { code: 6, name: "Anguilla" },
  { code: 7, name: "Antigua & Barbuda" },
  { code: 8, name: "Argentina" },
  { code: 9, name: "Armenia" },
  { code: 10, name: "Australia" },
  { code: 11, name: "Austria" },
  { code: 12, name: "Azerbaijan" },
  { code: 13, name: "Bahamas" },
  { code: 14, name: "Bahrain" },
  { code: 15, name: "Bangladesh" },
  { code: 16, name: "Barbados" },
  { code: 17, name: "Belarus" },
  { code: 18, name: "Belgium" },
  { code: 19, name: "Belize" },
  { code: 20, name: "Benin" },
  { code: 21, name: "Bermuda" },
  { code: 22, name: "Bhutan" },
  { code: 23, name: "Bolivia" },
  { code: 24, name: "Bosnia & Herzegovina" },
  { code: 25, name: "Botswana" },
  { code: 26, name: "Brazil" },
  { code: 27, name: "Brunei Darussalam" },
  { code: 28, name: "Bulgaria" },
  { code: 29, name: "Burkina Faso" },
  { code: 30, name: "Burundi" },
  { code: 31, name: "Cambodia" },
  { code: 32, name: "Cameroon" },
  { code: 33, name: "Canada" },
  { code: 34, name: "Cape Verde" },
  { code: 35, name: "Cayman Islands" },
  { code: 36, name: "Central African Republic" },
  { code: 37, name: "Chad" },
  { code: 38, name: "Chile" },
  { code: 39, name: "China" },
  { code: 40, name: "China - Hong Kong / Macau" },
  { code: 41, name: "Colombia" },
  { code: 42, name: "Comoros" },
  { code: 43, name: "Congo" },
  { code: 44, name: "Congo, Democratic Republic of (DRC)" },
  { code: 45, name: "Costa Rica" },
  { code: 46, name: "Croatia" },
  { code: 47, name: "Cuba" },
  { code: 48, name: "Cyprus" },
  { code: 49, name: "Czech Republic" },
  { code: 50, name: "Denmark" },
  { code: 51, name: "Djibouti" },
  { code: 52, name: "Dominica" },
  { code: 53, name: "Dominican Republic" },
  { code: 54, name: "Ecuador" },
  { code: 55, name: "Egypt" },
  { code: 56, name: "El Salvador" },
  { code: 57, name: "Equatorial Guinea" },
  { code: 58, name: "Eritrea" },
  { code: 59, name: "Estonia" },
  { code: 60, name: "Eswatini" },
  { code: 61, name: "Ethiopia" },
  { code: 62, name: "Fiji" },
  { code: 63, name: "Finland" },
  { code: 64, name: "France" },
  { code: 65, name: "French Guiana" },
  { code: 66, name: "Gabon" },
  { code: 67, name: "Gambia, Republic of The" },
  { code: 68, name: "Georgia" },
  { code: 69, name: "Germany" },
  { code: 70, name: "Ghana" },
  { code: 71, name: "Great Britain" },
  { code: 72, name: "Greece" },
  { code: 73, name: "Grenada" },
  { code: 74, name: "Guadeloupe" },
  { code: 75, name: "Guatemala" },
  { code: 76, name: "Guinea" },
  { code: 77, name: "Guinea-Bissau" },
  { code: 78, name: "Guyana" },
  { code: 79, name: "Haiti" },
  { code: 80, name: "Honduras" },
  { code: 81, name: "Hungary" },
  { code: 82, name: "Iceland" },
  { code: 83, name: "India" },
  { code: 84, name: "Indonesia" },
  { code: 85, name: "Iran" },
  { code: 86, name: "Iraq" },
  { code: 87, name: "Italy" },
  { code: 88, name: "Ivory Coast (Cote d'Ivoire)" },
  { code: 89, name: "Jamaica" },
  { code: 90, name: "Japan" },
  { code: 91, name: "Jordan" },
  { code: 92, name: "Kazakhstan" },
  { code: 93, name: "Kenya" },
  { code: 94, name: "Korea, Democratic Republic of (North Korea)" },
  { code: 95, name: "Korea, Republic of (South Korea)" },
  { code: 96, name: "Kosovo" },
  { code: 97, name: "Kuwait" },
  { code: 98, name: "Kyrgyz Republic (Kyrgyzstan)" },
  { code: 99, name: "Laos" },
  { code: 100, name: "Latvia" },
  { code: 101, name: "Lebanon" },
  { code: 102, name: "Lesotho" },
  { code: 103, name: "Liberia" },
  { code: 104, name: "Libya" },
  { code: 105, name: "Liechtenstein" },
  { code: 106, name: "Lithuania" },
  { code: 107, name: "Luxembourg" },
  { code: 108, name: "Madagascar" },
  { code: 109, name: "Malawi" },
  { code: 110, name: "Malaysia" },
  { code: 111, name: "Maldives" },
  { code: 112, name: "Mali" },
  { code: 113, name: "Malta" },
  { code: 114, name: "Martinique" },
  { code: 115, name: "Mauritania" },
  { code: 116, name: "Mauritius" },
  { code: 117, name: "Mayotte" },
  { code: 118, name: "Mexico" },
  { code: 119, name: "Moldova, Republic of" },
  { code: 120, name: "Monaco" },
  { code: 121, name: "Mongolia" },
  { code: 122, name: "Montenegro" },
  { code: 123, name: "Montserrat" },
  { code: 124, name: "Morocco" },
  { code: 125, name: "Mozambique" },
  { code: 126, name: "Myanmar/Burma" },
  { code: 127, name: "Namibia" },
  { code: 128, name: "Nepal" },
  { code: 129, name: "Netherlands" },
  { code: 130, name: "New Zealand" },
  { code: 131, name: "Nicaragua" },
  { code: 132, name: "Niger" },
  { code: 133, name: "Nigeria" },
  { code: 134, name: "North Macedonia, Republic of" },
  { code: 135, name: "Norway" },
  { code: 136, name: "Oman" },
  { code: 137, name: "Pacific Islands" },
  { code: 138, name: "Pakistan" },
  { code: 139, name: "Palestine" },
  { code: 140, name: "Panama" },
  { code: 141, name: "Papua New Guinea" },
  { code: 142, name: "Paraguay" },
  { code: 143, name: "Peru" },
  { code: 144, name: "Philippines" },
  { code: 145, name: "Poland" },
  { code: 146, name: "Portugal" },
  { code: 147, name: "Puerto Rico" },
  { code: 148, name: "Qatar" },
  { code: 149, name: "Reunion" },
  { code: 150, name: "Romania" },
  { code: 151, name: "Russian Federation" },
  { code: 152, name: "Rwanda" },
  { code: 153, name: "Saint Kitts and Nevis" },
  { code: 154, name: "Saint Lucia" },
  { code: 155, name: "Saint Vincent and the Grenadines" },
  { code: 156, name: "Samoa" },
  { code: 157, name: "Sao Tome and Principe" },
  { code: 158, name: "Saudi Arabia" },
  { code: 159, name: "Senegal" },
  { code: 160, name: "Serbia" },
  { code: 161, name: "Seychelles" },
  { code: 162, name: "Sierra Leone" },
  { code: 163, name: "Singapore" },
  { code: 164, name: "Slovak Republic (Slovakia)" },
  { code: 165, name: "Slovenia" },
  { code: 166, name: "Solomon Islands" },
  { code: 167, name: "Somalia" },
  { code: 168, name: "South Africa" },
  { code: 169, name: "South Sudan" },
  { code: 170, name: "Spain" },
  { code: 171, name: "Sri Lanka" },
  { code: 172, name: "Sudan" },
  { code: 173, name: "Suriname" },
  { code: 174, name: "Sweden" },
  { code: 175, name: "Switzerland" },
  { code: 176, name: "Syria" },
  { code: 177, name: "Tajikistan" },
  { code: 178, name: "Tanzania" },
  { code: 179, name: "Thailand" },
  { code: 180, name: "Timor Leste" },
  { code: 181, name: "Togo" },
  { code: 182, name: "Trincodead & Tobago" },
  { code: 183, name: "Tunisia" },
  { code: 184, name: "Türkiye (Turkey)" },
  { code: 185, name: "Turkmenistan" },
  { code: 186, name: "Turks & Caicos Islands" },
  { code: 187, name: "Uganda" },
  { code: 188, name: "Ukraine" },
  { code: 189, name: "United Arab Emirates" },
  { code: 190, name: "United States of America (USA)" },
  { code: 191, name: "Uruguay" },
  { code: 192, name: "Uzbekistan" },
  { code: 193, name: "Venezuela" },
  { code: 194, name: "Vietnam" },
  { code: 195, name: "Virgin Islands (UK)" },
  { code: 196, name: "Virgin Islands (US)" },
  { code: 197, name: "Yemen" },
  { code: 198, name: "Zambia" },
  { code: 199, name: "Zimbabwe" },
];

export const graduation = [
  { code: 1, name: "Primary School" },
  { code: 2, name: "Middle School" },
  { code: 3, name: "High School" },
  { code: 4, name: "Associates Degrees" },
  { code: 5, name: "Bachelor's Degrees" },
];

export const Languages = [
  { code: "aa", name: "Afar" },
  { code: "ab", name: "Abkhazian" },
  { code: "ae", name: "Avestan" },
  { code: "af", name: "Afrikaans" },
  { code: "ak", name: "Akan" },
  { code: "am", name: "Amharic" },
  { code: "an", name: "Aragonese" },
  { code: "ar", name: "Arabic" },
  { code: "as", name: "Assamese" },
  { code: "av", name: "Avaric" },
  { code: "ay", name: "Aymara" },
  { code: "az", name: "Azerbaijani" },
  { code: "ba", name: "Bashkir" },
  { code: "be", name: "Belarusian" },
  { code: "bg", name: "Bulgarian" },
  { code: "bh", name: "Bihari languages" },
  { code: "bi", name: "Bislama" },
  { code: "bm", name: "Bambara" },
  { code: "bn", name: "Bengali" },
  { code: "bo", name: "Tibetan" },
  { code: "br", name: "Breton" },
  { code: "bs", name: "Bosnian" },
  { code: "ca", name: "Catalan; Valencian" },
  { code: "ce", name: "Chechen" },
  { code: "ch", name: "Chamorro" },
  { code: "co", name: "Corsican" },
  { code: "cr", name: "Cree" },
  { code: "cs", name: "Czech" },
  {
    code: "cu",
    name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
  },
  { code: "cv", name: "Chuvash" },
  { code: "cy", name: "Welsh" },
  { code: "da", name: "Danish" },
  { code: "de", name: "German" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian" },
  { code: "dz", name: "Dzongkha" },
  { code: "ee", name: "Ewe" },
  { code: "el", name: "Greek, Modern (1453-)" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "es", name: "Spanish; Castilian" },
  { code: "et", name: "Estonian" },
  { code: "eu", name: "Basque" },
  { code: "fa", name: "Persian" },
  { code: "ff", name: "Fulah" },
  { code: "fi", name: "Finnish" },
  { code: "fj", name: "Fijian" },
  { code: "fo", name: "Faroese" },
  { code: "fr", name: "French" },
  { code: "fy", name: "Western Frisian" },
  { code: "ga", name: "Irish" },
  { code: "gd", name: "Gaelic; Scomttish Gaelic" },
  { code: "gl", name: "Galician" },
  { code: "gn", name: "Guarani" },
  { code: "gu", name: "Gujarati" },
  { code: "gv", name: "Manx" },
  { code: "ha", name: "Hausa" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "ho", name: "Hiri Motu" },
  { code: "hr", name: "Croatian" },
  { code: "ht", name: "Haitian; Haitian Creole" },
  { code: "hu", name: "Hungarian" },
  { code: "hy", name: "Armenian" },
  { code: "hz", name: "Herero" },
  {
    code: "ia",
    name: "Interlingua (International Auxiliary Language Association)",
  },
  { code: "id", name: "Indonesian" },
  { code: "ie", name: "Interlingue; Occidental" },
  { code: "ig", name: "Igbo" },
  { code: "ii", name: "Sichuan Yi; Nuosu" },
  { code: "ik", name: "Inupiaq" },
  { code: "io", name: "Ido" },
  { code: "is", name: "Icelandic" },
  { code: "it", name: "Italian" },
  { code: "iu", name: "Inuktitut" },
  { code: "ja", name: "Japanese" },
  { code: "jv", name: "Javanese" },
  { code: "ka", name: "Georgian" },
  { code: "kg", name: "Kongo" },
  { code: "ki", name: "Kikuyu; Gikuyu" },
  { code: "kj", name: "Kuanyama; Kwanyama" },
  { code: "kk", name: "Kazakh" },
  { code: "kl", name: "Kalaallisut; Greenlandic" },
  { code: "km", name: "Central Khmer" },
  { code: "kn", name: "Kannada" },
  { code: "ko", name: "Korean" },
  { code: "kr", name: "Kanuri" },
  { code: "ks", name: "Kashmiri" },
  { code: "ku", name: "Kurdish" },
  { code: "kv", name: "Komi" },
  { code: "kw", name: "Cornish" },
  { code: "ky", name: "Kirghiz; Kyrgyz" },
  { code: "la", name: "Latin" },
  { code: "lb", name: "Luxembourgish; Letzeburgesch" },
  { code: "lg", name: "Ganda" },
  { code: "li", name: "Limburgan; Limburger; Limburgish" },
  { code: "ln", name: "Lingala" },
  { code: "lo", name: "Lao" },
  { code: "lt", name: "Lithuanian" },
  { code: "lu", name: "Luba-Katanga" },
  { code: "lv", name: "Latvian" },
  { code: "mg", name: "Malagasy" },
  { code: "mh", name: "Marshallese" },
  { code: "mi", name: "Maori" },
  { code: "mk", name: "Macedonian" },
  { code: "ml", name: "Malayalam" },
  { code: "mn", name: "Mongolian" },
  { code: "mr", name: "Marathi" },
  { code: "ms", name: "Malay" },
  { code: "mt", name: "Maltese" },
  { code: "my", name: "Burmese" },
  { code: "na", name: "Nauru" },
  {
    code: "nb",
    name: "Bokmål, Norwegian; Norwegian Bokmål",
  },
  { code: "nd", name: "Ndebele, North; North Ndebele" },
  { code: "ne", name: "Nepali" },
  { code: "ng", name: "Ndonga" },
  { code: "nl", name: "Dutch; Flemish" },
  { code: "nn", name: "Norwegian Nynorsk; Nynorsk, Norwegian" },
  { code: "no", name: "Norwegian" },
  { code: "nr", name: "Ndebele, South; South Ndebele" },
  { code: "nv", name: "Navajo; Navaho" },
  { code: "ny", name: "Chichewa; Chewa; Nyanja" },
  { code: "oc", name: "Occitan (post 1500)" },
  { code: "oj", name: "Ojibwa" },
  { code: "om", name: "Oromo" },
  { code: "or", name: "Oriya" },
  { code: "os", name: "Ossetian; Ossetic" },
  { code: "pa", name: "Panjabi; Punjabi" },
  { code: "pi", name: "Pali" },
  { code: "pl", name: "Polish" },
  { code: "ps", name: "Pushto; Pashto" },
  { code: "pt", name: "Portuguese" },
  { code: "qu", name: "Quechua" },
  { code: "rm", name: "Romansh" },
  { code: "rn", name: "Rundi" },
  { code: "ro", name: "Romanian; Moldavian; Moldovan" },
  { code: "ru", name: "Russian" },
  { code: "rw", name: "Kinyarwanda" },
  { code: "sa", name: "Sanskrit" },
  { code: "sc", name: "Sardinian" },
  { code: "sd", name: "Sindhi" },
  { code: "se", name: "Northern Sami" },
  { code: "sg", name: "Sango" },
  { code: "si", name: "Sinhala; Sinhalese" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "sm", name: "Samoan" },
  { code: "sn", name: "Shona" },
  { code: "so", name: "Somali" },
  { code: "sq", name: "Albanian" },
  { code: "sr", name: "Serbian" },
  { code: "ss", name: "Swati" },
  { code: "st", name: "Sotho, Southern" },
  { code: "su", name: "Sundanese" },
  { code: "sv", name: "Swedish" },
  { code: "sw", name: "Swahili" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "tg", name: "Tajik" },
  { code: "th", name: "Thai" },
  { code: "ti", name: "Tigrinya" },
  { code: "tk", name: "Turkmen" },
  { code: "tl", name: "Tagalog" },
  { code: "tn", name: "Tswana" },
  { code: "to", name: "Tonga (Tonga Islands)" },
  { code: "tr", name: "Turkish" },
  { code: "ts", name: "Tsonga" },
  { code: "tt", name: "Tatar" },
  { code: "tw", name: "Twi" },
  { code: "ty", name: "Tahitian" },
  { code: "ug", name: "Uighur; Uyghur" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "ve", name: "Venda" },
  { code: "vi", name: "Vietnamese" },
  { code: "vo", name: "Volapük" },
  { code: "wa", name: "Walloon" },
  { code: "wo", name: "Wolof" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "za", name: "Zhuang; Chuang" },
  { code: "zh", name: "Chinese" },
  { code: "zu", name: "Zulu" },
];

export const avatars = [
  { key: 1, avatar: images.avatar1 },
  { key: 2, avatar: images.avatar2 },
  { key: 3, avatar: images.avatar3 },
  { key: 4, avatar: images.avatar4 },
  { key: 5, avatar: images.avatar5 },
  { key: 6, avatar: images.avatar6 },
  { key: 7, avatar: images.avatar7 },
  { key: 8, avatar: images.avatar8 },
  { key: 9, avatar: images.avatar9 },
  { key: 10, avatar: images.avatar10 },
];

export const Flashcards = [
  {
    key: 1,
    image: images.rookie,
    name: "Rookie",
    lottie: lotties.rookie,
  },
  {
    key: 2,
    image: images.freshman,
    name: "Freshman",
    lottie: lotties.freshman,
  },
  {
    key: 3,
    image: images.junior,
    name: "Junior",
    lottie: lotties.junior,
  },
  {
    key: 4,
    image: images.senior,
    name: "Senior",
    lottie: lotties.senior,
  },
  {
    key: 5,
    image: images.expert,
    name: "Expert",
    lottie: lotties.expert,
  },
  {
    key: 6,
    image: images.professional,
    name: "Professional",
    lottie: lotties.professional,
  },
  {
    key: 7,
    image: images.king,
    name: "King",
    lottie: lotties.king,
  },
];
