import React, { useMemo } from "react";
import { Text, Grid, Box, Divider } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { PostService } from "../../services";
import { Spinner } from "..";

const LessonMenu = ({ dividerH = "9.5vh", dividerW = "0.2vh" }) => {
  const { data, isLoading } = useQuery("lessons", PostService.getLessonTab);

  const lessons = useMemo(() => {
    return data && data.length > 0 ? data[0]?.data : [] || [];
  }, [data]);

  return (
    <Box display="flex" h="32" flexDirection={"column"}>
      <Grid
        templateColumns="repeat(3, 1fr)"
        display={"flex"}
        flex={1}
        h={"100%"}
        alignItems="center"
      >
        <GridItemMenu
          count={lessons.Vocabulary}
          text={"Vocabulary"}
          isLoading={isLoading}
          mb="3"
        />
        <Divider orientation="vertical" color={"title"} h={"125%"} />
        <GridItemMenu
          count={lessons.Grammar}
          text={"Grammar"}
          isLoading={isLoading}
          mb="3"
        />
        <Divider orientation="vertical" color={"title"} h={"125%"} />
        <GridItemMenu
          count={lessons.Reading}
          text={"Reading"}
          isLoading={isLoading}
          mb="3"
        />
      </Grid>
      <Divider orientation="horizontal" color={"title"} w={"100%"} />
      <Grid
        templateColumns="repeat(3, 1fr)"
        display={"flex"}
        flex={1}
        h={"100%"}
        alignItems={"center"}
      >
        {/* <Divider orientation="vertical" color={"title"} h={"125%"} /> */}
        <GridItemMenu
          count={lessons.Speaking}
          text={"Speaking"}
          isLoading={isLoading}
        />
        <Divider orientation="vertical" color={"title"} h={"125%"} />
        <GridItemMenu
          count={lessons.Listening}
          text={"Listening"}
          isLoading={isLoading}
        />
        <Divider orientation="vertical" color={"title"} h={"125%"} />
        <GridItemMenu
          count={lessons.Writing}
          text={"Writing"}
          isLoading={isLoading}
        />
      </Grid>
    </Box>
  );
};

export default LessonMenu;

const GridItemMenu = ({
  count,
  text,
  textColor = "title",
  isLoading,
  mb = "",
}) => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      textAlign={"center"}
      alignItems="center"
      flex={1}
      mb={mb}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Text color={textColor} fontWeight="extrabold" fontSize={"24"}>
          {count}
        </Text>
      )}
      <Text fontWeight={"500"} color={textColor} fontSize="10" mt="-1.5">
        {text}
      </Text>
    </Box>
  );
};
