import React, { useState } from "react";
import { Box, Icon, Text } from "@chakra-ui/react";
import {
  MainLayout,
  Title,
  DefaultButton,
  PerformanceReportCard,
  Spinner,
  ShowAlert,
} from "../../components";
import images from "../../constants/images";
import { useMutation } from "react-query";
import { UserService } from "../../services";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ChartBar, Check, Flag } from "../../components/icons";

const PerformanceReport = () => {
  const [data, setData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState("");
  const [show, setShow] = useState(false);
  const [level, setLevel] = useState("");

  const { mutateAsync, isLoading } = useMutation(
    UserService.getPerformanceReport
  );

  const getData = async () => {
    const data = await mutateAsync();
    if (data.success) {
      setData(data?.data);
      setUserLevel(data?.data);
      setShow(true);
    }
    if (data.success === false) {
      setAlertData(data.message);
      setShowAlert(true);
    }
  };

  const excellData = [
    {
      "Online Courses": data?.online_courses,
      Books: data?.books,
      News: data?.news,
      "Online Speaking": data?.online_speaking,
      Writing: data?.writing,
      Level: level,
      Day: data?.user_day?.day + "Day " + data?.user_day?.name,
      Vocabulary: data?.category?.Vocabulary,
      Grammar: data?.category?.Grammar,
      Reading: data?.category?.Reading,
      Speaking: data?.category?.Speaking,
      Listening: data?.category?.Listening,
      Writing_: data?.category?.Writing,
    },
  ];

  const fileName = "Performance Report";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  const fileExtension = ".xlsx";

  const DownloadReport = async () => {
    const ws = XLSX.utils.json_to_sheet(excellData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excellBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excellBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const SendPerformanceMail = async () => {
    const data = await UserService.sendPerformanceMail();
    if (data.success) {
      setAlertData(data.message);
      setShowAlert(true);
    }
    if (data.success === false) {
      setAlertData(data.message);
      setShowAlert(true);
    }
  };

  const setUserLevel = (_data) => {
    switch (_data?.user_level) {
      case 1:
        setLevel("A1");
        break;
      case 2:
        setLevel("A2");
        break;
      case 3:
        setLevel("B1");
        break;
      case 4:
        setLevel("B2");
        break;
      default:
        return;
    }
  };

  return (
    <MainLayout>
      <Title text={"Performance Report"} color="title" />
      <Box minH={"60vh"}>
        <Box display={"flex"} flexDirection="column">
          <DefaultButton
            color={"white"}
            fontSize={"10"}
            height="30"
            label="Create Report"
            backgroundColor={"title"}
            fontWeight="bold"
            w={{ base: "40%", md: "20%" }}
            alignSelf="center"
            onClick={() => getData()}
          />
          {show && (
            <Box display={"flex"} flexDirection="column">
              <DefaultButton
                color={"white"}
                fontSize={"10"}
                height="30"
                label="Download Report"
                backgroundColor={"whiteTwo"}
                fontWeight="bold"
                w={{ base: "40%", md: "20%" }}
                alignSelf="center"
                mt="10"
                disable={isLoading}
                onClick={() => DownloadReport()}
              />
              <DefaultButton
                color={"white"}
                fontSize={"10"}
                height="30"
                label="E-mail the report"
                backgroundColor={"whiteTwo"}
                fontWeight="bold"
                w={{ base: "40%", md: "20%" }}
                alignSelf="center"
                mt="-0.5"
                disable={isLoading}
                onClick={() => SendPerformanceMail()}
              />
            </Box>
          )}
        </Box>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box>
            {show && (
              <Box alignItems={"center"}>
                <Box display={"flex"} alignItems="center" gap="5" mt="2">
                  <Box display={"flex"} flex={1} justifyContent="flex-end">
                    <Icon as={ChartBar} boxSize={10} />
                  </Box>
                  <Box flex={1}>
                    <Box display={"flex"}>
                      <Text fontSize={"36"} fontWeight="800" color="title">
                        {level}
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <Box display={"flex"} alignItems="center" gap="5" mt="2">
                  <Box display={"flex"} flex={1} justifyContent="flex-end">
                    <Icon as={Flag} boxSize={10} />
                  </Box>
                  <Box flex={1}>
                    <Text fontSize={"24"} fontWeight="800" color="title">
                      {data?.user_day?.day} Day
                    </Text>
                    <Text fontSize={"16"} fontWeight="500" color="title">
                      {data?.user_day?.name}
                    </Text>
                  </Box>
                </Box>

                <Box display={"flex"} gap="5" mt="2">
                  <Box display={"flex"} flex={1} justifyContent="flex-end">
                    <Icon as={Check} boxSize={10} />
                  </Box>
                  <Box flex={1}>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Vocabulary}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Vocabulary
                      </Text>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Grammar}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Grammar
                      </Text>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Reading}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Reading
                      </Text>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Speaking}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Speaking
                      </Text>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Listening}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Listening
                      </Text>
                    </Box>
                    <Box display="flex" alignItems={"center"} gap="2">
                      <Text fontSize={"24"} fontWeight="800" color="title">
                        {data?.category?.Writing}
                      </Text>
                      <Text fontSize={"16"} fontWeight="500" color="title">
                        Writing
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  display="grid"
                  gridGap={"4"}
                  gridTemplateColumns={{
                    base: "1fr",
                    sm: "1fr 1fr",
                    md: "1fr 1fr",
                    lg: "1fr 1fr",
                  }}
                  w="100%"
                  alignItems={"center"}
                >
                  <PerformanceReportCard
                    background={images.onlineCoursesRaportBackground}
                    image={images.onlineCoursesRaport}
                    count={data?.online_courses}
                    text="Online Courses"
                  />
                  <PerformanceReportCard
                    background={images.booksRaportBackground}
                    image={images.booksRaport}
                    count={data?.books}
                    text="Books"
                  />
                  <PerformanceReportCard
                    background={images.newsRaportBackground}
                    image={images.newsRaport}
                    count={data?.news}
                    text="News"
                  />
                  <PerformanceReportCard
                    background={images.onlineSpeakingRaportBackground}
                    image={images.onlineSpeakingRaport}
                    count={data?.online_speaking}
                    text="Online Speaking"
                  />
                  <PerformanceReportCard
                    background={images.writingRaportBackground}
                    image={images.writingRaport}
                    count={data?.writing}
                    text="Writing"
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <ShowAlert
        data={alertData}
        isShow={showAlert}
        color="title"
        onClick={() => setShowAlert(false)}
      />
    </MainLayout>
  );
};

export default PerformanceReport;
