import React, { useMemo, useEffect, useState } from "react";
import { MainLayout, Title, TopicList, Spinner } from "../components";
import { useQuery } from "react-query";
import { PostService, AuthService } from "../services";
import { topicA1, topicA2, topicB1, topicB2 } from "../constants/data";

const Topic = () => {
  const userLevel = AuthService.getLevel();
  const [level, setLevel] = useState("");
  const [lottie, setLottie] = useState([]);

  const { data, isLoading } = useQuery("posts", PostService.init);

  const posts = useMemo(() => {
    return data && data.length > 0 ? data[0]?.content_subjects : [] || [];
  }, [data]);

  useEffect(() => {
    setUserLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserLevel = () => {
    switch (parseInt(userLevel)) {
      case 1:
        setLevel("A1");
        setLottie(topicA1);
        break;
      case 2:
        setLevel("A2");
        setLottie(topicA2);
        break;
      case 3:
        setLevel("B1");
        setLottie(topicB1);
        break;
      case 4:
        setLevel("B2");
        setLottie(topicB2);
        break;
      default:
        return;
    }
  };

  return (
    <MainLayout>
      <Title text={`Topic - ${level}`} color={"title"} />
      {isLoading ? (
        <Spinner />
      ) : (
        <TopicList data={posts} level={level} lottie={lottie} />
      )}
    </MainLayout>
  );
};

export default Topic;
