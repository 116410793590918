import React from "react";
import { Text, Input as CInput, Box } from "@chakra-ui/react";

const AuthInput = ({ label, ...props }) => {
  return (
    <>
      {label && (
        <Text fontSize={10} fontWeight={"medium"} mb="-2" ml={2} mt="1.5">
          {label}
        </Text>
      )}
      <CInput
        borderWidth={1}
        borderColor={"lightgray"}
        backgroundColor={"input"}
        my={"2"}
        color={"warmGray"}
        fontSize={8}
        h="30"
        {...props}
      />
    </>
  );
};

export default AuthInput;
