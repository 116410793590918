import React from "react";
import { Box } from "@chakra-ui/react";
import { TopicCard } from "..";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";

const TopicList = ({ data = [], lottie = [], level, ...props }) => {
  const navigate = useNavigate();

  const onDetail = (title, subtitle, image_url, id, level) =>
    navigate(routes.categories.path, {
      state: { title, subtitle, image_url, id, level },
    });

  return (
    <Box
      flex={1}
      display="grid"
      gridGap={"5"}
      gridTemplateColumns={{
        base: "1fr 1fr",
        sm: "1fr 1fr",
        md: "1fr 1fr 1fr 1fr",
        lg: "1fr 1fr 1fr 1fr 1fr",
      }}
      alignItems={"center"}
      mb={10}
      {...props}
    >
      {data.map?.((item, index) => {
        return (
          <TopicCard
            key={index}
            onClick={() =>
              onDetail(
                item?.title,
                item?.subtitle,
                item?.image_url,
                item?.id,
                level
              )
            }
            label={item?.title}
            gif={lottie[index]?.gif}
          />
        );
      })}
    </Box>
  );
};

export default TopicList;
