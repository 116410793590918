import { Box, Image } from "@chakra-ui/react";
import React from "react";
import images from "../constants/images";

const Fallback = () => {
  return (
    <Box
      display={"flex"}
      height={"100vh"}
      width={"100vw"}
      justifyContent={"center"}
      alignItems={"center"}
      backgroundColor={"#BCD6FF"}
    >
      <Image src={images.logo} alt="logo" width={"20vw"} />
    </Box>
  );
};

export default Fallback;
